import React, { useState, useEffect } from "react";
import {
  Typography,
  Tabs,
  Box,
  FormControl,
  Grid,
  TextField,
  Card,
  CardHeader,
  Menu,
  CardContent,
  CardMedia,
  Switch,
  Select,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { Container, MenuItem } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import DataTable from "react-data-table-component";
import IconButton from "@material-ui/core/IconButton";
import SortIcon from "@material-ui/icons/Sort";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import axios from "axios";
import Config from "../../config.json";
import Alert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import { useUserContext } from "./../../Context/UserContext";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useConfirm } from "material-ui-confirm";

const getQuery = (query) => {
  let q = "";
  Object.keys(query).forEach((k) => {
    if (query[k] !== 0) {
      q += `${k}=${query[k]}&`;
    }
  });
  return q;
};

function Products() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [categories, setcategories] = useState(null);
  const [brands, setbrands] = useState(null);
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/category/all`)
        .then((response) => {
          setcategories(response.data);
        })
        .catch((error) => {});
    };
    init();
  }, []);
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/brands/all`)
        .then((response) => {
          setbrands(response.data);
        })
        .catch((error) => {});
    };
    init();
  }, []);
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        Products
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/orders">
          Products
        </Link>
      </Breadcrumbs>
      <Paper evaluation={1} className="px-2">
        <AppBar position="static" color="transparent" elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="nav tabs example"
          >
            <Tab label="Products" {...a11yProps(0)} />
            <Tab label="Categories" {...a11yProps(1)} />
            <Tab label="Brands" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <AllProducts categories={categories} brands={brands} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Categories />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Brands />
        </TabPanel>
      </Paper>
    </Container>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));
function AllProducts({ categories, brands }) {
  //Filters
  const [EnableFilters, setEnableFilters] = useState(false);
  const [search, setsearch] = useState("");
  const [CategoryFilter, setCategoryFilter] = useState(0);
  const [BrandFilter, setBrandFilter] = useState(0);
  const [discount, setdiscount] = useState(0);
  const [price, setprice] = useState(0);
  const handleSubmit = async () => {
    await axios
      .get(
        `${Config.SERVER_URL}/product?${getQuery({
          price: price,
          discount: discount,
          category: CategoryFilter,
          brand: BrandFilter,
        })}`
      )
      .then((response) => {
        if (response.data) setproducts(response.data.data);
      })
      .catch((error) => {});
  };

  //End Filter
  const classes = useStyles();
  const [products, setproducts] = useState(null);
  const [data, setdata] = useState(null);
  const [pagenumber, setpagenumber] = useState("");
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/product?search=${search}&page=${pagenumber}`)
        .then((response) => {
          if (response.data) {
            setproducts(response.data.data);
            setdata(response.data);
          }
        })
        .catch((error) => {});
    };
    init();
  }, [search, pagenumber]);
  const [error, seterror] = useState(false);
  const [success, setsuccess] = useState(false);
  return (
    <Container maxWidth="xl">
      <Box>
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={6} sm={3}>
            <IconButton
              aria-label="delete"
              color="default"
              className="filters"
              onClick={() => setEnableFilters(!EnableFilters)}
            >
              Filters
              <ArrowDropDownIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs
            className="d-flex justify-content-end align-items-center"
          >
            <TextField
              variant="outlined"
              size="small"
              onChange={(e) => {
                setpagenumber("");
                setsearch(e.target.value);
              }}
            ></TextField>
            <Link to="/products/new" className="mx-2 mt-2">
              <Button variant="contained" color="primary" className="h-100">
                Add Product
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Box p={4} className={EnableFilters ? "Filters-Show" : "Filters-Hide"}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Category</label>
              <Select
                color="primary"
                variant="outlined"
                name="Category"
                onChange={(e) => setCategoryFilter(e.target.value)}
                value={CategoryFilter}
              >
                <MenuItem value={0} selected>
                  Select Category
                </MenuItem>
                {categories &&
                  categories?.map((cat, index) => {
                    return (
                      <MenuItem key={index} value={cat.id}>
                        {cat.category_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Brand</label>
              <Select
                color="primary"
                variant="outlined"
                name="Brand"
                onChange={(e) => {
                  setBrandFilter(e.target.value);
                }}
                value={BrandFilter}
              >
                <MenuItem value={0} selected>
                  Select Brand
                </MenuItem>
                {brands &&
                  brands?.map((brand, index) => {
                    return (
                      <MenuItem key={index} value={brand.id}>
                        {brand.brand_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Discount</label>
              <TextField
                variant="outlined"
                onChange={(e) => setdiscount(e.target.value)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Price</label>
              <TextField
                variant="outlined"
                onChange={(e) => setprice(e.target.value)}
                size="small"
                placeholder="Type Here"
                type="number"
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} container justify="flex-end" alignItems="center">
            <Button variant="outlined" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
      {error &&
        Object.keys(error).map((key) => {
          return (
            <Alert key={key} severity="error">
              {error[key]}
            </Alert>
          );
        })}
      {success && <Alert severity="success"> Operation Successfull</Alert>}
      <Grid container spacing={4}>
        {products &&
          products.map((p, index) => {
            return (
              <Grid item xs={3} xl={2} key={index}>
                <Card className={classes.root}>
                  <ActionMenu
                    product={p}
                    setproducts={setproducts}
                    seterror={seterror}
                    setsuccess={setsuccess}
                  />
                  <Link to={`/products/details/${p.id}`}>
                    <CardMedia
                      className={classes.media}
                      image={p?.productimages[0]?.picture_reference}
                      title="Paella dish"
                    />
                  </Link>

                  <CardContent>
                    <Typography variant="h6" component="p">
                      {p.product_name}
                    </Typography>
                    <Typography variant="overline" component="p">
                      Category: {p?.category?.category_name}
                    </Typography>
                    <Typography variant="overline" component="p">
                      Brand: {p?.brand?.brand_name}
                    </Typography>
                  </CardContent>
                  <CardContent className="pt-1">
                    <Typography variant="overline" component="p" align="center">
                      Stock: {p?.quantity}
                    </Typography>
                    <Typography variant="body1" component="p" align="center">
                      Price: SAR. {p?.price}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        {products && (
          <Grid container item xs={12} justify="flex-end">
            <Pagination
              count={parseInt(data?.total / 12 + 1)}
              showFirstButton
              showLastButton
              onChange={(e, val) => setpagenumber(val)}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

//Action Menu
function ActionMenu({ product, setproducts, setsuccess, seterror }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { getuser } = useUserContext();
  const [user] = useState(getuser());
  const handleDelete = async () => {
    confirm({
      description: `Are you sure you want to delete ${product?.product_name}!`,
    })
      .then(async () => {
        await axios
          .post(`${Config.SERVER_URL}/product/delete/${product?.id}`, {
            admin_id: user?.id,
          })
          .then((res) => {
            setsuccess(true);
            setproducts(res.data.data);
            handleClose();
          })
          .catch((error) => {
            if (error?.response && error?.response.status === 400)
              seterror(error.response.data);
          });
      })
      .catch(() => {
        /* ... */
      });
  };
  const confirm = useConfirm();
  return (
    <div>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            className="py-0"
            style={{ fontSize: "16pt" }}
            onClick={handleClick}
          >
            <MoreHorizIcon />
          </IconButton>
        }
        className="px-1 pb-0"
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <Link to={`/products/details/${product?.id}`}>Product Status</Link>
        </MenuItem>
        <MenuItem>
          <Link to={`/products/details/${product?.id}`}>
            Review Product Orders
          </Link>
        </MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </div>
  );
}
// Categoreis
function Categories() {
  const columns = [
    {
      name: "Image",
      selector: "banner",
      sortable: false,
      cell: (row) => <img src={row?.banner} alt={row?.id} width="100" />,
    },
    {
      name: "Category Name",
      selector: "category_name",
      sortable: true,
      cell: (row) => (
        <Link to={`category/edit/${row?.id}`}>{row?.category_name}</Link>
      ),
    },
    {
      name: "Status",
      selector: "Status",
      sortable: true,
      cell: (row) => (
        <Switch
          checked={row.status === 1 || row.status === "1" ? true : false}
          onChange={(e) => handleStatusChange(e, row)}
          color="primary"
          name="Status"
          inputProps={{ "aria-label": "Status Change" }}
        />
      ),
    },
    {
      name: "Products",
      selector: "products_count",
      sortable: true,
    },
    {
      name: "Actions",
      selector: "Actions",
      sortable: true,
      cell: (row) => (
        <IconButton aria-label="delete" onClick={() => DeleteCategory(row?.id)}>
          <DeleteForeverIcon style={{ color: "red" }} />
        </IconButton>
      ),
    },
  ];
  const [categories, setcategories] = useState([]);
  const [error, seterror] = useState({});
  const [success, setsuccess] = useState(false);
  const confirm = useConfirm();
  const DeleteCategory = async (cid) => {
    confirm({
      description:
        "Are you sure you want to delete this category? it will also delete its products!",
    })
      .then(async () => {
        await axios
          .post(`${Config.SERVER_URL}/category/delete/${cid}`)
          .then((res) => {
            setcategories(res.data.data);
            setsuccess(true);
            setTimeout(() => {
              setsuccess(true);
            }, 3000);
          })
          .catch((error) => {
            error.response &&
              error.response.status === 400 &&
              seterror(error.response.data);
            setTimeout(() => {
              seterror({});
            }, 3000);
          });
      })
      .catch(() => {
        /* ... */
      });
  };
  const [data, setdata] = useState(null);
  const [pagenumber, setpagenumber] = useState(1);
  const handleStatusChange = async (e, cate) => {
    await axios
      .post(`${Config.SERVER_URL}/category/changestatus/${cate?.id}`, {
        status: e.target.checked,
      })
      .then((res) => {
        setcategories(res.data.data);
        setdata(res.data);
      })
      .catch((error) => {});
  };
  const [isLoading, setisLoading] = useState(true);
  const [search, setsearch] = useState("");

  useEffect(() => {
    const init = async () => {
      await axios
        .get(
          `${Config.SERVER_URL}/category?search=${search}&page=${pagenumber}`
        )
        .then((response) => {
          if (response.data) {
            setcategories(response.data.data);
            setdata(response.data);
            setisLoading(false);
          }
        })
        .catch((error) => {});
    };
    init();
  }, [search, pagenumber]);

  return (
    <Container maxWidth="xl">
      <Box>
        <Grid container justify="space-between" spacing={3}>
          <Grid
            item
            xs
            className="d-flex justify-content-end align-items-center"
          >
            <TextField
              variant="outlined"
              size="small"
              onChange={(e) => setsearch(e.target.value)}
            ></TextField>
            <Link to="/category/new" className="mx-2 mt-2">
              <Button variant="contained" color="primary" className="h-100">
                Add Category
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {error &&
          Object.keys(error).map((key) => {
            return (
              <Alert severity="error" key={key}>
                {error[key]}
              </Alert>
            );
          })}
        {success && (
          <Alert severity="success">Category Deleted Successfully</Alert>
        )}
        <DataTable
          columns={columns}
          data={categories}
          defaultSortField="title"
          SortIcon={<SortIcon />}
          progressPending={isLoading}
          pagination
          selectableRows
          paginationPerPage={10}
          paginationServer={true}
          paginationTotalRows={data?.total}
          onChangePage={(e) => setpagenumber(e)}
        />
      </Box>
    </Container>
  );
}
function Brands() {
  const columns = [
    {
      name: "Image",
      selector: "image",
      sortable: false,
      cell: (row) => <img src={row?.image} alt={row?.id} width="100" />,
    },
    {
      name: "Brand Name",
      selector: "brand_name",
      sortable: true,
      cell: (row) => (
        <Link to={`/brand/edit/${row?.id}`}>{row?.brand_name}</Link>
      ),
    },
    {
      name: "Products",
      selector: "products_count",
      sortable: true,
    },
    {
      name: "Actions",
      selector: "Actions",
      sortable: true,
      cell: (row) => (
        <IconButton aria-label="delete" onClick={() => DeleteBrand(row?.id)}>
          <DeleteForeverIcon style={{ color: "red" }} />
        </IconButton>
      ),
    },
  ];
  const [brands, setbrands] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [search, setsearch] = useState("");
  const [pagenumber, setpagenumber] = useState(1);
  const [data, setdata] = useState(null);
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState({});
  const confirm = useConfirm();
  const DeleteBrand = async (cid) => {
    confirm({
      description:
        "Are you sure you want to delete this brand? it will also delete its products!",
    })
      .then(async () => {
        await axios
          .post(`${Config.SERVER_URL}/brand/delete/${cid}`)
          .then((res) => {
            setbrands(res.data);
            setsuccess(true);
            setTimeout(() => {
              setsuccess(true);
            }, 3000);
          })
          .catch((error) => {
            error.response &&
              error.response.status === 400 &&
              seterror(error.response.data);
            setTimeout(() => {
              seterror({});
            }, 3000);
          });
      })
      .catch(() => {
        /* ... */
      });
  };
  useEffect(() => {
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}/brand?search=${search}&page=${pagenumber}`)
        .then((response) => {
          if (response.data) {
            setbrands(response.data.data);
            setdata(response.data);
            setisLoading(false);
          }
        })
        .catch((error) => {
          setisLoading(false);
        });
    };
    init();
  }, [search, pagenumber]);
  return (
    <Container maxWidth="xl">
      <Box>
        <Grid container justify="space-between" spacing={3}>
          <Grid
            item
            xs
            className="d-flex justify-content-end align-items-center"
          >
            <TextField
              variant="outlined"
              size="small"
              onChange={(e) => setsearch(e.target.value)}
            ></TextField>
            <Link to="/brand/new" className="mx-1 ">
              <Button variant="contained" color="primary" className="h-100">
                Add Brand
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {error &&
          Object.keys(error).map((key) => {
            return (
              <Alert severity="error" key={key}>
                {error[key]}
              </Alert>
            );
          })}
        {success && (
          <Alert severity="success">Category Deleted Successfully</Alert>
        )}
        <DataTable
          columns={columns}
          data={brands}
          defaultSortField="title"
          SortIcon={<SortIcon />}
          pagination
          progressPending={isLoading}
          selectableRows
          paginationPerPage={10}
          paginationServer={true}
          paginationTotalRows={data?.total}
          onChangePage={(e) => setpagenumber(e)}
        />
      </Box>
    </Container>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

export default Products;
