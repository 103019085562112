import React, { useEffect, useState } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Paper, Box, Button } from "@material-ui/core";
import axios from "axios";
import Config from "../../../config.json";
import { Alert } from "@material-ui/lab";
function Terms() {
  const [content, setcontent] = useState("");
  const [success, setsuccess] = useState(false);
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/terms`)
        .then((response) => {
          if (response.data) {
            let value = response.data;
            setcontent(value.body)
          }
        })
        .catch((error) => {
         
        });
    };
    init()
  }, []);
  const [newContent, setnewContent] = useState("");
  const handleSubmit = async () => {
    await axios
      .post(`${Config.SERVER_URL}/terms/update`, { body: newContent })
      .then((response) => {
        setsuccess(true);
        if (response.data) {
          let value = response.data;
          setcontent(value.body)
        }
      })
      .catch((error) => {
       
      });
  };
  return (
      <>
        <Paper>
        {success && (
          <Alert severity="success">Terms Updated successfully</Alert>
        )}
        <CKEditor
          editor={ClassicEditor}
          data={content}
          onChange={(event, editor) => {
            const data = editor.getData();
            setnewContent(data);
          }}
        />
      </Paper>
      <Box display="flex" justifyContent="flex-end" className="my-2">
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          Save
        </Button>
      </Box>
      </>
  );
}

export default Terms;
