import React, { useState, useEffect } from "react";
import Logo from "../../assets/logo/ADVABlack.svg";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Button, Grid, TextField, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    maxWidth: "95%",
    margin: "auto",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default function Verification() {
  const classes = useStyles();
  const [Code, setCode] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <Paper className={classes.root}>
      <Link to="/login" className="back mx-4">
        <ArrowBackIcon />
      </Link>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={Logo} alt="" />
          <Typography component="h1" variant="h5">
            Account Recovery
          </Typography>
        </div>
        <div>
          <Typography
            variant="body1"
            color="textSecondary"
            component="h6"
            align="center"
          >
            Enter Verification code sent to your email younasrahimi@gmail.com
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            placeholder="Enter Verification Code"
            id="Code"
            autoComplete="Code"
            size="small"
            autoFocus
            value={Code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
        </div>
        <Grid container justify="center">
          <Link to="/account-recovery/reset-password">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Verify
          </Button>
          </Link>
        </Grid>
      </Container>
    </Paper>
  );
}
