import React from "react";
import {
  Typography,
  Tabs,
  Box,
} from "@material-ui/core";
import { Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { Container } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import NavigateNextIcon  from '@material-ui/icons/NavigateNext';
import Returns from './../Returns/Index';
import AllOrders from './component/AllOrders';
import Completed from './component/Completed';
import Processed from './component/Processed';
import Delivering from './component/Delivering';

function Orders() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        Orders
      </Typography>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}   aria-label="breadcrumb" className="mb-3">
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/orders">
          Order
        </Link>
      </Breadcrumbs>
      <Paper evaluation={1} className="px-2">
        <AppBar position="static" color="transparent" elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="nav tabs example"
          >
            <Tab label="Orders" {...a11yProps(0)} />
            <Tab label="Returns" {...a11yProps(1)} />
            <Tab label="Completed" {...a11yProps(2)} />
            <Tab label="Processed" {...a11yProps(3)} />
            <Tab label="Delivering" {...a11yProps(4)} />
            {/* <Typography className="ml-auto">hi</Typography> */}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <AllOrders />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Returns />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Completed />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Processed />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Delivering />
        </TabPanel>
      </Paper>
    </Container>
  );
}



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

export default Orders;
