import React from "react";
import { FormControl, TextField } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import CountriesSelect from "../../Components/CountriesSelect";

export default function Payments({ onChangeHandler, data }) {
  return (
    <Grid container spacing={4} className="px-4">
      <Grid item xs={6}>
        <FormControl fullWidth>
          <label htmlFor="">Address</label>
          <TextField
            name="address"
            id="Address"
            defaultValue={data?.address}
            variant="outlined"
            color="primary"
            size="small"
            onChange={(e) => {
              onChangeHandler(e);
            }}
          ></TextField>
        </FormControl>
        <FormControl fullWidth>
          <label htmlFor="">Country</label>
          <CountriesSelect
            currentCountry={data?.country}
            handleChange={onChangeHandler}
          />
        </FormControl>
        <FormControl fullWidth>
          <label htmlFor="">City</label>
          <TextField
            name="city"
            id="City"
            defaultValue={data?.city}
            variant="outlined"
            color="primary"
            size="small"
            onChange={(e) => {
              onChangeHandler(e);
            }}
          ></TextField>
        </FormControl>
        <FormControl fullWidth>
          <label htmlFor="">Post Code</label>
          <TextField
            name="postal_code"
            id="postal_code"
            defaultValue={data?.postal_code}
            variant="outlined"
            color="primary"
            size="small"
            onChange={(e) => {
              onChangeHandler(e);
            }}
          ></TextField>
        </FormControl>
      </Grid>
    </Grid>
  );
}
