import React from "react";
import { Typography, Tabs, Box } from "@material-ui/core";
import { Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { Container } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Edit from "./component/Edit";
import Reviews from "./component/Reviews";
import Orders from "./component/Orders";

function ProductDetails() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        Products
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/" disabled>
          Products
        </Link>
      </Breadcrumbs>
      <Paper>
        <AppBar position="static" color="transparent" elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="nav tabs example"
          >
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Reviews" {...a11yProps(1)} />
            <Tab label="Orders" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Edit />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Reviews />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Orders />
        </TabPanel>
      </Paper>
    </Container>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}
export default ProductDetails;
