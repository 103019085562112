import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/Sort";
import axios from "axios";
import Config from "../../../config.json";
import { Alert } from "@material-ui/lab";
function Orders() {
  const { id } = useParams();
  const columns = [
    {
      name: "Order-ID",
      selector: "id",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <Link to={`/orders/details/${row.id}`} style={{ fontWeight: "bold" }}>
            {row.id}
          </Link>
        </div>
      ),
    },
    {
      name: "Customer",
      selector: "Customer",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
            {row.customer?.first_name} {row.customer?.last_name}
        </div>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    {
      name: "Dates",
      selector: "created_at",
      sortable: true,
    },
    {
      name: "Shipping",
      selector: "Shipping",
      sortable: true,
    },
    {
      name: "Total",
      selector: "total",
      sortable: true,
    },
  ];
  const [orders, setorders] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [error, seterror] = useState(false);
  const [search] = useState("");
  useEffect(() => {
    const init = async () => {
      setisLoading(true)
      await axios
        .get(`${Config.SERVER_URL}/product/orders/${id}`)
        .then((res) => {
          setisLoading(false);
          setorders(res.data);
        })
        .catch(() => {
          setisLoading(false);
          seterror(true);
        });
    };
    init();
  }, [search, id]);
  return (
    <>
    {error && <Alert severity="error">Something Went worng. Please refresh</Alert>}
      <DataTable
        columns={columns}
        data={orders}
        defaultSortField="Order-ID"
        SortIcon={<SortIcon />}
        pagination
        progressPending={isLoading}
        selectableRows
      />
    </>
  );
}

export default Orders;
