import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { mainListItems } from "./ListItem";
import Logo from "../assets/logo/ADVABlack.svg";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Menu, Box } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import useInterval from "./../Hooks/Interval";
import axios from "axios";
import Config from "../config.json";
import { useUserContext } from "./../Context/UserContext";
import { Button } from "antd";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
    backgroundColor: "white",
    color: "black",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: 3,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 363,
  },
}));

export default function Sidebar(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { logout } = useUserContext();
  const location = useHistory();
  const logoutuser = () => {
    sessionStorage.clear();
    logout();
    location.push("/login");
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
        elevation={1}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.title}>
            <Link to="/kisok" className="text-decoration-none">
              <Button type="default">Switch to KIOSK</Button>
            </Link>
          </div>
          <NotificationMenu />
          <IconButton color="inherit" onClick={handleClick}>
            <AccountCircleIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>
              <Link to="/profile" className="text-decoration-none text-dark">
                My account
              </Link>
            </MenuItem>
            <MenuItem onClick={logoutuser}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={Logo} alt="ADVA" width="160" />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={`${classes.content} admin`}>{props.component}</main>
    </div>
  );
}

function NotificationMenu() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [notification, setnotification] = useState(null);
  const { getuser } = useUserContext();
  const [user] = useState(getuser());
  const [count, setcount] = useState(0);

  useInterval(async () => {
    await axios
      .get(`${Config.SERVER_URL}/notification/get`)
      .then((res) => {
        setnotification(res.data.notification);
        setcount(res.data.count);
      })
      .catch((error) => {});
  }, 100000);
  useEffect(() => {
    window.scrollTo(0, 0);
    const init = async () => {
      if (user) {
        await axios
          .get(`${Config.SERVER_URL}/notification/get`)
          .then((res) => {
            setnotification(res.data.notification);
            setcount(res.data.count);
          })
          .catch((error) => {});
        if (anchorEl) {
          await axios
            .get(`${Config.SERVER_URL}/notifications/read`)
            .then((res) => {})
            .catch((error) => {});
        }
      }
    };
    init();
  }, [user, anchorEl]);
  return (
    <div>
      <IconButton
        onClick={handleClick}
        disableFocusRipple
        disableRipple
        className="py-0"
        style={{ fontSize: "16pt" }}
      >
        <Badge badgeContent={count} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="mx-2"
        style={{
          minWidth: 200,
          maxHeight: 301,
          overflowX: "auto",
          display: "flex",
        }}
      >
        {notification && Object.keys(notification).length > 0 ? (
          <div>
            {notification?.map((notif, index) => {
              return (
                <div key={index}>
                  <Box
                    style={{ minWidth: 200 }}
                    justifyContent="center"
                    alignItems="start"
                    display="flex"
                  >
                    {/* <img src={notif?.icon === 'offer' ? offerIcon : newProductIcon} alt="" width="25" className="mx-2" /> */}
                    <span
                      style={{
                        width: 150,
                        wordWrap: "break-word",
                        display: "inline-block",
                        fontSize: 12,
                        padding: "8px",
                      }}
                    >
                      {notif?.notification}
                    </span>
                  </Box>
                  <Divider />
                </div>
              );
            })}
          </div>
        ) : (
          <MenuItem>No Notifications</MenuItem>
        )}
      </Menu>
    </div>
  );
}
