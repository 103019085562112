import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { Breadcrumbs, Divider, ListItem, Card } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link } from "react-router-dom";
import { Container, Box } from "@material-ui/core";
import { Grid, Paper, TextField, Avatar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import sendIcon from "../../assets/icons/send.svg";
import UserCircle from "../../assets/images/user-circle.svg";
import { MoreHorizOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ScrollToBottom from "react-scroll-to-bottom";
import axios from "axios";
import Config from "../../config.json";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 700,
  },
  leftSide: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: "relative",
    height: 700,
    overflowY: "auto",
  },
  chat: {
    position: "relative",
    height: 650,
    overflowY: "auto",
    border: "1px solid lightgrey",
  },
  message: {
    maxWidth: "300px",
    wordBreak: "break-all",
    border: "1px solid #7070704D",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    margin: "auto 5px",
    padding: 5,
  },
}));
function Messages() {
  const classes = useStyles();
  const [AllConversations, setAllConversations] = useState([]);
  const [search, setsearch] = useState("");
  const [conmessages, setconmessages] = useState(null);
  const [message, setmessage] = useState("");
  const [msglist, setmsglist] = useState(null);
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/chat/conversations?search=${search}`)
        .then((response) => {
          if (response.data) {
            setAllConversations(response.data);
          }
          setmessage("");
        })
        .catch((error) => {});
    };
    init();
  }, [search]);
  const getConverstion = async (chat) => {
    setconmessages(chat);
    await axios
      .get(`${Config.SERVER_URL}/chat/messages/${chat?.id}`)
      .then((response) => {
        setmsglist(response.data);
      })
      .catch((error) => {});
  };

  const sendmessage = async () => {
    await axios
      .post(`${Config.SERVER_URL}/chat/reply`, {
        chat_id: conmessages.id,
        message: message,
      })
      .then((response) => {
        setmsglist(response.data);
      })
      .catch((error) => {});
  };
  const deletechat = async () => {
    await axios
      .post(`${Config.SERVER_URL}/chat/delete/${conmessages?.id}`)
      .then((res) => {
        setconmessages(null);
        setmsglist(null);
      })
      .catch((err) => {});
  };
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        Products
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/" disabled>
          Messages
        </Link>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Paper elevation={2} className="px-2 pb-5">
            <Grid container spacing={1}>
              <Grid xs item className="px-3">
                <TextField
                  name="Search"
                  id="Search"
                  color="primary"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => setsearch(e.target.value)}
                ></TextField>
              </Grid>
              {/* <Grid xs={2} item>
                <IconButton className="mx-1" disableRipple>
                  <img src={NewMessageIcon} alt="NW" />
                </IconButton>
              </Grid> */}
            </Grid>
            {/* Conversion List */}
            <Box className={classes.leftSide} component="div">
              {AllConversations &&
                AllConversations?.map((con) => {
                  return (
                    <>
                      <Grid
                        container
                        spacing={1}
                        onClick={() => getConverstion(con)}
                      >
                        <Grid xs item container justify="center">
                          <Avatar src={UserCircle} alt="user"></Avatar>
                        </Grid>
                        <Grid xs={9} item>
                          <Typography component="p">
                            {con?.customer?.first_name}
                            {con?.customer?.last_name}
                          </Typography>
                          {/* <Typography
                            component="p"
                            variant="caption"
                            display="block"
                          >
                            {con?.messages}
                          </Typography> */}
                        </Grid>
                        <Grid item xs>
                          {con.unread > 0 && <span className="not-seen"></span>}
                        </Grid>
                      </Grid>
                      <Divider className="my-3" />
                    </>
                  );
                })}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper className={classes.root}>
            <Grid container justify="center" className="py-3">
              {conmessages ? (
                <>
                  <Grid item xs={11}>
                    <Typography
                      component="h6"
                      variant="h6"
                      className="text-main"
                      align="center"
                    >
                      {conmessages?.customer?.first_name}
                      {conmessages?.customer?.last_name}
                    </Typography>
                  </Grid>
                  <Grid item container xs alignItems="center" justify="center">
                    <MoreHorizOutlined onClick={() => deletechat()} />
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
            <Divider />
            <Box className={classes.chat} component={ScrollToBottom}>
              <List>
                {msglist &&
                  msglist?.map((msg) => {
                    return msg?.user_id !== 0 ? (
                      <>
                        <ListItem className="d-flex justify-content-start chat-left">
                          <Avatar src={UserCircle} alt="user" />
                          <Box
                            component={Card}
                            elevation={0}
                            className={classes.message}
                          >
                            {msg?.message}
                          </Box>
                        </ListItem>
                      </>
                    ) : (
                      <>
                        <ListItem className="d-flex justify-content-end chat-right">
                          <Box
                            component={Card}
                            elevation={0}
                            className={classes.message}
                          >
                            {msg?.message}
                          </Box>
                          <Avatar src={UserCircle} alt="user" />
                        </ListItem>
                      </>
                    );
                  })}
              </List>
            </Box>

            <Box className="mt-1">
              <form>
                <Grid container justify="center" className="py-3">
                  <Grid item xs={10}>
                    <TextField
                      color="primary"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={message}
                      onChange={(e) => setmessage(e.target.value)}
                    ></TextField>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      disableRipple
                      onClick={sendmessage}
                      type="reset"
                    >
                      <Avatar src={sendIcon} alt="send"></Avatar>
                    </IconButton>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Messages;
