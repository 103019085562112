import React from "react";
import Logo from "../../assets/logo/ADVABlack.svg";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Button, FormControl, Grid, Paper, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    maxWidth: "95%",
    margin: "auto",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  email: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default function AccountRecovery() {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Link to="/login" className="back mx-4">
        <ArrowBackIcon />
      </Link>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={Logo} alt="" />
          <Typography component="h1" variant="h5">
            Account Recovery
          </Typography>

          <Typography component="p" variant="h6" className="mt-5 mb-1 w-100">
            Get Verification code
          </Typography>
          <Typography component="p"  className={classes.email}>
            Please enter your email or Phone number to get a verification code
          </Typography>
          <FormControl fullWidth className="mt-5">
            <label htmlFor="">Email Address</label>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              size="small"
              autoFocus
            />
          </FormControl>
        </div>
        <Grid container justify="flex-end">
          <Link
            to="/account-recovery/verify"
            className="text-decoration-none"
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Send
            </Button>
          </Link>
        </Grid>
      </Container>
    </Paper>
  );
}
