import React, { createContext, useState, useContext } from "react";
const UserContext = createContext();
export const useUserContext = () => useContext(UserContext);

export default function UserProvider(props) {
  const [user, setuser] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const login = (data) => {
    sessionStorage.setItem("user", JSON.stringify(data));
    setuser(data);
  };
  const logout = () => {
    sessionStorage.clear();
    setuser(null);
  };
  const getuser = () => {
    return user;
  };
  const role = () => {
    return user ? user?.role : null;
  };
  const isLogined = () => {
    return user ? true : false;
  };
  const isAdmin = () => {
    return user?.role === "admin" ? true : false;
  };
  const isSalesman = () => {
    return user?.role === "salesman" ? true : false;
  };
  const getid = () => {
    return user?.id;
  };
  const isWriteOnly = () => {
    return user?.permission === "write";
  };
  const isReadOnly = () => {
    return user?.permission === "read";
  };
  return (
    <UserContext.Provider
      value={{
        login,
        logout,
        getuser,
        role,
        isLogined,
        isAdmin,
        isSalesman,
        getid,
        isWriteOnly,
        isReadOnly,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
