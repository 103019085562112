import React, { useState } from "react";
import {
  Button,
  Container,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link, useHistory } from "react-router-dom";
import {
  Paper,
  Box,
  Divider,
  Grid,
  Card,
  CardContent,
  FormControl,
} from "@material-ui/core";
import UserCircle from "../../assets/images/user-circle.svg";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Config from "../../config.json";
import { Alert } from "@material-ui/lab";
const useStyles = makeStyles(() => ({
  card: {
    minHeight: 650,
  },
  profile: {
    marginTop: "27px",
    padding: "16px",
  },
  list: {
    "& .MuiListItem-root": {
      paddingTop: "19px",
      paddingBottom: "19px",
      cursor: "pointer",
    },
    "& .MuiListItem-root.active": {
      backgroundColor: "#F1BD8E",
    },
  },
  select: {
    padding: "0px 14px !important",
  },
  imageround: {
    borderRadius: "50%",
  },
}));
function AddStaff() {
  const classes = useStyles();
  const [image, setimage] = useState(null);
  const [ImagesURL, setImagesURL] = useState(null);
  const [UserForm, setUserForm] = useState({
    password: "",
    phone: "",
    email: "",
    first_name: "",
    last_name: "",
    dob: "",
    confirm_password: "",
    commission: "",
    permission: "",
  });

  function handleImageChanges(e) {
    let imagesTemp = e.target.files[0];
    let imagesTempURL = URL.createObjectURL(e.target.files[0]);
    setimage(imagesTemp);
    setImagesURL(imagesTempURL);
  }
  function handleFormChange(e) {
    UserForm[e.target.name] = e.target.value;
    setUserForm(UserForm);
  }
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState({});
  const location = useHistory();
  const AddStaff = async () => {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("password", UserForm.password);
    formData.append("phone", UserForm.phone);
    formData.append("first_name", UserForm.first_name);
    formData.append("last_name", UserForm.last_name);
    formData.append("dob", UserForm.dob);
    formData.append("confirm_password", UserForm.confirm_password);
    formData.append("commission", UserForm.commission);
    formData.append("email", UserForm.email);
    formData.append("role", UserForm.role);
    formData.append("permission", UserForm.permission);
    await axios
      .post(`${Config.SERVER_URL}/staff/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        seterror(false);
        setsuccess(true);
        setTimeout(() => {
          location.push(`/staff/details/${response.data.id}`);
        }, 3000);
      })
      .catch((error) => {
        setsuccess(false);
        seterror(error.response.data);
      });
  };
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        Staff
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/staff/order/create">
          Staff
        </Link>
        <Link color="inherit" to="/" disabled>
          Insert
        </Link>
      </Breadcrumbs>
      <Paper className="p-4">
        {error &&
          Object.keys(error).map((key) => {
            return (
              <Alert key={key} severity="error">
                {error[key]}
              </Alert>
            );
          })}
        {success && (
          <Alert severity="success">
            Staff added successfully. You will be redirected in 3 sec
          </Alert>
        )}
        <Box className="p-4">
          <Grid item container xs={3}>
            <Card elevation={0}>
              <CardContent className="p-0">
                <Box
                  flexGrow={1}
                  alignContent="center"
                  className={classes.profile}
                >
                  <div className="">
                    {image ? (
                      <img
                        src={ImagesURL}
                        width="150"
                        height="150"
                        alt="banner"
                        className={classes.imageround}
                      />
                    ) : (
                      <img src={UserCircle} alt="profile" className="" />
                    )}
                  </div>
                  <label className="imageUpload text-center">
                    Upload Image
                    <input
                      type="file"
                      name="banner"
                      id="banner"
                      onChange={handleImageChanges}
                    />
                  </label>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Divider />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography component="h6" variant="body1" className="my-3">
                Personal Information
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormControl>
                    <label htmlFor="">First Name</label>
                    <TextField
                      name="first_name"
                      id="first_name"
                      type="text"
                      color="primary"
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleFormChange(e)}
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    <label htmlFor="">Last Name</label>
                    <TextField
                      name="last_name"
                      id="last_name"
                      type="text"
                      color="primary"
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleFormChange(e)}
                    ></TextField>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <label htmlFor="">Date of Birth</label>
                    <TextField
                      name="dob"
                      id="dob"
                      color="primary"
                      variant="outlined"
                      size="small"
                      type="date"
                      onChange={(e) => handleFormChange(e)}
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    <label htmlFor="">Email Address</label>
                    <TextField
                      name="email"
                      id="email"
                      type="email"
                      color="primary"
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleFormChange(e)}
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    <label htmlFor="">Phone No.</label>
                    <TextField
                      name="phone"
                      id="phone"
                      type="tel"
                      color="primary"
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleFormChange(e)}
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    <label htmlFor="">Password.</label>
                    <TextField
                      name="password"
                      id="password"
                      type="password"
                      color="primary"
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleFormChange(e)}
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    <label htmlFor="">Confirm Password.</label>
                    <TextField
                      name="confirm_password"
                      id="confirm_password"
                      type="password"
                      color="primary"
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleFormChange(e)}
                    ></TextField>
                  </FormControl>
                </Grid>
              </Grid>
              <Typography component="p" variant="body1" className="my-3">
                Other Information
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <label htmlFor="">Permission</label>
                  <FormControl fullWidth className="mt-3">
                    <Select
                      color="primary"
                      variant="outlined"
                      onChange={(e) => handleFormChange(e)}
                      name="permission"
                    >
                      <MenuItem value={""}>Select Permission</MenuItem>
                      <MenuItem value={"read"}>Read</MenuItem>
                      <MenuItem value={"write"}>Write</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <label htmlFor="">Commission Per Sale</label>
                  <FormControl>
                    <TextField
                      name="commission"
                      onChange={(e) => handleFormChange(e)}
                      id="commission"
                      type="number"
                      color="primary"
                      variant="outlined"
                      size="small"
                    ></TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="flex-end">
            <Button color="primary" variant="contained" onClick={AddStaff}>
              Save
            </Button>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}

export default AddStaff;
