import React, { useState, useEffect } from "react";
import { Grid, Box, Menu, MenuItem, Button } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/Sort";
import axios from "axios";
import Config from "../../../config.json";
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useUserContext } from './../../../Context/UserContext';
import { Alert } from "@material-ui/lab";
import { useConfirm } from 'material-ui-confirm';
export default function Promo() {
  const columns = [
    {
      name: "Promo Code",
      selector: "promo_code",
      sortable: true,
    },
    {
      name: "Recieve Points",
      selector: "points_for_customer",
      sortable: true,
    },
    {
      name: "Reward Points",
      selector: "points_for_user",
      sortable: true,
    },
    {
      name: "Start Date",
      selector: "date_start",
      sortable: true,
    },
    {
      name: "End Date",
      selector: "date_end",
      sortable: true,
    },
    {
      name: "Action",
      sortable: true,
      width: "150px",
      cell: (row) => (
        <ActionMenu promo={row} setpromo={setpromo} setsuccess={setsuccess} seterror={seterror} />
      ),
    },
  ];
  const [success, setsuccess] = useState(false)
  const [error, seterror] = useState({})
  const [promo, setpromo] = useState([]);
  const [search, setsearch] = useState("");
  const [isLoading, setisLoading] = useState(false);
  function handleChange(e) {
   
  }
  useEffect(() => {
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}/get/promo?search=${search}`)
        .then((response) => {
          setisLoading(false);
          setpromo(response.data);
        })
        .catch((error) => {
         
          setisLoading(false);
        });
    };
    init();
  }, [search]);
  return (
    <>
      <Grid container justify="flex-end">
        <Grid item xs>
          <Box className="d-flex align-items-center justify-content-end my-3">
            <DeleteForeverIcon className="text-main" fontSize="large" />
            <TextField
              name="Search"
              id="Search"
              color="primary"
              variant="outlined"
              size="small"
              onChange={(e) => setsearch(e.target.value)}
            />
            <Link to="/marketing/promo/add">
              <Button color="primary" variant="contained" className="mx-3">
                Add Promo
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
      {
        success && <Alert severity="success">Operation Successfull</Alert>
      }
      {
        error && Object.keys(error).map(key => {
          return <Alert severity="error" key={key}>{error[key]}</Alert>
        })
      }
      <DataTable
        columns={columns}
        data={promo}
        defaultSortField="Promo Code"
        SortIcon={<SortIcon />}
        pagination
        selectableRows
        progressPending={isLoading}
        onSelectedRowsChange={handleChange}
      />
    </>
  );
}

function ActionMenu({ promo, setpromo, setsuccess, seterror }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { getuser } = useUserContext()
  const [user] = useState(getuser());
  const confirm = useConfirm();
  const handleDelete = async () => {
    confirm({ description: `Are you sure you want to delete ${promo?.promo_code}!` })
    .then(async() => { 
      await axios.post(`${Config.SERVER_URL}/promo/delete/${promo?.id}`, {
        admin_id: user?.id
      }).then(res => {
        setsuccess(true);
        setpromo(res.data)
        handleClose();
        setTimeout(() => {
          setsuccess(false)
        }, 3000);
      }).catch(error => {
        if (error?.response && error?.response.status === 400)
          seterror(error.response.data)
        setTimeout(() => {
          seterror({})
        }, 3000);
      })
      
     })
    .catch(() => { /* ... */ });
  };
  return (
    <div>
      <IconButton
        aria-label="settings"
        className="py-0"
        style={{ fontSize: "16pt" }}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </div>
  );
}