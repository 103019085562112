import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  Container,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Box,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { Grid, TableBody } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Config from "../../config.json";
import Alert from "@material-ui/lab/Alert";
import Payments from "../Orders/component/AddPayment";
import Customer from "../Orders/component/Customer";
import { useUserContext } from "./../../Context/UserContext";
const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    paddingBottom: "10px",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      background: "#BF6159",
    },
  },
  completed: {
    "& $line": {
      background: "#BF6159",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    border: "1px solid #BF6159",
    background: "transparent",
    "& svg": {
      color: "#BF6159",
    },
  },
  completed: {
    background: "#BF6159",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
    4: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,

  completed: PropTypes.bool,

  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Customer",
    "Payment & Shipping Address",
    "Select Product",
    "Checkout Details",
  ];
}

function getStepContent(step, handler, data, quantityHandler) {
  switch (step) {
    case 0:
      return <Customer onChangeHandler={handler} data={data} />;
    case 1:
      return <Payments onChangeHandler={handler} data={data} />;
    case 2:
      return (
        <Products
          onChangeHandler={handler}
          data={data}
          quantityChange={quantityHandler}
        />
      );
    case 3:
      return (
        <CheckOut
          onChangeHandler={handler}
          data={data}
          address={quantityHandler}
        />
      ); //here quantity is used as data holder instead of function
    default:
      return "Unknown step";
  }
}

export default function StaffOrder() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const { getuser } = useUserContext();
  const [staff] = useState(getuser());
  const location = useHistory();
  const CreateOrder = async () => {
    setisLoading(true);
    await axios
      .post(`${Config.SERVER_URL}/staff/order/create`, {
        ...customer,
        ...PaymentsAndShipping,
        products: product,
        staff_id: staff?.id,
      })
      .then((response) => {
        setsuccess(true);
        setTimeout(() => {
          location.push("/staff/order");
        }, 3000);
      })
      .catch((r) => {
        setisLoading(false);
        if (r.response.status === 400) {
          seterror(r.response.data);
        }
      });
  };
  const [PaymentsAndShipping, setPaymentsAndShipping] = useState({
    country: "Saudi Arabia",
  });

  const [customer, setcustomer] = useState({
    customer_id: "0",
    received_from: 4,
  });
  // eslint-disable-next-line
  const [product, setproduct] = useState([]);

  const CheckOutDataChange = (e) => {
    PaymentsAndShipping[e.target.name] = e.target.value;
    setPaymentsAndShipping(PaymentsAndShipping);
  };

  const PaymentsAndShippingDataChange = (e) => {
    PaymentsAndShipping[e.target.name] = e.target.value;
    setPaymentsAndShipping(PaymentsAndShipping);
  };
  const CustomerDataChange = (e) => {
    customer[e.target.name] = e.target.value;
    setcustomer(customer);
  };
  // eslint-disable-next-line
  const [dummy, setdummy] = useState(false);
  const ProductDataChange = (e, prod) => {
    if (e.target.checked) {
      const rProduct = product.filter((p) => {
        return p.id === prod.id;
      });
      if (rProduct.length > 0) {
        return;
      }
      prod["quantity"] = 1;
      product.push(prod);
      setproduct(product);
      setdummy(!dummy);
    } else {
      const rProduct = product.filter((p) => {
        return p.id !== prod.id;
      });
      setproduct(rProduct);
    }
  };
  const quantityDataChange = (value, index) => {
    const rProduct = [...product];
    if (rProduct[index]["quantity"] === 0 && value === -1) {
      return;
    }
    rProduct[index]["quantity"] += value;
    setproduct(rProduct);
  };
  const handleNext = () => {
    if (product === [] && steps === 2) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Container maxWidth="xl" className="mainContainer ">
      <Typography component="h5" variant="h5">
        Add Order
      </Typography>

      <Paper evaluation={1} className="px-2 mt-3 py-4">
        <div className={classes.root}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <>
                {error &&
                  Object.keys(error).map((key) => (
                    <Alert severity="error" className="m-1">
                      {error[key]}
                    </Alert>
                  ))}
                <Grid xs={12} container justify="center" item>
                  {success && (
                    <Alert severity="success">
                      Order Created.You will be redirected in 3 secs
                    </Alert>
                  )}
                  {error && (
                    <Button
                      onClick={() => {
                        setActiveStep(0);
                        seterror(null);
                      }}
                      variant="outlined"
                      color="default"
                      className="mx-2"
                    >
                      Go Back
                    </Button>
                  )}
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isLoading}
                    onClick={() => {
                      CreateOrder();
                    }}
                  >
                    Confirm Order
                  </Button>
                </Grid>
              </>
            ) : (
              <div>
                {/* get Content */}
                {activeStep === 0
                  ? getStepContent(activeStep, CustomerDataChange, customer)
                  : activeStep === 1
                  ? getStepContent(
                      activeStep,
                      PaymentsAndShippingDataChange,
                      PaymentsAndShipping
                    )
                  : activeStep === 2
                  ? getStepContent(
                      activeStep,
                      ProductDataChange,
                      product,
                      quantityDataChange
                    )
                  : getStepContent(
                      activeStep,
                      CheckOutDataChange,
                      product,
                      PaymentsAndShipping
                    )}
                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Paper>
    </Container>
  );
}

const ProductsStyles = makeStyles(() => ({
  button: {
    minWidth: 15,
    minHeight: 15,
    padding: "3px",
    "& .MuiTouchRipple-root": {
      minWidth: 15,
      minHeight: 15,
    },
    "& .MuiButton-label span": {
      margin: 0,
    },
    checkbox: {
      "& path": {
        color: "#BF6159",
      },
    },
  },
}));
function Products({ onChangeHandler, data, quantityChange }) {
  const classes = ProductsStyles();
  const [products, setproducts] = useState(null);
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/product/all`)
        .then((response) => {
          if (response.data) setproducts(response.data);
        })
        .catch((error) => {});
    };
    init();
  }, []);
  return (
    <Grid container spacing={4} className="px-4">
      <Grid item xs={6}>
        <Typography component="p">Select Items</Typography>
        <TableContainer style={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell component="th">Name</TableCell>
                <TableCell component="th">Brand</TableCell>
                <TableCell component="th">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products &&
                products.map((product, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          onChange={(e) => {
                            onChangeHandler(e, product);
                          }}
                          className={classes.checkbox}
                        />
                      </TableCell>
                      <TableCell component="td">
                        {product?.product_name}
                      </TableCell>
                      <TableCell component="td">
                        {product?.brand?.brand_name}
                      </TableCell>
                      <TableCell component="td">{product?.price}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={6}>
        {data &&
          data.map((p, index) => {
            return (
              <Card key={index} className="mb-2">
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <img
                        src={p?.productimages[0]?.picture_reference}
                        alt=""
                        className="auto"
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography component="h6" variant="h6">
                        {p.product_name}
                      </Typography>
                      <Typography component="p" className="pt-2">
                        {p.brand?.brand_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography component="h6" variant="h6" align="right">
                        SAR. {p.price}
                      </Typography>
                      <div className="d-flex justify-content-end align-items-center">
                        <Typography component="p" className="px-2">
                          Quantity
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<RemoveIcon />}
                          className={classes.button}
                          disableRipple={true}
                          onClick={() => {
                            quantityChange(-1, index);
                          }}
                        ></Button>
                        <Typography component="p" variant="h6" className="px-2">
                          {p.quantity}
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<AddIcon />}
                          className={classes.button}
                          disableRipple={true}
                          onClick={() => {
                            quantityChange(1, index);
                          }}
                        ></Button>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
      </Grid>
    </Grid>
  );
}
function CheckOut({ data, address }) {
  const [shipping, setshipping] = useState(0);
  const [cod, setcod] = useState(0);
  const [tax, settax] = useState(0);
  const [freeShipping, setfreeShipping] = useState(0);
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/shippingrate`)
        .then((res) => {
          setshipping(
            address?.country === "Saudi Arabia"
              ? res.data?.shipping_rate
              : res.data?.international_charges
          );
          setfreeShipping(
            address?.country === "Saudi Arabia"
              ? res.data?.free_shipping
              : res.data?.free_international_shipping
          );
        })
        .catch((error) => {});
      await axios
        .get(`${Config.SERVER_URL}/cod`)
        .then((res) => {
          setcod(res.data?.charges);
        })
        .catch((error) => {});
      await axios
        .get(`${Config.SERVER_URL}/tax`)
        .then((res) => {
          settax(res.data?.tax_value);
        })
        .catch((error) => {});
    };
    init();
  }, [address]);
  var vat = React.useMemo(() => {
    var cVat = 0;
    data.forEach((p) => {
      if (p.vat !== 0) {
        var amount = p.price * p.quantity;
        cVat = amount * (tax / 100);
      }
    });
    return cVat;
  }, [data, tax]);
  const total = React.useMemo(() => {
    let computed = 0;
    data.forEach((p) => {
      if (p.vat !== 0) {
        var amount = p.price * p.quantity;
        var cVat = amount * (tax / 100);
        computed += cVat + amount;
      } else {
        computed += p.price * p.quantity;
      }
    });
    return computed;
  }, [data, tax]);
  return (
    <Grid container spacing={8}>
      <Grid item xs={6}>
        {data &&
          data.map((p, index) => {
            return (
              <Card key={index} className="mb-2 mx-4">
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <img
                        src={p?.productimages[0]?.picture_reference}
                        alt=""
                        className="auto"
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography component="h6" variant="h6">
                        {p?.product_name}
                      </Typography>
                      <Typography component="p" className="pt-2">
                        {p?.brand?.brand_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography component="h6" variant="h6" align="right">
                        SAR. {p?.price}
                      </Typography>
                      <Typography component="p" align="right">
                        Quantity: {p?.quantity}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span className="f-18">cod:</span>
          <span className="f-18 text-main">{parseFloat(cod).toFixed(2)}</span>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span className="f-18">VAT:</span>
          <span className="f-18 text-main">{parseFloat(vat).toFixed(2)}</span>
        </Box>
        {freeShipping > total && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <span className="f-18">Shipping:</span>
            <span className="f-18 text-main">
              {parseFloat(shipping).toFixed(2)}
            </span>
          </Box>
        )}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span className="f-18">Total:</span>
          <span className="f-18 text-main">
            {parseFloat(
              total + cod + (freeShipping > total ? shipping : 0)
            ).toFixed(2)}
          </span>
        </Box>
      </Grid>
    </Grid>
  );
}
