import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./pages/admin/Login";
import AccountRecovery from "./pages/admin/AccountRecovery";
import Verification from "./pages/admin/Verification";
import RecoverPassword from "./pages/admin/RecoverPassword";
import PrivateRoute from "./pages/Components/Routes/PrivateRoute";
import Dashboard from "./pages/Dashboard/Index";
import Orders from "./pages/Orders/Index";
import Sidebar from "./Layout/Sidebar";
import AddOrder from "./pages/Orders/Add";
import OrderDetails from "./pages/Orders/Details";
import AddReturn from "./pages/Returns/Add";
import Products from "./pages/Products/Index";
import AddProduct from "./pages/Products/Add";
import AddCategory from "./pages/Category/Add";
import AddBrand from "./pages/Brands/Add";
import Customers from "./pages/Customers/Index";
import CustomerDetails from "./pages/Customers/Details";
import Staffs from "./pages/Staff/Index";
import StaffDetails from "./pages/Staff/Details";
import Messages from "./pages/Messages/Index";
import MediaManager from "./pages/MediaManager/Index";
import ChatBot from "./pages/ChatBot/Index";
import Reports from "./pages/Reports/Index";
import Settings from "./pages/Settings/Index";
import AdminProfile from "./pages/admin/Profile/Index";
import Marketing from "./pages/Marketing/Index";
import AddVocuher from "./pages/Marketing/Voucher/Add";
import AddPromo from "./pages/Marketing/Promo/Add";
import AddDiscountOffer from "./pages/Marketing/Offers/Discount/Add";
import AddBogoOffer from "./pages/Marketing/Offers/Bogo/Add";
import QuestionAndAnswer from "./pages/QuestionsAndAnswer/Index";
import ProductDetails from "./pages/Products/Details";
import AddStaff from "./pages/Staff/Add";
import EditCategory from "./pages/Category/Edit";
import EditBrand from "./pages/Brands/Edit";
import StaffLogin from "./pages/Staff/Login";
import SalesManRoute from "./pages/Components/Routes/SalesManRoute";
import StaffOrder from "./pages/Staff/Order";
import StaffSidebar from "./Layout/StaffSidebar";
import StaffAllOrder from "./pages/Staff/Orders/Index";
import StaffProfle from "./pages/Staff/Profile";
import ReturnDetails from "./pages/Returns/Details";
import AddDiscountCode from "./pages/Marketing/DiscountCode/Add";
import StaffOrderDetails from "./pages/Staff/Orders/Details";
import EditDiscountOffer from "./pages/Marketing/Offers/Discount/Edit";
import { Suspense } from "react";
const KisokSidebar = React.lazy(() => import("./Layout/kisok/Sidebar"));
const KProductsSection = React.lazy(() =>
  import("./pages/kisok/Products/index")
);
const KAddProduct = React.lazy(() =>
  import("./pages/kisok/Products/Products/Add")
);
const KEditProduct = React.lazy(() =>
  import("./pages/kisok/Products/Products/Edit")
);
const KAddCategory = React.lazy(() =>
  import("./pages/kisok/Products/Category/Add")
);
const KEditCategory = React.lazy(() =>
  import("./pages/kisok/Products/Category/Edit")
);
const KAddBrand = React.lazy(() => import("./pages/kisok/Products/Brands/Add"));
const KEditBrand = React.lazy(() => import("./pages/kisok/Products/Brands/Edit"));
const KShopsSection = React.lazy(() => import("./pages/kisok/Shops/index"));
const KEditShop = React.lazy(() => import("./pages/kisok/Shops/Shops/Edit"));
const KAddShop = React.lazy(() => import("./pages/kisok/Shops/Shops/Add"));
const KAddBranch = React.lazy(() => import("./pages/kisok/Shops/Branches/Add"));
const KEditBranch = React.lazy(() =>
  import("./pages/kisok/Shops/Branches/Edit")
);
const KBranchDetails = React.lazy(() =>
  import("./pages/kisok/Shops/Branches/Details")
);
const KOrders = React.lazy(() => import("./pages/kisok/orders/index"));
const KRequestReturn = React.lazy(() =>
  import("./pages/kisok/RequestAndReturn/index")
);

const KReports = React.lazy(() => import("./pages/kisok/Reports/index"));
const KSettings = React.lazy(() => import("./pages/kisok/Settings/index"));
const KOrderDetails = React.lazy(() => import("./pages/kisok/orders/Details"));
function App() {
  return (
    <Router basename="/admin">
      <Switch>
        {/* Admin Routes */}
        <PrivateRoute path="/" exact>
          <Sidebar component={<Dashboard />}> </Sidebar>
        </PrivateRoute>

        <Route path="/login" component={Login} exact />
        <Route path="/account-recovery" component={AccountRecovery} exact />
        <Route path="/account-recovery/verify" component={Verification} exact />
        <Route
          path="/account-recovery/reset-password"
          component={RecoverPassword}
          exact
        />
        <PrivateRoute path="/dashboard" component={Dashboard} exact />
        <PrivateRoute path="/orders" exact>
          <Sidebar component={<Orders />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/orders/new" exact>
          <Sidebar component={<AddOrder />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/orders/details/:id" exact>
          <Sidebar component={<OrderDetails />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/orders/return/details/:id" exact>
          <Sidebar component={<ReturnDetails />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/returns/new/:id" exact>
          <Sidebar component={<AddReturn />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/products" exact>
          <Sidebar component={<Products />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/products/details/:id" exact>
          <Sidebar component={<ProductDetails />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/products/new" exact>
          <Sidebar component={<AddProduct />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/category/new" exact>
          <Sidebar component={<AddCategory />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/category/edit/:id" exact>
          <Sidebar component={<EditCategory />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/brand/new" exact>
          <Sidebar component={<AddBrand />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/brand/edit/:id" exact>
          <Sidebar component={<EditBrand />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/customers" exact>
          <Sidebar component={<Customers />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/customers/details/:id" exact>
          <Sidebar component={<CustomerDetails />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/staff" exact>
          <Sidebar component={<Staffs />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/staff/new" exact>
          <Sidebar component={<AddStaff />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/staff/details/:id" exact>
          <Sidebar component={<StaffDetails />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/marketing" exact>
          <Sidebar component={<Marketing />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/marketing/voucher/add" exact>
          <Sidebar component={<AddVocuher />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/marketing/promo/add" exact>
          <Sidebar component={<AddPromo />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/marketing/discount/add" exact>
          <Sidebar component={<AddDiscountOffer />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/marketing/discount/edit/:id" exact>
          <Sidebar component={<EditDiscountOffer />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/marketing/discountcode/add" exact>
          <Sidebar component={<AddDiscountCode />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/marketing/bogo/add" exact>
          <Sidebar component={<AddBogoOffer />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/qa" exact>
          <Sidebar component={<QuestionAndAnswer />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/messages" exact>
          <Sidebar component={<Messages />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/media-manager" exact>
          <Sidebar component={<MediaManager />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/chatbot" exact>
          <Sidebar component={<ChatBot />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/reports" exact>
          <Sidebar component={<Reports />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/settings" exact>
          <Sidebar component={<Settings />}></Sidebar>
        </PrivateRoute>
        <PrivateRoute path="/profile" exact>
          <Sidebar component={<AdminProfile />}></Sidebar>
        </PrivateRoute>
        {/* Admin Routes */}
        {/* KISOK Routes */}
        <Suspense fallback={<div>Loading...</div>}>
          <PrivateRoute
            path={["/kisok", "/kisok/products", "/kisok/products/:tab"]}
            exact
          >
            <KisokSidebar component={<KProductsSection />} />
          </PrivateRoute>
          <PrivateRoute path="/kisok/product/add" exact>
            <KisokSidebar component={<KAddProduct />} />
          </PrivateRoute>
          <PrivateRoute path="/kisok/product/edit/:id" exact>
            <KisokSidebar component={<KEditProduct />} />
          </PrivateRoute>

          <PrivateRoute path="/kisok/category/add" exact>
            <KisokSidebar component={<KAddCategory />} />
          </PrivateRoute>
          <PrivateRoute path="/kisok/category/edit/:id" exact>
            <KisokSidebar component={<KEditCategory />} />
          </PrivateRoute>

          <PrivateRoute path="/kisok/brand/add" exact>
            <KisokSidebar component={<KAddBrand />} />
          </PrivateRoute>
          <PrivateRoute path="/kisok/brand/edit/:id" exact>
            <KisokSidebar component={<KEditBrand />} />
          </PrivateRoute>

          <PrivateRoute path={["/kisok/shops", "/kisok/shops/:tab"]} exact>
            <KisokSidebar component={<KShopsSection />} />
          </PrivateRoute>
          <PrivateRoute path="/kisok/shop/add" exact>
            <KisokSidebar component={<KAddShop />} />
          </PrivateRoute>
          <PrivateRoute path="/kisok/shop/edit/:id" exact>
            <KisokSidebar component={<KEditShop />} />
          </PrivateRoute>

          <PrivateRoute path="/kisok/branch/add" exact>
            <KisokSidebar component={<KAddBranch />} />
          </PrivateRoute>
          <PrivateRoute path="/kisok/branch/edit/:id" exact>
            <KisokSidebar component={<KEditBranch />} />
          </PrivateRoute>
          <PrivateRoute path="/kisok/branch/:id/product" exact>
            <KisokSidebar component={<KBranchDetails />} />
          </PrivateRoute>

          <PrivateRoute path="/kisok/orders" exact>
            <KisokSidebar component={<KOrders />} />
          </PrivateRoute>
          <PrivateRoute path="/kisok/orders/details/:id?" exact>
            <KisokSidebar component={<KOrderDetails />} />
          </PrivateRoute>

          <PrivateRoute path="/kisok/request-return" exact>
            <KisokSidebar component={<KRequestReturn />} />
          </PrivateRoute>

          <PrivateRoute path="/kisok/reports" exact>
            <KisokSidebar component={<KReports />} />
          </PrivateRoute>

          <PrivateRoute path="/kisok/settings" exact>
            <KisokSidebar component={<KSettings />} />
          </PrivateRoute>
        </Suspense>
        {/* KISOK Routes */}
        {/* Staff Routes */}
        <Route path="/staff/login" exact>
          <StaffLogin />
        </Route>
        <SalesManRoute path="/staff/order" exact>
          <StaffSidebar component={<StaffAllOrder />}></StaffSidebar>
        </SalesManRoute>
        <SalesManRoute path="/staff/order/create" exact>
          <StaffSidebar component={<StaffOrder />}></StaffSidebar>
        </SalesManRoute>
        <SalesManRoute path="/staff/order/Details/:id" exact>
          <StaffSidebar component={<StaffOrderDetails />}></StaffSidebar>
        </SalesManRoute>
        <SalesManRoute path="/staff/profile" exact>
          <StaffSidebar component={<StaffProfle />}></StaffSidebar>
        </SalesManRoute>
        {/* Staff Routes */}
      </Switch>
    </Router>
  );
}

export default App;
