import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  CardContent,
  CardHeader,
  Card,
  Menu,
  MenuItem,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Config from "../../../../config.json";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import { useUserContext } from './../../../../Context/UserContext';
import { useConfirm } from 'material-ui-confirm';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    width: "100%",
    height: "auto",
    maxHeight: 168,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));
export default function Offers() {
  const classes = useStyles();
  const [offers, setoffers] = useState([]);
  const [search, setsearch] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState({});
  useEffect(() => {
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}/offer?search=${search}`)
        .then((response) => {
          if (response.data) setoffers(response.data);
          setisLoading(false);
        })
        .catch((error) => {
          setisLoading(false);
         
        });
    };
    init();
  }, [search]);
  return (
    <>
      {error &&
        Object.keys(error).map((key) => {
          return <Alert severity="error">{error[key]}</Alert>;
        })}
      {success && <Alert>Offer deleted successfully</Alert>}
      <Grid container justify="flex-end">
        <Grid item xs>
          <Box className="d-flex align-items-center justify-content-end my-3">
            <DeleteForeverIcon className="text-main" fontSize="large" />
            <TextField
              name="Search"
              id="Search"
              color="primary"
              variant="outlined"
              size="small"
              onChange={(e) => setsearch(e.target.value)}
            />
            <Link to="/marketing/discount/add">
              <Button color="primary" variant="contained" className="mx-3">
                Add Offer
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {isLoading ? (
          <Typography variant="h6" component="h6" align="center">
            Loading
          </Typography>
        ) : (
            <>
              {offers &&
                offers.map((p, index) => {
                  return (
                    <Grid item xs={3} key={index}>
                      <Card className={classes.root} elevation={4}>
                        <ActionMenu
                          offer={p}
                          setoffers={setoffers}
                          seterror={seterror}
                          setsuccess={setsuccess}
                        />
                        <CardContent className="py-1">
                          <Typography component="p" variant="caption">
                            Name
                        </Typography>
                          <Typography component="p" variant="body2">
                            {p.name}
                          </Typography>
                        </CardContent>
                        <CardContent className="py-1">
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography component="p" variant="caption">
                                Value off
                            </Typography>
                              <Typography component="p" variant="body2">
                                {p?.discount}
                              </Typography>
                            </Grid>

                          </Grid>
                        </CardContent>
                        <img
                          className={classes.media}
                          src={p?.image}
                          alt="ProductImage"
                        />
                      </Card>
                    </Grid>
                  );
                })}
            </>
          )}
      </Grid>
    </>
  );
}
function ActionMenu({ offer, setoffers, setsuccess, seterror }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { getuser } = useUserContext()
  const [user] = useState(getuser());
  const confirm = useConfirm();
  const handleDelete = async () => {
    confirm({ description: `Are you sure you want to delete ${offer?.name}!` })
    .then(async() => { 
      await axios
        .post(`${Config.SERVER_URL}/offer/delete/${offer?.id}`, {
          admin_id: user?.id,
        })
        .then((res) => {
          setsuccess(true);
          setoffers(res.data);
          handleClose()
        })
        .catch((error) => {
          if (error?.response && error?.response.status === 400)
            seterror(error.response.data);
        });
     })
    .catch(() => { /* ... */ });
  };
  return (
    <div>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            className="py-0"
            style={{ fontSize: "16pt" }}
            onClick={handleClick}
          >
            <MoreHorizIcon />
          </IconButton>
        }
        className="px-1 pb-0"
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem><Link to={`/marketing/discount/edit/${offer?.id}`}>Edit</Link></MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </div>
  );
}
