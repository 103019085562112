import React from 'react'
import { Redirect, Route } from 'react-router-dom';
import { useUserContext } from './../../../Context/UserContext';

function PrivateRoute({ component, ...props }) {
    const {isLogined,isAdmin} = useUserContext();
    // eslint-disable-next-line
    return (
        <>
            {
                isLogined() && isAdmin() ? (<Route {...props} render={()=>component}/>) : (<Redirect to="/login" />)
            }
        </>
    )
}

export default PrivateRoute
