import React, { useState } from "react";
import {
  FormControl,
  Paper,
  Typography,
  Grid,
  TextField,
  Container,
  Button
} from "@material-ui/core";
import { Link,useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import axios from 'axios'
import Config from '../../../config.json'
import { Alert } from "@material-ui/lab";
export default function AddDiscountCode() {
  const [data, setdata] = useState({
    discount_name: "",
    discount_code: "",
    discount_percent: 0,
    start_date: "",
    expiry_date: "",
  });
  const handleChange = (e) => {
    data[e.target.name] = e.target.value;
    setdata(data);
  };
  const location = useHistory()
  const [success, setsuccess] = useState(false)
  const [error, seterror] = useState({})
  const handleSubmit = async ()=>{
    await axios.post(`${Config.SERVER_URL}/marketing/discount/create`,data).then(response=>{
     setsuccess(true)
     setTimeout(() => {
       location.push('/marketing')
     }, 3000);
    }).catch(error=>{
        error.response && error.response.status === 400 && seterror(error.response.data);
        setTimeout(() => {
                seterror({});
        }, 3000);
    })
  }
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        <Link to="/marketing">
          <ArrowBackIcon className="text-dark" />
        </Link>
        Discount Code
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/marketing">
          Marketing
        </Link>
        <Link color="inherit" to="/">
          Add Discount Code
        </Link>
      </Breadcrumbs>
      <Paper className="p-4">
          {
            success && <Alert severity="success">Discount Code added You will be redirected in 3 sec.</Alert>
          }
          {
              error && Object.keys(error).map(key=>{
                  return <Alert severity="error" key={key}>{error[key]}</Alert>
              })
          }
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <label>Discount Name</label>
                  <TextField
                    name="discount_name"
                    id="discount_name"
                    onChange={(e) => handleChange(e)}
                    color="primary"
                    variant="outlined"
                    size="small"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <label>Discount Code</label>
                  <TextField
                    name="discount_code"
                    onChange={(e) => handleChange(e)}
                    color="primary"
                    variant="outlined"
                    size="small"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label>Discount Percent(%)</label>
                  <TextField
                    name="discount_percent"
                    id="Amount"
                    onChange={(e) => handleChange(e)}
                    color="primary"
                    variant="outlined"
                    type="number"
                    size="small"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                    defaultValue={data.discount_percent}
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label>Start Date</label>
                  <TextField
                    name="start_date"
                    id="StartDate"
                    type="date"
                    onChange={(e) => handleChange(e)}
                    color="primary"
                    variant="outlined"
                    size="small"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label>End Date</label>
                  <TextField
                    name="expiry_date"
                    id="EndDate"
                    onChange={(e) => handleChange(e)}
                    color="primary"
                    variant="outlined"
                    size="small"
                    type="date"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} item className="d-flex justify-content-end">
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
