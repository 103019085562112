import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/Sort";
import { Link, Redirect } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { TextField, Typography, Paper } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Button, FormControl } from "@material-ui/core";
import axios from "axios";
import Config from "../../../config.json";
import { useUserContext } from "./../../../Context/UserContext";
import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Export from "./../../Orders/component/Export";
export default function StaffAllOrder() {
  const [Filters, setFilters] = useState({
    Customer: "",
    Product: "",
    OrderStatus: "",
    From: "",
    To: "",
    Country: "",
    Zone: "",
    City: "",
    promo_code: "",
    Total: 0,
    Shipping: 0,
  });
  const [isLoading, setisLoading] = useState(true);
  const columns = [
    {
      name: "Order-ID",
      selector: "id",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <Link
            to={`/staff/order/Details/${row?.id}`}
            style={{ fontWeight: "bold" }}
          >
            {row?.id}{" "}
            {row?.is_seen === 0 || row?.is_seen === "0" ? (
              <span className="not-seen"></span>
            ) : (
              ""
            )}
          </Link>
        </div>
      ),
    },
    {
      name: "Customer",
      selector: "customer_id",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          {row?.customer_id !== 0 ? row?.customer?.first_name : row?.first_name}
        </div>
      ),
    },
    {
      name: "Delivery Address",
      selector: "address",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    {
      name: "Dates",
      selector: "created_at",
      sortable: true,
    },
    {
      name: "Discount",
      selector: "discount",
      sortable: true,
    },
    {
      name: "Promo Discount",
      selector: "promo_discount",
      sortable: true,
    },
    {
      name: "VAT",
      selector: "vat",
      sortable: true,
    },
    {
      name: "Shipping",
      selector: "shipping",
      sortable: true,
    },
    {
      name: "Discount Code",
      selector: "discount_code",
      sortable: true,
    },
    {
      name: "Promo Code",
      selector: "promo_code",
      sortable: true,
    },
    {
      name: "Sub Total",
      selector: "total",
      sortable: true,
    },
    {
      name: "Total",
      selector: "sub_total",
      sortable: true,
    },
  ];
  const handleFilterChange = (e) => {
    let TempFilters = Filters;
    TempFilters[e.target.name] = e.target.value;
    setFilters(TempFilters);
  };
  const [rows, setrows] = useState([]);

  const [search, setsearch] = useState("");
  const [pagenumber, setpagenumber] = useState(1);
  const [data, setdata] = useState(null);
  const { getuser, isSalesman, isWriteOnly, isReadOnly } = useUserContext();
  const [user] = useState(getuser());
  useEffect(() => {
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}/order?search=${search}&page=${pagenumber}`)
        .then((response) => {
          setrows(response.data.data);
          setdata(response.data);
          setisLoading(false);
        })
        .catch((error) => {});
    };
    init();
  }, [search, pagenumber, user]);
  const submitFilter = async () => {
    setisLoading(true);
    await axios
      .get(
        `${Config.SERVER_URL}/order?customer=${Filters.Customer}&product=${Filters.Product}&status=${Filters.OrderStatus}&from=${Filters.From}&to=${Filters.To}&discount=${Filters.Discount_code}&promo=${Filters.promo_code}&total=${Filters.Total}&shipping=${Filters.Shipping}`
      )
      .then((response) => {
        setrows(response.data.data);
        setdata(response.data);
        setisLoading(false);
      })
      .catch((error) => {});
  };
  const [EnableFilters, setEnableFilters] = useState(false);
  if (isSalesman() && isWriteOnly()) {
    return <Redirect to="/staff/order/create" />;
  }
  return (
    <Box component={Paper} p={2}>
      <Box marginTop={10}>
        <Typography component="h5" variant="h5">
          Orders
        </Typography>
      </Box>
      <Box>
        <Grid item xs={6} sm={3}>
          <IconButton
            aria-label="delete"
            color="default"
            className="filters"
            onClick={() => setEnableFilters(!EnableFilters)}
          >
            Filters
            <ArrowDropDownIcon />
          </IconButton>
        </Grid>
        <Grid container justify="space-between" spacing={3}>
          <Grid
            item
            xs
            className="d-flex justify-content-end align-items-center"
          >
            <Export />
            <TextField
              variant="outlined"
              size="small"
              onChange={(e) => setsearch(e.target.value)}
            ></TextField>
            {!isReadOnly() && (
              <Link to="/staff/order/create" className="mx-2">
                <Button variant="contained" color="primary" className="h-100">
                  Add Order
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box p={4} className={EnableFilters ? "Filters-Show" : "Filters-Hide"}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Customer</label>
              <TextField
                variant="outlined"
                name="Customer"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Product</label>
              <TextField
                variant="outlined"
                name="Product"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Order Status</label>
              <TextField
                variant="outlined"
                name="OrderStatus"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <label htmlFor="">From</label>
              <TextField
                variant="outlined"
                name="From"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
                type="date"
                fullWidth
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <label htmlFor="">To</label>
              <TextField
                variant="outlined"
                name="To"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
                type="date"
                fullWidth
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Discount</label>
              <TextField
                variant="outlined"
                name="Discount_code"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Promo</label>
              <TextField
                variant="outlined"
                name="promo_code"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Shipping</label>
              <TextField
                variant="outlined"
                name="Shipping"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Total</label>
              <TextField
                variant="outlined"
                name="Total"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>
          <Grid
            item
            container
            justify="flex-end"
            alignItems="center"
            xs
            className="mt-4 "
          >
            <Button variant="outlined" color="primary" onClick={submitFilter}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box component={Paper}>
        <DataTable
          columns={columns}
          data={rows}
          defaultSortField="title"
          SortIcon={<SortIcon />}
          pagination
          selectableRows
          progressPending={isLoading}
          paginationPerPage={10}
          paginationServer={true}
          paginationTotalRows={data?.total}
          onChangePage={(e) => setpagenumber(e)}
        />
      </Box>
    </Box>
  );
}
