import {
    Box,
    Divider,
    FormControl,
    Grid,
    InputAdornment,
    OutlinedInput,
    TextareaAutosize,
    Typography,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/Sort";
import axios from "axios";
import Config from "../../config.json";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {makeStyles} from "@material-ui/core/styles";
import Logo from "../../assets/logo/ADVABlack.svg";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import SendIcon from "@material-ui/icons/Send";
import DeleteIcon from "@material-ui/icons/Delete";
import {message} from "antd";
import {useConfirm} from "material-ui-confirm";

function Gallery() {
    const columns = [
        {
            name: "ID",
            selector: "id",
            sortable: true,
            cell: (row) => (
                <span
                    style={{cursor: "pointer"}}
                    className="text-main"
                    onClick={() => handleShowClickOpen(row.id)}
                >
          ID-{row.id}
        </span>
            ),
        },
        {
            name: "Image",
            selector: "image",
            sortable: true,
            cell: (row) => <img src={row?.image} alt={row?.id} width="100"/>,
        },
        {
            name: "Description",
            selector: "description",
            sortable: true,
        },
        {
            name: "Comments",
            selector: "comments_count",
            sortable: true,
        },
        {
            name: "Likes",
            selector: "likes_count",
            sortable: true,
        },{
            name: "Action",
            sortable: false,
            cell: (row) => <DeleteIcon style={{color:'red',cursor:"pointer"}} onClick={()=>delete_post(row.id)}/>
        },
    ];
    const [open, setOpen] = React.useState(false);
    const [Showopen, setShowOpen] = React.useState(false);
    const [postid, setpostid] = useState(0);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleShowClickOpen = (id) => {
        setpostid(id);
        setShowOpen(true);
    };

    const handleShowClose = () => {
        setShowOpen(false);
    };
    const [refresh,setRefresh]=useState(false)
    const [posts, setposts] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const handleChange = () => {
    };
    const confirm = useConfirm();
    const delete_post = (id) => {
        confirm({ description: 'Are you sure you want to delete this post!' })
            .then(async() => {
                await axios.delete(`${Config.SERVER_URL}/post/${id}`).then(res => {
                   setRefresh(!refresh)
                }).catch(error => {
                    error.response && error.response.status === 400 && Object.entries(error.response.data).map((e)=>message.error(e))
                })
            })
            .catch(() => { /* ... */ });
    };
    useEffect(() => {
        const init = async () => {
            setisLoading(true);
            await axios
                .get(`${Config.SERVER_URL}/posts`)
                .then((response) => {
                    setposts(response.data);
                    setisLoading(false);
                })
                .catch(() => {
                    setisLoading(false);
                });
        };
        init();
    }, [refresh]);
    return (
        <>
            <AddPost
                isopen={open}
                handleClose={() => handleClose()}
                setposts={setposts}
            />
            {Showopen && (
                <ShowPost
                    isopen={Showopen}
                    handleClose={() => handleShowClose()}
                    id={postid}
                />
            )}
            <Box display="flex" justifyContent="flex-end">
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    Add Post +
                </Button>
            </Box>
            <Box>
                <DataTable
                    columns={columns}
                    data={posts}
                    defaultSortField="title"
                    SortIcon={<SortIcon/>}
                    pagination
                    selectableRows
                    progressPending={isLoading}
                    onSelectedRowsChange={handleChange}
                />
            </Box>
        </>
    );
}

function AddPost({isopen, handleClose, setposts}) {
    const [Images, setImages] = useState(null);
    const [ImagesURL, setImagesURL] = useState(null);

    function handleImageChanges(e) {
        let imagesTemp = e.target.files[0];
        let imagesTempURL = URL.createObjectURL(e.target.files[0]);
        setImagesURL(imagesTempURL);
        setImages(imagesTemp);
    }

    const [description, setdescription] = useState("");
    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append("image", Images);
        formData.append("description", description);

        await axios
            .post(`${Config.SERVER_URL}/media/create/post`, formData)
            .then((response) => {
                handleClose();
                setposts(response.data);
                setImagesURL(null);
                setImages(null);
            })
            .catch((error) => {
            });
    };
    return (
        <Dialog
            open={isopen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Create New Post"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormControl fullWidth>
                        <label>
                            Description
                            <TextareaAutosize
                                rowsMin="5"
                                className="w-100"
                                autoFocus
                                onChange={(e) => setdescription(e.target.value)}
                            />
                        </label>
                    </FormControl>
                    <FormControl fullWidth>
                        <label className="imageUpload">
                            Upload Image
                            <input
                                type="file"
                                name="banner"
                                id="banner"
                                onChange={handleImageChanges}
                            />
                        </label>
                    </FormControl>
                    {Images && <img src={ImagesURL} width="500" alt="asdabjsdh"/>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    className="w-100 d-block"
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(() => ({
    modal: {
        minHeight: "600px",
        minWidth: "1000px",
        overflow: "hidden"
    },
}));

function ShowPost({isopen, handleClose, id}) {
    const [post, setpost] = useState(null);
    useEffect(() => {
        const init = async () => {
            setisLoading(true);
            await axios
                .get(`${Config.SERVER_URL}/posts/${id}`)
                .then((res) => {
                    setpost(res.data);
                    setisLoading(false);
                })
                .catch((error) => {
                    setisLoading(false);
                });
        };
        init();
    }, [id]);
    const classes = useStyles();
    const [isLoading, setisLoading] = useState(false);
    const [comment, setcomment] = useState("");
    const postComment = async () => {
        await axios
            .post(`${Config.SERVER_URL}/media/comment/${id}`, {
                customer_id: 0,
                comment: comment,
                post_id: id,
            })
            .then((response) => {
                setpost(response.data);
            })
            .catch((error) => {
            });
    };
    const deleteComment = async (comment_id) => {
        await axios.post(`${Config.SERVER_URL}/media/comment/delete`, {post_id: id, comment_id: comment_id}).then((res) => {
           message.success("Comment deleted successfully")
            setpost(res.data)
        }).catch(err => {
            if (err.response.status === 400) {
                Object.entries(err.response.data).map((e) =>
                    message.error(e)
                )
            } else if (err.response.status === 500) {
                message.error("Internal Server Error")
            }
        })
    }
    return (
        <>
            <Dialog
                open={isopen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                className={classes.modal}
            >
                {isLoading ? (
                    <>
                        <DialogContent className="m-0">
                            <Grid container>
                                <Grid
                                    xs={8}
                                    item
                                    className={classes.modal}
                                    alignItems="center"
                                    container
                                    justify="center"
                                >
                                    <Typography>Loading please wait</Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </>
                ) : (
                    <DialogContent className="m-0 p-0">
                        <Grid container>
                            <Grid
                                xs={8}
                                item
                                container
                                alignItems="center"
                                style={{backgroundColor: "black"}}
                            >
                                <img
                                    src={post?.image}
                                    alt={post?.id}
                                    style={{width: "100%", height: "600px"}}
                                />
                            </Grid>
                            <Grid xs={4}>
                                <Box display="flex">
                                    <Box>
                                        <img
                                            src={
                                                post?.customer?.profile_image
                                                    ? post?.customer?.profile_image
                                                    : Logo
                                            }
                                            alt=""
                                            width="100"
                                        />
                                    </Box>
                                    <Box p={3}>
                                        <Typography>
                                            {post?.customer?.first_name
                                                ? post?.customer?.first_name
                                                : "ADVA"}
                                        </Typography>
                                        <Typography>{post?.created_at}</Typography>
                                    </Box>
                                </Box>
                                <Divider/>
                                <Box p={3} className={"d-flex justify-content-end"}>
                                    <div className={"mx-2"}>
                                        <ThumbUpIcon className="text-main"/> Likes &nbsp;
                                        {post?.likes_count}
                                    </div>
                                    <div className={"mx-2"}>
                                        <ChatBubbleIcon className="text-main"/> Comments &nbsp;
                                        {post?.comments_count}
                                    </div>

                                </Box>
                                <Box style={{height: 380, overflowY: 'auto', overflowX: "hidden"}}>
                                    {post?.comments &&
                                    post?.comments?.map((c, index) => {
                                        return (
                                            <Grid
                                                container
                                                key={index}
                                                spacing={2}
                                                className="py-2"
                                            >
                                                <Grid
                                                    item
                                                    xs={3}
                                                    container
                                                    alignItems="center"
                                                    justify="center"
                                                >
                                                    <img
                                                        src={c?.customer?.profile_image}
                                                        alt={""}
                                                        width="50"
                                                    />
                                                </Grid>
                                                <Grid
                                                    xs={8}
                                                    item
                                                    style={{backgroundColor: "lightgrey"}}
                                                    className="p-2"
                                                >
                                                    <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <p className={"mb-1 border-bottom"} style={{opacity: "0.6"}}>
                                                            {c?.customer?.first_name} {c?.customer?.last_name}
                                                        </p>
                                                        <DeleteIcon
                                                            style={{fontSize: 18, color: "red", cursor: "pointer"}}
                                                            onClick={() => deleteComment(c.id)}/>
                                                    </div>
                                                    <p>{c?.comment}</p>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Box>
                                <Grid container justify="center">
                                    <Grid xs={12} items>
                                        <form action="">
                                            <OutlinedInput
                                                id="input-with-icon-adornment"
                                                fullWidth
                                                className={"w-100"}
                                                onChange={(e) => setcomment(e.target.value)}
                                                endAdornment={
                                                    <InputAdornment position="right">
                                                        <SendIcon onClick={postComment} type="reset"/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </form>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                )}
            </Dialog>
        </>
    );
}

export default Gallery;
