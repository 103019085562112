import React, { useState } from "react";
import { Breadcrumbs, FormControl, Grid, TextField } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import { Paper, Button, ButtonGroup } from "@material-ui/core";
import { Box } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import axios from "axios";
import Config from "../../config.json";
import { useForm } from "react-hook-form";
import { Alert } from "@material-ui/lab";
function AddBrand() {
  const [Images, setImages] = useState(null);
  const [ImagesURL, setImagesURL] = useState(null);
  function handleImageChanges(e) {
    let imagesTemp = e.target.files[0];
    let imagesTempURL = URL.createObjectURL(e.target.files[0]);
    setImages(imagesTemp);
    setImagesURL(imagesTempURL);
  }
  const [dummystate, setdummystate] = useState(false);
  const [BrandName, setBrandName] = useState("");
  const [brand_arabic_name, setbrand_arabic_name] = useState("");
  const { handleSubmit, errors } = useForm();
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState({});
  const location = useHistory();
  const addBrand = async () => {
    const formData = new FormData();
    formData.append("banner", Images);
    formData.append("brand_name", BrandName);
    formData.append("brand_arabic_name", brand_arabic_name);
    await axios
      .post(`${Config.SERVER_URL}/brand/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setsuccess(true);
        setTimeout(() => {
          location.push("/products");
        }, 3000);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400)
          seterror(error.response.data);
      });
  };
  const [isEnglish, setisEnglish] = useState(true);
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/products">
          Brands
        </Link>
        <Link color="inherit" to="/products/new" disabled>
          Insert
        </Link>
      </Breadcrumbs>
      <Paper evaluation={2} className="p-4">
        {error &&
          Object.keys(error).map((key) => {
            return (
              <Alert key={key} severity="error">
                {error[key]}
              </Alert>
            );
          })}
        {success && (
          <Alert severity="success">
            Brand Added You will be redirected in 3 sec
          </Alert>
        )}
        <form onSubmit={handleSubmit(addBrand)} encType="multipart/form-data">
          <Grid container spacing={10}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Box display="flex" justifyContent="space-between">
                  <label htmlFor="">Category Name</label>
                  <ButtonGroup
                    size="small"
                    aria-label="small text primary button group"
                    disableElevation
                  >
                    <Button
                      onClick={() => {
                        setisEnglish(true);
                        setdummystate(!dummystate);
                      }}
                      variant={isEnglish ? "outlined" : "contained"}
                      color={isEnglish ? "primary" : ""}
                    >
                      English
                    </Button>
                    <Button
                      onClick={() => {
                        setisEnglish(false);
                        setdummystate(!dummystate);
                      }}
                      variant={isEnglish ? "contained" : "outlined"}
                      color={isEnglish ? "" : "primary"}
                    >
                      Arabic
                    </Button>
                  </ButtonGroup>
                </Box>
                {isEnglish ? (
                  <TextField
                    variant="outlined"
                    name="BrandName"
                    size="small"
                    placeholder="Type English Here"
                    onChange={(e) => setBrandName(e.target.value)}
                    value={BrandName}
                  />
                ) : (
                  <TextField
                    variant="outlined"
                    name="brand_arabic_name"
                    size="small"
                    placeholder="Type Arabic Here"
                    onChange={(e) => setbrand_arabic_name(e.target.value)}
                    value={brand_arabic_name}
                  />
                )}
                <span className="error text-danger">
                  {errors?.BrandName?.message}
                </span>
                <span className="error text-danger">
                  {errors?.brand_arabic_name?.message}
                </span>
              </FormControl>
              <Box className="d-flex justify-content-between">
                <p>Image</p>
                <label className="imageUpload">
                  Upload Image
                  <input
                    type="file"
                    name="banner"
                    id="banner"
                    onChange={handleImageChanges}
                  />
                </label>
              </Box>
              {Images && (
                <img src={ImagesURL} width="300" height="300" alt="banner" />
              )}
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <Button color="primary" variant="contained" type="submit">
              Save
            </Button>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default AddBrand;
