import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-phone-input-2/lib/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import UserProvider from "./Context/UserContext";
import { ConfirmProvider } from "material-ui-confirm";
ReactDOM.render(
  <ConfirmProvider>
    <UserProvider>
      <App />
    </UserProvider>
  </ConfirmProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
