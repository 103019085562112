import { useUserContext } from './../../../Context/UserContext';
import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios'
import Config from '../../../config.json'
import Alert  from '@material-ui/lab/Alert';
const useStyles = makeStyles(() => ({
  card: {
    minHeight: 650,
  },
  profile: {
    marginTop: "27px",
    padding: "16px",
  },
  list: {
    "& .MuiListItem-root": {
      paddingTop: "19px",
      paddingBottom: "19px",
      cursor: "pointer",
    },
    "& .MuiListItem-root.active": {
      backgroundColor: "#F1BD8E",
    },
  },
  select: {
    padding: "0px 14px !important",
  },


}));
function ChangePassword() {
  const classes = useStyles();
  const [userForm, setuserForm] = useState({})
  const handleChange = (e) => {
    userForm[e.target.name] = e.target.value;
    setuserForm(userForm);
  }
  const {getuser} = useUserContext()
  const [user] = useState(getuser());
  const [success, setsuccess] = useState(false)
  const [error, seterror] = useState(false)
  const handleSubmit = async () => {
    await axios.post(`${Config.SERVER_URL}/password/update`, {
      id: user?.id,
      ...userForm
    }).then(res => {
      setsuccess(true)
    }).catch(error => {
      error.response && error.response.status === 400 && seterror(error.response.data)
    })
  }
  return (
    <Card className={classes.card}>
      <Box flexGrow={1} className="justify-content-between p-2" display="flex">
        <Typography component="h4" variant="h6">
          Customer Orders
        </Typography>
      </Box>
      <Divider />
      <Box p={5}>
        {
          success && <Alert severity="success">Password Updated Successfully</Alert>
        }
        {
          error &&
          Object.keys(error).map(key => {
            return <Alert key={key} severity="error">{error[key]}</Alert>
          })
        }
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <label>Current Password</label>
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="current_password"
              id="CurrentPassword"
              color="primary"
              variant="outlined"
              size="small"
              type="password"
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <label>New Password</label>
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="new_password"
              id="NewPassword"
              color="primary"
              variant="outlined"
              size="small"
              type="password"
              fullWidth onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <label>Confirm Password</label>
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="confirm_password"
              id="ConfirmPassword"
              color="primary"
              variant="outlined"
              size="small"
              type="password" onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container justify="flex-end">
        <Button
          name="Update"
          color="primary"
          variant="contained"
          className="mx-4"
          onClick={handleSubmit}
        >
          Update
        </Button>
      </Grid>
    </Card>
  );
}

export default ChangePassword;
