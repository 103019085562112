import React, { useEffect, useState } from "react";
import { Box, Container } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import {
  Typography,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableHead,
  Card,
  CardContent,
  Grid,
  Breadcrumbs,
  Select,
  MenuItem,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import axios from "axios";
import Config from "../../config.json";
import Alert from "@material-ui/lab/Alert";

function ReturnDetails() {
  const [order, setorder] = useState(null);
  const [error, seterror] = useState(null);
  const [cart, setcart] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/return/orders/get/${id}`)
        .then((response) => {
          setorder(response.data.order);
          setcart(response.data.products);
        })
        .catch((error) => {});
    };
    init();
  }, [id]);
  const HandleStatusChange = async (e) => {
    await axios
      .post(`${Config.SERVER_URL}/return/orders/status/change/${id}`, {
        status: e.target.value,
      })
      .then((res) => {
        setorder(res.data.order);
        setcart(res.data.products);
      })
      .catch(() => {
        seterror(true);
        setTimeout(() => {
          seterror(false);
        }, 3000);
      });
  };
  return (
    <>
      <Container maxWidth="xl" className="px-3 mainContainer">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="mb-3"
        >
          <Link color="inherit" to="/" className="active">
            {" "}
            Home{" "}
          </Link>
          <Link color="inherit" to="/orders">
            {" "}
            OrderDetails
          </Link>
          <Link color="inherit" to="/orders/new" disabled>
            {" "}
            Add Order
          </Link>
        </Breadcrumbs>
        <Card>
          {error &&
            Object.keys(error).map((key) => {
              return <Alert severity="error">{error[key]}</Alert>;
            })}
          <Typography
            component="h6"
            variant="h6"
            className="border-bottom py-3 px-2"
          >
            {!!order && order?.return_status !== "returnRecieved" ? (
              <>
                <Select
                  color="primary"
                  variant="outlined"
                  onChange={HandleStatusChange}
                  defaultValue={order?.return_status === "return" ? 1 : 2}
                >
                  <MenuItem value={1}>In Processed</MenuItem>
                  <MenuItem value={2}>Return Accepted</MenuItem>
                  <MenuItem value={3}>Return Recieved</MenuItem>
                </Select>
              </>
            ) : (
              <Typography>Order Status: Return Recieved</Typography>
            )}
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th">Product Name</TableCell>
                <TableCell component="th">ID</TableCell>
                <TableCell component="th">Quantity</TableCell>
                <TableCell component="th">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cart &&
                cart?.map((prod, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{prod?.products?.product_name}</TableCell>
                      <TableCell>{prod?.id}</TableCell>
                      <TableCell>{prod?.quantity}</TableCell>
                      <TableCell>{prod?.price}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Card>
        <Grid container spacing={4} className="my-3">
          <Grid item xs={6}>
            <Card>
              <Typography
                component="h6"
                variant="h6"
                className="pt-3 px-2 border-bottom"
              >
                Customer Details{" "}
              </Typography>
              <CardContent>
                <Box m={2}>
                  <Typography>
                    Email:
                    <a href={`mailto:${order?.customer?.email}`}>
                      {order?.customer?.email}
                    </a>
                  </Typography>
                </Box>
                <Box m={2}>
                  <Typography>
                    Payment Telephone: {order?.customer?.phone}
                  </Typography>
                </Box>
                <Box m={2}>
                  <Typography>Customer Type: </Typography>
                </Box>
                <Box m={2}>
                  <Typography>
                    Customer Group: {order?.customer?.customer_group}
                  </Typography>
                </Box>
                <Box m={2}>
                  <Typography>Comment: {order?.comments}</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <Typography
                component="h6"
                variant="h6"
                className="pt-3 px-2 border-bottom"
              >
                {" "}
                Shipping Address
              </Typography>
              <CardContent>
                <Box m={2}>
                  <Typography>Address: {order?.address}</Typography>
                </Box>
                <Box m={2}>
                  <Typography>City: {order?.city}</Typography>
                </Box>
                <Box m={2}>
                  <Typography>Postcode: {order?.postal_code}</Typography>
                </Box>
                <Box m={2}>
                  <Typography>Country: {order?.country}</Typography>
                </Box>
                <Box m={2}>
                  <Typography>Shipping Method: </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={4} className="my-3">
          <Grid item xs={6}>
            <Card>
              <Typography
                component="h6"
                variant="h6"
                className="pt-3 px-2 border-bottom"
              >
                Payment Address
              </Typography>
              <CardContent>
                <Box m={2}>
                  <Typography>Address: {order?.address}</Typography>
                </Box>
                <Box m={2}>
                  <Typography>City: {order?.city}</Typography>
                </Box>
                <Box m={2}>
                  <Typography>Postcode: {order?.postal_code}</Typography>
                </Box>
                <Box m={2}>
                  <Typography>Country: {order?.country}</Typography>
                </Box>
                <Box m={2}>
                  <Typography>
                    Payment Method: {order?.payment_method}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ReturnDetails;
