import {
  Container,
  FormControl,
  Paper,
  TextField,
  Box,
  Divider,
  Select,
  MenuItem,
  List,
  Button,
  ListItem,
  IconButton,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Breadcrumbs, Grid } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import dummyImage from "../../../../assets/images/dummyimage.svg";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Config from "../../../../config.json";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 160,
    padding: "0px 14px !important",
  },
  listitem: {
    boxShadow: "0px 3px 6px #0000001A",
    margin: "11px auto",
    border: " 1px solid #D6D6D6",
    padding: "20px 14px",
  },
}));
function AddDiscountOffer() {
  const classes = useStyles();
  const [Offer, setOffer] = useState({
    OfferName: "",
    From: "",
    To: "",
    Discount: 0,
  });
  const [image, setimage] = useState(null);
  const handleChange = (e) => {
    setOffer({ ...Offer, [e.target.name]: e.target.value });
  };
  const [ImagesURL, setImagesURL] = useState(dummyImage);
  function handleImageChanges(e) {
    let imagesTempURL = URL.createObjectURL(e.target.files[0]);
    setimage(e.target.files[0]);
    setImagesURL(imagesTempURL);
  }
  const [OfferOn, setOfferOn] = useState(0);

  const [ProductsInList, setProductsInList] = useState([]);
  const [CategoryInList, setCategoryInList] = useState([]);
  const [dummtstate, setdummtstate] = useState(false);
  const handleSelectChange = (e) => {
    if (e.target.value === 0 || e.target.value === "0") {
      return;
    }
    let value = e.target.value;
    if (OfferOn === 0) {
      let isDuplicate = CategoryInList.filter((s, index) => {
        return s.id === value.id;
      });
      if (isDuplicate.length < 1) {
        CategoryInList.push(value);
        setCategoryInList(CategoryInList);
        setdummtstate(!dummtstate);
      }
    } else {
      let isDuplicate = ProductsInList.filter((s, index) => {
        return s.id === value.id;
      });
      if (isDuplicate.length < 1) {
        ProductsInList.push(value);
        setProductsInList(ProductsInList);
        setdummtstate(!dummtstate);
      }
    }
  };
  const [options, setoptions] = useState(null);
  useEffect(() => {
    const init = async () => {
      if (OfferOn === 0) {
        await axios
          .get(`${Config.SERVER_URL}/category/all`)
          .then((response) => {
            if (response.data) setoptions(response.data);
          })
          .catch((error) => {});
      } else {
        await axios
          .get(`${Config.SERVER_URL}/product/all`)
          .then((response) => {
            if (response.data) setoptions(response.data);
          })
          .catch((error) => {});
      }
    };
    init();
  }, [OfferOn]);
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState({});
  const location = useHistory();
  const HandleSubmit = async () => {
    if (OfferOn) {
      const formData = new FormData();
      formData.append("for", OfferOn);
      formData.append("image", image);
      formData.append("name", Offer.OfferName);
      formData.append("discount", Offer.Discount);
      formData.append("from_date", Offer.From);
      formData.append("to_date", Offer.To);
      formData.append("products", JSON.stringify(ProductsInList));
      await axios
        .post(`${Config.SERVER_URL}/create`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setsuccess(true);
          setTimeout(() => {
            location.push("/marketing");
          }, 3000);
        })
        .catch((error) => {
          error.response &&
            error.response.status === 400 &&
            seterror(error.response.data);
        });
    } else {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("name", Offer.OfferName);
      formData.append("discount", Offer.Discount);
      formData.append("from_date", Offer.From);
      formData.append("to_date", Offer.To);
      formData.append("categorys", JSON.stringify(CategoryInList));
      await axios
        .post(`${Config.SERVER_URL}/create`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setsuccess(true);
          setTimeout(() => {
            location.push("/marketing");
          }, 3000);
        })
        .catch((error) => {
          error.response &&
            error.response.status === 400 &&
            seterror(error.response.data);
        });
    }
  };
  const removeFromProductList = (index) => {
    let rprod = ProductsInList.filter((s, ind) => {
      return index !== ind;
    });
    setProductsInList(rprod);
  };

  const removeFromCategoryList = (index) => {
    let rcat = CategoryInList.filter((s, ind) => {
      return index !== ind;
    });
    setCategoryInList(rcat);
  };

  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        <Link to="/customers">
          <ArrowBackIcon className="text-dark" />
        </Link>
        DISCOUNT OFFER
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/marketing">
          Marketing
        </Link>
        <Link color="inherit" to="/">
          Add Discount Offer
        </Link>
      </Breadcrumbs>
      <Paper className="p-4">
        {success && <Alert severity="success">Offer Added successfully</Alert>}
        {error &&
          Object.keys(error).map((key) => {
            return <Alert severity="error">{error[key]}</Alert>;
          })}
        <Grid container spacing={4} justify="space-between">
          <Grid item xs={5}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <label>Offer Name</label>
                  <TextField
                    name="OfferName"
                    id="OfferName"
                    color="primary"
                    variant="outlined"
                    onChange={handleChange}
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <label>From</label>
                  <TextField
                    name="From"
                    id="From"
                    color="primary"
                    variant="outlined"
                    type="date"
                    size="small"
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <label>To</label>
                  <TextField
                    name="To"
                    id="To"
                    color="primary"
                    variant="outlined"
                    type="date"
                    size="small"
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <label>Discount Amount</label>
                  <TextField
                    name="Discount"
                    id="Discount"
                    color="primary"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Box className="d-flex justify-content-between">
              <p>Image</p>
              <label className="imageUpload">
                Upload Image
                <input
                  type="file"
                  name="banner"
                  id="banner"
                  onChange={handleImageChanges}
                />
              </label>
            </Box>
            {ImagesURL && (
              <div className="d-flex justify-content-center">
                <img src={ImagesURL} alt="banner" width="470" height="300" />
              </div>
            )}
          </Grid>
        </Grid>
        <Divider className="my-3" />
        <Box>
          <FormControl>
            <label>Offer On</label>
            <Select
              color="primary"
              variant="outlined"
              value={OfferOn}
              onChange={(e) => {
                setOfferOn(e.target.value);
                setProductsInList([]);
                setCategoryInList([]);
              }}
              className={classes.select}
            >
              <MenuItem value={0}>Category</MenuItem>
              <MenuItem value={1}>Products</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <Typography component="p" variant="body2">
              Select items for offer
            </Typography>
            <Typography component="p" variant="caption">
              specifically select categories or products for the offer
            </Typography>
            <Select
              color="primary"
              variant="outlined"
              defaultValue={0}
              onChange={handleSelectChange}
            >
              <MenuItem value="0" selected>
                Select value
              </MenuItem>
              {options &&
                options?.map((op) => {
                  return (
                    <MenuItem value={op}>
                      {OfferOn === 1 ? op?.product_name : op?.category_name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
        <Box component={Paper} p={4}>
          <List>
            {OfferOn === 1 ? (
              <ListItem>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    Type
                  </Grid>
                  <Grid item xs={3}>
                    Name
                  </Grid>
                  <Grid item xs={3}>
                    Stock Quantity
                  </Grid>
                </Grid>
              </ListItem>
            ) : (
              <ListItem>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    Type
                  </Grid>
                  <Grid item xs={3}>
                    Name
                  </Grid>
                </Grid>
              </ListItem>
            )}
            {OfferOn === 1
              ? ProductsInList &&
                ProductsInList.map((prod, index) => (
                  <ListItem className={classes.listitem} key={index}>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        Product
                      </Grid>
                      <Grid item xs={3}>
                        {prod.product_name}
                      </Grid>
                      <Grid item xs={3}>
                        {prod.quantity}
                      </Grid>
                      <Grid item xs={3}>
                        <IconButton
                          onClick={() => removeFromProductList(index)}
                          disableFocusRipple
                          disableRipple
                          className="p-0"
                        >
                          {" "}
                          <CloseIcon className="text-danger" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))
              : CategoryInList &&
                CategoryInList.map((cat, index) => (
                  <ListItem className={classes.listitem} key={index}>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        Category
                      </Grid>
                      <Grid item xs={3}>
                        {cat.category_name}
                      </Grid>
                      <Grid item xs={3}>
                        <IconButton
                          onClick={() => removeFromCategoryList(index)}
                          disableFocusRipple
                          disableRipple
                          className="p-0"
                        >
                          {" "}
                          <CloseIcon className="text-danger" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
          </List>
        </Box>
        <Box justifyContent="flex-end" display="flex" m={3}>
          <Button color="primary" variant="contained" onClick={HandleSubmit}>
            Save
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default AddDiscountOffer;
