import React, { useState, useEffect } from "react";
import {
  Card,
  Container,
  Grid,
  TextField,
  FormControl,
  TextareaAutosize,
  Select,
  MenuItem,
  CardContent,
} from "@material-ui/core";
import { Breadcrumbs } from "@material-ui/core";
import { Link, useParams, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import axios from "axios";
import Config from "../../config.json";
import Alert from "@material-ui/lab/Alert";

export default function AddReturn() {
  const [order, setorder] = useState(null);
  // const [isLoading, setisLoading] = useState(false);
  // const [data, setdata] = useState(order);
  // const quantityChange = (value, index) => {
  //   let rProducts = [...data];
  //   if (rProducts[index]["quantity"] >= 1)
  //     rProducts[index]["quantity"] += value;
  //   setdata(rProducts);
  //   setdata(rProducts);
  // };
  const [orderForm] = useState(null);
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState(null);
  const location = useHistory();
  const handleSubmit = async () => {
    await axios
      .post(`${Config.SERVER_URL}/returned/orders`, orderForm)
      .then((response) => {
        setsuccess(true);
        setTimeout(() => {
          location.push("/orders");
        }, 3000);
      })
      .catch((error) => {
        seterror(true);
      });
  };
  const { id } = useParams();
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/admin/orders/${id}`)
        .then((response) => {
          setorder(response.data);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            seterror(error.response.data);
          }
        });
    };
    init();
  }, [id]);
  return (
    <Container maxWidth="xl" className="px-3 mainContainer">
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/orders">
          Returns
        </Link>
        <Link color="inherit" to="/orders/new" disabled>
          Insert
        </Link>
      </Breadcrumbs>
      <Card>
        {success && (
          <Alert severity="success">
            Return Order created You will be redirected in 3 sec
          </Alert>
        )}
        {error &&
          Object.keys(error).map((key) => (
            <Alert severity="error" className="m-1">
              {error[key]}
            </Alert>
          ))}
        <Typography component="h6" variant="h6" className="py-3 px-2">
          Order Details
        </Typography>
        <Grid container spacing={4} className="px-3">
          <Grid item xs={6}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label htmlFor="">Order ID</label>
                  <TextField
                    name="order_id"
                    id="OrderID"
                    variant="outlined"
                    color="primary"
                    size="small"
                    defaultValue={order?.id}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label htmlFor="">Customer</label>
                  <TextField
                    name="customer_id"
                    id="Customer"
                    variant="outlined"
                    color="primary"
                    size="small"
                    defaultValue={order?.customer_id}
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label htmlFor="">First Name</label>
                  <TextField
                    name="first_name"
                    id="FirstName"
                    variant="outlined"
                    color="primary"
                    size="small"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label htmlFor="">Last Name</label>
                  <TextField
                    name="last_name"
                    id="LastName"
                    variant="outlined"
                    color="primary"
                    size="small"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label htmlFor="">Email Address</label>
                  <TextField
                    name="email"
                    id="Email"
                    variant="outlined"
                    color="primary"
                    size="small"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label htmlFor="">Phone No</label>
                  <TextField
                    name="phone"
                    id="Phone"
                    variant="outlined"
                    color="primary"
                    size="small"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>

            <Typography component="p" variant="h6" className="mt-5">
              Return details
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label htmlFor="">Return Reason</label>
                  <Select color="primary" variant="outlined">
                    <MenuItem value="Item Broken" selected>
                      Item Broken
                    </MenuItem>
                    <MenuItem value="Wrong Item">Wrong Item</MenuItem>
                    <MenuItem value="Quality Issue">Quality Issue</MenuItem>
                    <MenuItem value="Order Error">Order Error</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label htmlFor="">Product State</label>
                  <Select color="primary" variant="outlined">
                    <MenuItem value="Opened" selected>
                      Opened
                    </MenuItem>
                    <MenuItem value="Closed">Closed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12}>
                <label htmlFor="">Comment</label>
                <TextareaAutosize
                  className="w-100"
                  name="comment"
                  rowsMin="5"
                ></TextareaAutosize>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label htmlFor="">Return Action</label>
                  <Select color="primary" variant="outlined">
                    <MenuItem value="" selected>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Refund">Refund</MenuItem>
                    <MenuItem value="Replacement">Replacement</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label htmlFor="">Return Status</label>
                  <Select color="primary" variant="outlined">
                    <MenuItem value="Awaiting Products" selected>
                      Awaiting Products
                    </MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Complete">Complete</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Typography component="p">Product</Typography>
            {order &&
              order?.cart?.map((p, index) => {
                return (
                  <>
                    <Card key={index} className="mb-2" elevation={2}>
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={2}>
                            <img src="" alt="" />
                          </Grid>
                          <Grid item xs={5}>
                            <Typography component="h6" variant="h6">
                              {p?.product?.product_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography
                              component="h6"
                              variant="h6"
                              align="right"
                            >
                              SAR. {p?.price}
                            </Typography>
                            <div className="d-flex justify-content-end align-items-center">
                              <Typography component="p" className="px-2">
                                Quantity
                              </Typography>
                              {/* <Button
                              variant="contained"
                              color="primary"
                              startIcon={<RemoveIcon />}
                              className={classes.button}
                              disableRipple={true}
                              onClick={() => {
                                quantityChange(-1, index);
                              }}
                            ></Button>
                            <Typography
                              component="p"
                              variant="h6"
                              className="px-2"
                            > */}
                              {p.quantity}
                              {/* </Typography>
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<AddIcon />}
                              className={classes.button}
                              disableRipple={true}
                              onClick={() => {
                                quantityChange(1, index);
                              }}
                            ></Button> */}
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </>
                );
              })}
          </Grid>
        </Grid>

        <Grid container justify="flex-end">
          <Button
            color="primary"
            variant="contained"
            className="mx-4 my-4"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Grid>
      </Card>
    </Container>
  );
}
