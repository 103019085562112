import React, { useState, useEffect } from "react";
import { Grid, Box, Button, MenuItem, Menu } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/Sort";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import axios from 'axios'
import Config from '../../../config.json';
import { useUserContext } from './../../../Context/UserContext';
import { Alert } from "@material-ui/lab";
import { useConfirm } from 'material-ui-confirm';
function DiscountCode() {
    const columns = [
        {
            name: "Discount Name",
            selector: "discount_name",
            sortable: true,
        },
        {
            name: "Discount Code",
            selector: "discount_code",
            sortable: true,
        },
        {
            name: "Discount Percent",
            selector: "discount_percent",
            sortable: true,
        },
        {
            name: "Date Start",
            selector: "start_date",
            sortable: true,
        },
        {
            name: "Date End",
            selector: "expiry_date",
            sortable: true,
        },
        {
            name: "Action",
            sortable: true,
            width: "150px",
            cell: (row) => (
                <ActionMenu discount={row} setdiscount={setdiscount} setsuccess={setsuccess} seterror={seterror} />
            ),
        },
    ];
    const [success, setsuccess] = useState(false);
    const [error, seterror] = useState(false)
    const [discount, setdiscount] = useState([]);
    const [search, setsearch] = useState('')
    const [isLoading, setisLoading] = useState(false)
    useEffect(() => {
        const init = async () => {
            setisLoading(true)
            await axios.get(`${Config.SERVER_URL}/marketing/discount?search=${search}`).then((response => {
                setdiscount(response.data)
                setisLoading(false)
            })).catch(error => {
               
                setisLoading(false)
            })
        };
        init();
    }, [search]);
    return (
        <>
            <Grid container justify="flex-end">
                <Grid item xs>
                    <Box className="d-flex align-items-center justify-content-end my-3">
                        <DeleteForeverIcon className="text-main" fontSize="large" />
                        <TextField
                            name="Search"
                            id="Search"
                            color="primary"
                            variant="outlined"
                            size="small"
                            onChange={e => setsearch(e.target.value)}
                        />
                        <Link to="/marketing/discountcode/add">
                            <Button color="primary" variant="contained" className="mx-3">Add Discount</Button>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
            {
                error && Object.keys(error).map(key => {
                    return <Alert severity="error">{error[key]}</Alert>
                })
            }
            {
                success && <Alert severity="success">Discount Code Delete</Alert>
            }
            <DataTable
                columns={columns}
                data={discount}
                defaultSortField="discount_name"
                SortIcon={<SortIcon />}
                pagination
                progressPending={isLoading}
            />
        </>
    );
}
function ActionMenu({ discount, setdiscount, setsuccess, seterror }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const { getuser } = useUserContext()
    const [user] = useState(getuser());  
    const confirm = useConfirm();
    const handleDelete = async () => {
        confirm({ description: `Are you sure you want to delete ${discount?.discount_name}` })
        .then(async() => { 
            await axios.post(`${Config.SERVER_URL}/marketing/discount/delete/${discount?.id}`, {
                admin_id: user?.id
            }).then(res => {
                setsuccess(true);
                setdiscount(res.data)
                handleClose();
                setTimeout(() => {
                    setsuccess(false)
                }, 3000);
            }).catch(error => {
                if (error?.response && error?.response.status === 400)
                    seterror(error.response.data)
                setTimeout(() => {
                    seterror({})
                }, 3000);
            })
        })
        .catch(() => {  });
    };
    return (
        <div>
            <IconButton
                aria-label="settings"
                className="py-0"
                style={{ fontSize: "16pt" }}
                onClick={handleClick}
            >
                <MoreHorizIcon />
            </IconButton>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </div>
    );
}
export default DiscountCode;
