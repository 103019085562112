import React, { useState } from "react";
import {
  FormControl,
  Paper,
  Typography,
  Grid,
  TextField,
  Container,
  Button,
  Divider,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import axios from "axios";
import Config from "../../../config.json";
import { Alert } from "@material-ui/lab";
export default function AddPromo() {
  const [data, setdata] = useState({});
  const [dummyState, setdummyState] = useState(false);
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState(false);
  const location = useHistory();
  const handleChange = (e) => {
    data[e.target.name] = e.target.value;
    setdata(data);
  };
  const handleCustomer = (e) => {
    data["customer_id"] = e;

    setdata(data);
    setdummyState(!dummyState);
  };

  const AddPromo = async () => {
    await axios
      .post(`${Config.SERVER_URL}/promo/create`, data)
      .then((response) => {
        setsuccess(true);
        setTimeout(() => {
          location.push("/marketing");
        }, 3000);
      })
      .catch((error) => {
        error && error.response.status === 400 && seterror(error.response.data);
      });
  };
  const [isLoading, setisLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const handleSearch = async (query) => {
    setisLoading(true);
    await axios
      .get(`${Config.SERVER_URL}/customer/search?search=${query}`)
      .then((res) => {
        const options = res.data.map((i) => ({
          id: i.id,
          first_name: i.first_name,
          last_name: i.last_name,
        }));
        setOptions(options);
        setisLoading(false);
      })
      .catch((error) => {});
  };
  const filterBy = () => true;
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        <Link to="/customers">
          <ArrowBackIcon className="text-dark" />
        </Link>
        Promo
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/marketing">
          Marketing
        </Link>
        <Link color="inherit" to="/" disabled>
          Add Promo
        </Link>
      </Breadcrumbs>
      <Paper className="p-4">
        {error &&
          Object.keys(error).map((key) => {
            return (
              <Alert key={key} severity="error">
                {error[key]}
              </Alert>
            );
          })}
        {success && (
          <Alert severity="success">
            promo added successfully you will be redirected in 3 sec
          </Alert>
        )}
        <Grid container>
          <Grid item xs={12} md={5}>
            <AsyncTypeahead
              filterBy={filterBy}
              id="async-example"
              isLoading={isLoading}
              labelKey="first_name"
              minLength={2}
              onSearch={handleSearch}
              options={options}
              placeholder="Type & Search Customer"
              renderMenuItemChildren={(option, props) => (
                <>
                  <p onClick={() => handleCustomer(option?.id)}>
                    {option.first_name}
                    {option.last_name}
                  </p>
                  <Divider />
                </>
              )}
              className="w-100"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} justify="space-between">
          <Grid item xs={5}>
            <FormControl fullWidth>
              <label>Recieve Points </label>
              <TextField
                name="points_for_user"
                id="points_for_user"
                onChange={(e) => handleChange(e)}
                color="primary"
                variant="outlined"
                size="small"
                type="number"
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <label>Award Discount</label>
              <TextField
                name="award_discount"
                id="points_for_customer"
                onChange={(e) => handleChange(e)}
                color="primary"
                variant="outlined"
                size="small"
                type="number"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} justify="space-between">
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <label>Start Date</label>
                  <TextField
                    name="date_start"
                    id="StartDate"
                    onChange={(e) => handleChange(e)}
                    color="primary"
                    variant="outlined"
                    size="small"
                    type="date"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <label>End Date</label>
                  <TextField
                    name="date_end"
                    id="EndDate"
                    onChange={(e) => handleChange(e)}
                    color="primary"
                    variant="outlined"
                    size="small"
                    type="date"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} item className="d-flex justify-content-end">
            <Button color="primary" variant="contained" onClick={AddPromo}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
