import React, { useState, useEffect } from "react";
import {
  Container,
  Breadcrumbs,
  Paper,
  Typography,
  Grid,
  Box,
  FormControl,
  TextField,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import axios from "axios";
import Config from "../../config.json";
import { useForm } from "react-hook-form";
import { Alert } from "@material-ui/lab";
function ChatBot() {
  const [botMessages, setbotMessages] = useState(null);
  const { register, handleSubmit, errors } = useForm();
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState(false);

  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/chatbot`)
        .then((response) => {
          if (response.data) setbotMessages(response.data);
        })
        .catch((error) => {});
    };
    init();
  }, []);
  const [QuestionAnswer, setQuestionAnswer] = useState({
    question: "",
    answer: "",
  });
  const handleChange = (e) => {
    QuestionAnswer[e.target.name] = e.target.value;
    setQuestionAnswer(QuestionAnswer);
  };
  const handleNewQuestion = async () => {
    await axios
      .post(`${Config.SERVER_URL}/chatbot/create`, {
        ...QuestionAnswer,
      })
      .then((response) => {
        if (response.data) setbotMessages(response.data);
        setsuccess(true);
        setTimeout(() => {
          setsuccess(true);
        }, 3000);
      })
      .catch((error) => {
        seterror(true);
        setTimeout(() => {
          seterror(true);
        }, 3000);
      });
  };
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        ChatBot
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/ChatBot" disabled>
          ChatBot
        </Link>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Paper className="px-4 pb-3">
            <Typography component="h4" variant="h4" className="py-2">
              Questions Answers
            </Typography>
            <Divider />
            {botMessages &&
              botMessages?.map((m, index) => {
                return (
                  <QA
                    data={m}
                    key={index}
                    updateparent={(data) => setbotMessages(data)}
                  />
                );
              })}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className="px-4">
            <Typography component="h4" variant="h4" className="py-2">
              New Question
            </Typography>
            <Divider />
            {error && <Alert severity="error">Something Went Wrong</Alert>}
            {success && <Alert severity="success">Operation successfull</Alert>}
            <form onSubmit={handleSubmit(handleNewQuestion)}>
              <Box p={1}>
                <FormControl fullWidth>
                  <label htmlFor="">Question</label>
                  <TextField
                    name="question"
                    id="Question"
                    color="primary"
                    variant="outlined"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    inputRef={register({
                      required: "required",
                    })}
                    size="small"
                  />
                  <span className="error">{errors.question?.message}</span>
                </FormControl>
                <FormControl fullWidth>
                  <label htmlFor="">Answer</label>
                  <TextField
                    name="answer"
                    id="Answer"
                    color="primary"
                    variant="outlined"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    inputRef={register({
                      required: "required",
                    })}
                    size="small"
                  />
                  <span className="error">{errors.answer?.message}</span>
                </FormControl>
                <div className="d-flex justify-content-end my-2">
                  <Button color="primary" variant="contained" type="submit">
                    Save
                  </Button>
                </div>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
function QA({ data, updateparent }) {
  const [enableEditing, setenableEditing] = useState(false);
  const [updatedQuestionAnswer, setupdatedQuestionAnswer] = useState({
    question: data?.question,
    answer: data?.answer,
  });

  const handleChange = (e) => {
    updatedQuestionAnswer[e.target.name] = e.target.value;
    setupdatedQuestionAnswer(updatedQuestionAnswer);
  };
  const handleUpdate = async () => {
    setenableEditing(false);
    await axios
      .post(`${Config.SERVER_URL}/chatbot/update/${data?.id}`, {
        ...updatedQuestionAnswer,
      })
      .then((response) => {
        if (response.data) updateparent(response.data);
      })
      .catch((error) => {});
  };
  const handleDelete = async () => {
    await axios
      .post(`${Config.SERVER_URL}/chatbot/delete/${data?.id}`)
      .then((response) => {
        if (response.data) updateparent(response.data);
      })
      .catch((error) => {});
  };
  return (
    <Paper elevation={1} className="px-4 my-3">
      <div className="d-flex justify-content-end p-2">
        {enableEditing ? (
          <>
            <p onClick={handleUpdate} className="px-2 text-main cursor">
              Save
            </p>
            <p
              onClick={() => {
                setenableEditing(!enableEditing);
              }}
              className="text-main cursor"
            >
              Cancel
            </p>
          </>
        ) : (
          <>
            <EditIcon
              onClick={() => {
                setenableEditing(!enableEditing);
              }}
              className="cursor"
            />
            <DeleteForeverIcon
              style={{ color: "red" }}
              className="cursor"
              onClick={handleDelete}
            />
          </>
        )}
      </div>
      {enableEditing ? (
        <div>
          <Box p={3}>
            <Typography component="h6" variant="h6">
              Question
            </Typography>
            <TextField
              color="primary"
              variant="outlined"
              name="question"
              defaultValue={data.question}
              onChange={(e) => {
                handleChange(e);
              }}
              size="small"
              fullWidth
            ></TextField>
          </Box>
          <Box p={3}>
            <Typography component="h6" variant="h6">
              Answer
            </Typography>
            <TextField
              color="primary"
              variant="outlined"
              name="answer"
              defaultValue={data.answer}
              onChange={(e) => {
                handleChange(e);
              }}
              size="small"
              fullWidth
            ></TextField>
          </Box>
        </div>
      ) : (
        <div>
          <Box p={3}>
            <Typography component="h6" variant="h6">
              Question
            </Typography>
            <Typography component="h6" variant="body2">
              {data?.question}
            </Typography>
          </Box>
          <Box p={3}>
            <Typography component="h6" variant="h6">
              Answer
            </Typography>
            <Typography component="h6" variant="body2">
              {data?.answer}
            </Typography>
          </Box>
        </div>
      )}
    </Paper>
  );
}
export default ChatBot;
