import { Box, CardActions, Divider, Typography } from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";
import { Grid, Card, Button, Menu, MenuItem, Fab } from "@material-ui/core";
import AdsHeroImage from "../../assets/images/AdsHero.png";
import { makeStyles } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import Config from "../../config.json";
const useStyles = makeStyles(() => ({
  hero: {
    width: "100%",
    height: "auto",
  },
  fab: {
    backgroundColor: "#BF6159",
    "& svg": {
      color: "white",
    },
  },
}));
function AdsManager() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const imageFile = useRef(null);
  const UpdateImage = useRef(null);
  const [gallery, setgallery] = useState(null);
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/ads`)
        .then((res) => {
          setgallery(res.data);
        })
        .catch((error) => {});
    };
    init();
  }, []);
  const updateImageSubmit = async (e, id) => {
    const formData = new FormData();
    formData.append("media", e.target.files[0]);
    await axios
      .post(`${Config.SERVER_URL}/ads/update/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setgallery(res.data);
      })
      .catch((error) => {});
  };
  const deleteImage = async (id) => {
    await axios
      .post(`${Config.SERVER_URL}/ads/delete/${id}`)
      .then((res) => {
        setgallery(res.data);
      })
      .catch((error) => {});
  };
  const [Images, setImages] = useState([]);
  const [ImagesURL, setImagesURL] = useState([]);
  function handleImageChanges(e) {
    setImagesURL(URL.createObjectURL(e.target.files[0]));
    setImages(e.target.files[0]);
  }
  const addImages = async () => {
    const formData = new FormData();
    formData.append("media", Images);
    await axios
      .post(`${Config.SERVER_URL}/ads/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setgallery(res.data);
        setImagesURL(null);
      })
      .catch((error) => {});
  };
  return (
    <div>
      <Box maxWidth="lg">
        <img src={AdsHeroImage} alt="Hero" className={classes.hero} />
      </Box>
      <Divider />
      <Box>
        <Typography component="h6" variant="h6">
          Sequence
        </Typography>
        <Grid container spacing={5}>
          {gallery?.map((g, index) => {
            return (
              <Grid item xs={2} md={4} xl={2} className="my-3">
                <Card>
                  <img
                    src={g.media}
                    alt={`manager-${index}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <CardActions className="p-1 d-flex justify-content-end">
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreHorizIcon />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      elevation={0}
                    >
                      <MenuItem
                        onClick={() => {
                          UpdateImage.current.click();
                        }}
                      >
                        Change Image
                        <input
                          type="file"
                          name=""
                          id=""
                          className="d-none"
                          ref={UpdateImage}
                          onChange={(e) => updateImageSubmit(e, g?.id)}
                        />
                      </MenuItem>
                      <MenuItem onClick={() => deleteImage(g?.id)}>
                        Delete
                      </MenuItem>
                    </Menu>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}

          {/* New Images Code */}
          {ImagesURL?.length > 0 && (
            <Grid item xs={4} xl={2} className="my-3">
              <Card>
                <img
                  src={ImagesURL}
                  alt={`new item`}
                  style={{ width: "100%", height: "auto" }}
                />
                <CardActions className="p-1 d-flex justify-content-end">
                  <Button aria-controls="simple-menu" aria-haspopup="true">
                    <MoreHorizIcon />
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {/* Code ends Here */}
          <Grid xs={2} item className="d-flex align-items-center">
            <Fab
              className={classes.fab}
              aria-label="add"
              onClick={() => {
                imageFile.current.click();
              }}
            >
              <input
                type="file"
                name=""
                id=""
                className="d-none"
                ref={imageFile}
                onChange={handleImageChanges}
                multiple
              />
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={addImages}>
            Save
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default AdsManager;
