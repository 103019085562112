import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { TextField, Box, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios'
import Config from '../../../config.json'
import  Alert  from '@material-ui/lab/Alert';
const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 600
  }
}))
function Profile({ user }) {
  const classes = useStyles();
  const [userForm, setuserForm] = useState({
    name: user?.name,
    email: user?.email,
    dob: user?.dob,
    phone: user?.phone,
  });
  const [success, setsuccess] = useState(false)
  const [error, seterror] = useState({})
  const handleChange = e => {
    userForm[e.target.name] = e.target.value;
    setuserForm(userForm);
  }
  const submitForm = async () => {
    await axios.post(`${Config.SERVER_URL}/updateinfo`, {
      id: user?.id,
      name: userForm?.name,
      email: userForm?.email,
      phone: userForm?.phone,
      dob: userForm?.dob,
    }).then(res => {
      setsuccess(true);
    }).catch(error => {
      error.response && error.response.status === 400 && seterror(error.response.data)
    })
  }
  return (
    <Card className={classes.card}>
      <Typography component="h6" variant="h6" className="px-4 py-2">
        Personal Information
      </Typography>
      <Divider />
      {
        !!user &&
      <>    
      <Box p={5}>
        {
          success && <Alert severity="success">Profile Updated Successfully</Alert>
        }
        {
          error &&
          Object.keys(error).map(key => {
            return <Alert key={key} severity="error">{error[key]}</Alert>
          })
        }
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <label>Name</label>
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="name"
              id="FirstName"
              color="primary"
              variant="outlined"
              size="small" onChange={handleChange}
              fullWidth
              defaultValue={user?.name}
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center">
          <Grid item xs={2}>
            <label>Email Address</label>
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="email"
              id="Email"
              color="primary"
              variant="outlined"
              size="small" onChange={handleChange}
              fullWidth
              defaultValue={user?.email}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <label>Phone No.</label>
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="phone"
              id="Phone"
              color="primary"
              variant="outlined"
              size="small" onChange={handleChange}
              type="tel"
              fullWidth
              defaultValue={user?.phone}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <label>Date of Birth.</label>
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="dob"
              id="DOB"
              color="primary"
              variant="outlined"
              size="small" onChange={handleChange}
              type="date"
              fullWidth
              defaultValue={user?.dob}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container justify="flex-end">
        <Button color="primary" variant="contained" className="mx-4" onClick={submitForm}>
          Save
        </Button>
      </Grid>
      </>} 
  </Card>
  );
}

export default Profile;
