import React, { useState, useEffect } from "react";
import { Box, FormControl, Grid, TextField } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  Container,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Config from "../../config.json";
const useStyles = makeStyles(() => ({
  table: {
    "& .MuiTableCell-root": { border: "none" },
  },
}));
function SalesReport() {
  const [Filters, setFilters] = useState({
    NoOfOrder: 0,
    Date: "",
    From: "",
    To: "",
  });
  const [EnableFilters, setEnableFilters] = useState(false);
  const handleFilterChange = (e) => {
    let TempFilters = Filters;
    TempFilters[e.target.name] = e.target.value;
    setFilters(TempFilters);
  };
  const [report, setreport] = useState(null);
  const classes = useStyles();
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/report/sales`)
        .then((res) => {
          setreport(res.data);
        })
        .catch((error) => {});
    };
    init();
  }, []);
  const handleSubmitFilter = async () => {
    await axios
      .get(
        `${Config.SERVER_URL}/report/sales?from=${Filters.From}&to=${Filters.To}`
      )
      .then((res) => {
        setreport(res.data);
      })
      .catch((error) => {});
  };
  return (
    <Container maxWidth="xl">
      <Box>
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={6} sm={3}>
            <IconButton
              aria-label="delete"
              color="default"
              className="filters"
              onClick={() => setEnableFilters(!EnableFilters)}
            >
              Filters
              <ArrowDropDownIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box p={4} className={EnableFilters ? "Filters-Show" : "Filters-Hide"}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">From</label>
              <TextField
                variant="outlined"
                name="From"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                type="date"
                fullWidth
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">To</label>
              <TextField
                variant="outlined"
                name="To"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                type="date"
                fullWidth
              ></TextField>
            </FormControl>
          </Grid>
          <Grid
            xs={6}
            item
            className="justify-content-end d-flex align-items-center"
          >
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={handleSubmitFilter}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell component="th" width="200">
                Total Sale
              </TableCell>
              <TableCell>{report?.total_sale}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" width="200">
                Product Cost
              </TableCell>
              <TableCell>{report?.product_cost}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" width="200">
                Net Profit
              </TableCell>
              <TableCell>{report?.total_sale - report?.product_cost}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" width="200">
                E-payment Fees :
              </TableCell>
              <TableCell>0</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Container>
  );
}

export default SalesReport;
