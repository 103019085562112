import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  FormControl,
  Grid,
  TextField,
  ButtonGroup,
} from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import { Container } from "@material-ui/core";
import { Paper, Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import axios from "axios";
import Config from "../../config.json";
import { useForm } from "react-hook-form";
import { Alert } from "@material-ui/lab";
export default function EditBrand() {
  const { id } = useParams();
  const [Images, setImages] = useState(null);
  const handleImageChanges = async (e) => {
    const formData = new FormData();
    formData.append("banner", e.target.files[0]);
    await axios
      .post(`${Config.SERVER_URL}/brands/update/image/${id}`, formData)
      .then((res) => {
        setImages(res.data);
      })
      .catch((error) => {
        error.response &&
          error.response.status === 400 &&
          seterror(error.response.data);
      });
  };
  const [BrandName, setBrandName] = useState("");
  const [brand_arabic_name, setbrand_arabic_name] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState({});
  const location = useHistory();
  const addBrand = async () => {
    const formData = new FormData();
    formData.append("brand_name", BrandName);
    formData.append("brand_arabic_name", brand_arabic_name);
    await axios
      .post(`${Config.SERVER_URL}/brands/update/${id}`, formData)
      .then((response) => {
        setsuccess(true);
        setTimeout(() => {
          location.push("/products");
        }, 3000);
      })
      .catch((error) => {
        error.response &&
          error.response.status === 400 &&
          seterror(error.response.data);
      });
  };
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/brands/${id}`)
        .then((res) => {
          setBrandName(res.data?.brand_name);
          setbrand_arabic_name(res.data?.brand_arabic_name);
          setImages(res.data?.image);
        })
        .catch((error) => {});
    };
    init();
  }, [id]);
  const [isEnglish, setisEnglish] = useState(true);
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/products">
          Brands
        </Link>
        <Link color="inherit" to="/products/new" disabled>
          Insert
        </Link>
      </Breadcrumbs>
      <Paper evaluation={2} className="p-4">
        {error &&
          Object.keys(error).map((key) => {
            return (
              <Alert key={key} severity="error">
                {error[key]}
              </Alert>
            );
          })}
        {success && (
          <Alert severity="success">
            Brand Updated. You will be redirected in 3 sec
          </Alert>
        )}
        <form onSubmit={handleSubmit(addBrand)} encType="multipart/form-data">
          <Grid container spacing={10}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Box display="flex" justifyContent="space-between">
                  <label htmlFor="">Brand Name</label>
                  <ButtonGroup
                    size="small"
                    aria-label="small text primary button group"
                    disableElevation
                  >
                    <Button
                      onClick={() => {
                        setisEnglish(true);
                      }}
                      variant={isEnglish ? "outlined" : "contained"}
                      color={isEnglish ? "primary" : ""}
                    >
                      English
                    </Button>
                    <Button
                      onClick={() => {
                        setisEnglish(false);
                      }}
                      variant={isEnglish ? "contained" : "outlined"}
                      color={isEnglish ? "" : "primary"}
                    >
                      Arabic
                    </Button>
                  </ButtonGroup>
                </Box>
                {isEnglish ? (
                  <TextField
                    variant="outlined"
                    name="BrandName"
                    size="small"
                    placeholder="Type English Here"
                    inputRef={register({
                      required: "required",
                    })}
                    onChange={(e) => setBrandName(e.target.value)}
                    value={BrandName}
                  />
                ) : (
                  <TextField
                    variant="outlined"
                    name="brand_arabic_name"
                    size="small"
                    placeholder="Type Arabic Here"
                    inputRef={register({
                      required: "required",
                    })}
                    onChange={(e) => setbrand_arabic_name(e.target.value)}
                    value={brand_arabic_name}
                  />
                )}
                <span className="error text-danger">
                  {errors?.BrandName?.message}
                </span>
                <span className="error text-danger">
                  {errors?.brand_arabic_name?.message}
                </span>
              </FormControl>
              <Box className="d-flex justify-content-between">
                <p>Image</p>
                <label className="imageUpload">
                  Upload Image
                  <input
                    type="file"
                    name="banner"
                    id="banner"
                    onChange={handleImageChanges}
                  />
                </label>
              </Box>
              <img src={Images} alt="" width="400" />
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <Button color="primary" variant="contained" type="submit">
              Save
            </Button>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}
