import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "../assets/icons/home.svg";
import OrdersIcon from "../assets/icons/orders.svg";
import ProductIcon from "../assets/icons/products.svg";
import CustomerIcon from "../assets/icons/customers.svg";
import StaffIcon from "../assets/icons/staff.svg";
import MarketingIcon from "../assets/icons/marketing.svg";
import ChatBotIcon from "../assets/icons/chatbot.svg";
import MailIcon from "../assets/icons/mail.svg";
import MediaManagerIcon from "../assets/icons/media-manager.svg";
import ReportsIcon from "../assets/icons/analytics.svg";
import { Link } from "react-router-dom";
export const mainListItems = (
  <div>
    <Link to="/">
      <ListItem button>
        <ListItemIcon>
          <img src={HomeIcon} alt="H" />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
    </Link>

    <Link to="/orders">
      <ListItem button>
        <ListItemIcon>
          <img src={OrdersIcon} alt="O" />
        </ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItem>
    </Link>

    <Link to="/products">
      <ListItem button>
        <ListItemIcon>
          <img src={ProductIcon} alt="P" />
        </ListItemIcon>
        <ListItemText primary="Products" />
      </ListItem>
    </Link>

    <Link to="/customers">
      <ListItem button>
        <ListItemIcon>
          <img src={CustomerIcon} alt="C" />
        </ListItemIcon>
        <ListItemText primary="Customers" />
      </ListItem>
    </Link>

    <Link to="/staff">
      <ListItem button>
        <ListItemIcon>
          <img src={StaffIcon} alt="S" />
        </ListItemIcon>
        <ListItemText primary="Staff" />
      </ListItem>
    </Link>

    <Link to="/marketing">
      <ListItem button>
        <ListItemIcon>
          <img src={MarketingIcon} alt="M" />
        </ListItemIcon>
        <ListItemText primary="Marketing" />
      </ListItem>
    </Link>

    <Link to="/messages">
      <ListItem button>
        <ListItemIcon>
          <img src={MailIcon} alt="M" />
        </ListItemIcon>
        <ListItemText primary="Messages" />
      </ListItem>
    </Link>
    <Link to="/qa">
      <ListItem button>
        <ListItemIcon>
          <img src={MailIcon} alt="M" />
        </ListItemIcon>
        <ListItemText primary="Question & Ans" />
      </ListItem>
    </Link>

    <Link to="/media-manager">
      <ListItem button>
        <ListItemIcon>
          <img src={MediaManagerIcon} alt="M" />
        </ListItemIcon>
        <ListItemText primary="Media Manager" />
      </ListItem>
    </Link>

    <Link to="/chatbot">
      <ListItem button>
        <ListItemIcon>
          <img src={ChatBotIcon} alt="C" />
        </ListItemIcon>
        <ListItemText primary="Chat Bot" />
      </ListItem>
    </Link>

    <Link to="/reports">
      <ListItem button>
        <ListItemIcon>
          <img src={ReportsIcon} alt="R" />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItem>
    </Link>
    <Link to="/settings">
      <ListItem button>
        <ListItemIcon>
          <img src={ReportsIcon} alt="R" />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>
    </Link>

  </div>
);
