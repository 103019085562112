import React from "react";
import { Tabs, Box } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Privacy from "./Privacy";
import Terms from "./Terms";
import About from "./About";
function Legal() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Paper evaluation={1} className="px-2">
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
          <Tab label="Privacy Policy" {...a11yProps(0)} />
          <Tab label="Terms & Conditions" {...a11yProps(1)} />
          <Tab label="About us" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Privacy />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Terms />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <About />
      </TabPanel>
    </Paper>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

export default Legal;
