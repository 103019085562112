import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import OrdersIcon from "../assets/icons/orders.svg";
import { Link } from "react-router-dom";
export const StaffListItem = (
  <div>
    <Link to="/staff/order">
      <ListItem button>
        <ListItemIcon>
          <img src={OrdersIcon} alt="O" />
        </ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItem>
    </Link>
  </div>
);
