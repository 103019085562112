import React, { useState, useEffect } from "react";
import { FormControl, Select, TextField } from "@material-ui/core";
import { Grid, MenuItem } from "@material-ui/core";
import axios from "axios";
import Config from "../../../config.json";
export default function Customer({ onChangeHandler, data }) {
  const [customers, setcustomers] = useState(null);
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/customer/all`)
        .then((response) => {
          setcustomers(response.data);
        })
        .catch((error) => {});
    };
    init();
  }, []);
  const [visible, setvisible] = useState(true);
  const isVisible = (e) => {
    if (Number(e.target.value) !== 0) {
      setvisible(false);
    } else {
      setvisible(true);
    }
  };
  return (
    <Grid container spacing={4} className="px-4">
      <Grid item xs={6}>
        <FormControl fullWidth>
          <label htmlFor="">Customer</label>
          <Select
            color="primary"
            variant="outlined"
            name="customer_id"
            defaultValue={data.customer_id}
            helperText="You can select customer from this field or leave empty and enter data as a guest order."
            onChange={(e) => {
              onChangeHandler(e);
              isVisible(e);
            }}
          >
            <MenuItem value={0}>Select Customer</MenuItem>
            {customers &&
              customers?.map((cust, index) => {
                return (
                  <MenuItem value={cust?.id} key={index}>
                    {cust?.first_name} {cust?.last_name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ display: visible ? "" : "none" }}>
          <label htmlFor="">First Name</label>
          <TextField
            name="first_name"
            id="FirstName"
            defaultValue={data?.first_name}
            variant="outlined"
            color="primary"
            size="small"
            onChange={(e) => {
              onChangeHandler(e);
            }}
          ></TextField>
        </FormControl>
        <FormControl fullWidth style={{ display: visible ? "" : "none" }}>
          <label htmlFor="">LastName</label>
          <TextField
            name="last_name"
            id="LastName"
            defaultValue={data?.last_name}
            variant="outlined"
            color="primary"
            size="small"
            onChange={(e) => {
              onChangeHandler(e);
            }}
          ></TextField>
        </FormControl>
        <FormControl fullWidth style={{ display: visible ? "" : "none" }}>
          <label htmlFor="">Email Address</label>
          <TextField
            name="email"
            id="Email"
            defaultValue={data?.email}
            variant="outlined"
            color="primary"
            size="small"
            type="email"
            onChange={(e) => {
              onChangeHandler(e);
            }}
          ></TextField>
        </FormControl>
        <FormControl fullWidth style={{ display: visible ? "" : "none" }}>
          <label htmlFor="">Phone No</label>
          <TextField
            name="phone"
            id="Phone"
            defaultValue={data?.phone}
            variant="outlined"
            color="primary"
            size="small"
            onChange={(e) => {
              onChangeHandler(e);
            }}
          ></TextField>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <label htmlFor="">Order Recieved From</label>
          <Select
            color="primary"
            variant="outlined"
            name="received_from"
            defaultValue={data.received_from}
            onChange={(e) => onChangeHandler(e)}
          >
            <MenuItem value={2}>Instagram</MenuItem>
            <MenuItem value={3}>Facebook</MenuItem>
            <MenuItem value={4}>Whatsapp</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
