import React, { useState } from "react";
import {
  Breadcrumbs,
  FormControl,
  Grid,
  TextareaAutosize,
  TextField,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import { Paper, Switch } from "@material-ui/core";
import { Box } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import axios from "axios";
import Config from "../../config.json";
import { useForm } from "react-hook-form";
import Alert from "@material-ui/lab/Alert";
function AddCategory() {
  const [Images, setImages] = useState(null);
  const [ImagesURL, setImagesURL] = useState(null);
  const { register, handleSubmit } = useForm();
  const [CategoryForm, setCategoryForm] = useState({
    category_name: "",
    category_arabic_name: "",
    description: "",
    arabic_description: "",

    status: true,
    banner: null,
  });
  function handleImageChanges(e) {
    let imagesTemp = e.target.files[0];
    let imagesTempURL = URL.createObjectURL(e.target.files[0]);
    setImages(imagesTemp);
    CategoryForm["banner"] = e.target.files[0];
    setCategoryForm(CategoryForm);
    setImagesURL(imagesTempURL);
  }
  const handleChanges = (e) => {
    CategoryForm[e.target.name] = e.target.value;
    setCategoryForm(CategoryForm);
    setdummtstate(!dummtstate);
  };
  const location = useHistory();
  const formData = new FormData();
  const [dummtstate, setdummtstate] = useState(false);
  const [success, setsuccess] = useState(false);
  const [errors, seterrors] = useState({});
  const addCategory = async () => {
    formData.append("banner", Images);
    formData.append("category_name", CategoryForm.category_name);
    formData.append("category_arabic_name", CategoryForm.category_arabic_name);
    formData.append("status", CategoryForm.status);
    formData.append("description", CategoryForm.description);
    formData.append("arabic_description", CategoryForm.arabic_description);
    await axios
      .post(`${Config.SERVER_URL}/category/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setsuccess(true);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          console.log("hi", error.response.data);
          seterrors(error.response.data);
        }
      });
  };
  if (success) {
    setTimeout(() => {
      location.push("/products");
    }, 2000);
  }
  const [isEnglish, setisEnglish] = useState(true);
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/products">
          Category
        </Link>
        <Link color="inherit" to="/products/new" disabled>
          Insert
        </Link>
      </Breadcrumbs>
      <Paper evaluation={2} className="p-4">
        {success && (
          <Alert severity="success">
            Category Added successfully you will be redirected in 3 sec
          </Alert>
        )}
        <form
          onSubmit={handleSubmit(addCategory)}
          encType="multipart/form-data"
        >
          <Grid container spacing={10}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Box display="flex" justifyContent="space-between">
                  <label htmlFor="">Category Name</label>
                  <ButtonGroup
                    size="small"
                    aria-label="small text primary button group"
                    disableElevation
                  >
                    <Button
                      onClick={() => {
                        setisEnglish(true);
                        setdummtstate(!dummtstate);
                      }}
                      variant={isEnglish ? "outlined" : "contained"}
                      color={isEnglish ? "primary" : ""}
                    >
                      English
                    </Button>
                    <Button
                      onClick={() => {
                        setisEnglish(false);
                        setdummtstate(!dummtstate);
                      }}
                      variant={isEnglish ? "contained" : "outlined"}
                      color={isEnglish ? "" : "primary"}
                    >
                      Arabic
                    </Button>
                  </ButtonGroup>
                </Box>
                <TextField
                  variant="outlined"
                  name="category_name"
                  size="small"
                  placeholder="Type English Name Here"
                  onChange={(e) => handleChanges(e)}
                  value={CategoryForm.category_name}
                  className={isEnglish ? "" : "d-none"}
                ></TextField>
                <TextField
                  variant="outlined"
                  name="category_arabic_name"
                  size="small"
                  placeholder="Type Arabic Name Here"
                  onChange={(e) => handleChanges(e)}
                  value={CategoryForm.category_arabic_name}
                  className={isEnglish ? "d-none" : ""}
                ></TextField>
                <span className="error text-danger">
                  {errors?.category_name}
                </span>
                <span className="error text-danger">
                  {errors?.category_arabic_name}
                </span>
              </FormControl>
              <FormControl fullWidth>
                <label htmlFor="">Description</label>
                <TextareaAutosize
                  variant="outlined"
                  name="description"
                  size="small"
                  rowsMin="10"
                  onChange={(e) => handleChanges(e)}
                  placeholder="English Description"
                  className={`w-100 ${isEnglish ? "" : "d-none"}`}
                ></TextareaAutosize>
                <TextareaAutosize
                  variant="outlined"
                  name="arabic_description"
                  size="small"
                  rowsMin="10"
                  onChange={(e) => handleChanges(e)}
                  placeholder="Arabic Description"
                  className={`w-100 ${isEnglish ? "d-none" : ""}`}
                ></TextareaAutosize>
                <span className="error text-danger">{errors?.description}</span>
                <span className="error text-danger">
                  {errors?.arabic_description}
                </span>
              </FormControl>
              <FormControl fullWidth>
                <label htmlFor="">Status</label>
                <Switch
                  checked={CategoryForm.status}
                  color="primary"
                  name="status"
                  onChange={(e) => {
                    CategoryForm["status"] = e.target.checked;
                    setCategoryForm(CategoryForm);
                    setdummtstate(!dummtstate);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Box className="d-flex justify-content-between">
                <p>Image</p>
                <label className="imageUpload">
                  Upload Image
                  <input
                    type="file"
                    name="banner"
                    id="banner"
                    onChange={handleImageChanges}
                    inputref={register({
                      required: "required",
                    })}
                  />
                </label>
                <span className="error">{errors.banner}</span>
              </Box>
              {Images && (
                <img src={ImagesURL} width="300" height="300" alt="banner" />
              )}
            </Grid>
            <Grid xs={2} container item justify="flex-end">
              <Button type="submit" variant="contained" color="primary">
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default AddCategory;
