import React, { useState, useEffect } from "react";
import {
  CardContent,
  Container,
  Divider,
  ListItem,
  List,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Breadcrumbs, Grid, Card, Box } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PrintIcon from "@material-ui/icons/Print";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/Sort";
import UserCircle from "../../assets/images/user-circle.svg";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Profile from "./Profile";
import axios from "axios";
import Config from "../../config.json";
const useStyles = makeStyles(() => ({
  card: {
    minHeight: 650,
  },
  profile: {
    marginTop: "27px",
    padding: "16px",
  },
  list: {
    "& .MuiListItem-root": {
      paddingTop: "19px",
      paddingBottom: "19px",
      cursor: "pointer",
    },
    "& .MuiListItem-root.active": {
      backgroundColor: "#F1BD8E",
    },
  },
}));
const getStepComponent = (step, data) => {
  switch (step) {
    case 0:
      return <Profile customer={data} />;
    case 1:
      return <CustomerOrders />;
    case 2:
      return <ActivityLog />;
    default:
      break;
  }
};
function CustomerDetails() {
  const classes = useStyles();
  const [steps, setsteps] = useState(0);
  const [customer, setcustomer] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/customer/get/${id}`)
        .then((response) => {
          if (response.data) setcustomer(response.data);
        })
        .catch((error) => {});
    };
    init();
  }, [id]);
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        <Link to="/customers">
          <ArrowBackIcon className="text-dark" />
        </Link>
        Customer Profile
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/customers">
          Customer
        </Link>
        <Link color="inherit" to="/" disabled>
          Profile
        </Link>
      </Breadcrumbs>
      <Box className="p-4">
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Card className={classes.card}>
              <CardContent className="p-0">
                <Box
                  flexGrow={1}
                  alignContent="center"
                  className={classes.profile}
                >
                  <div className="d-flex justify-content-center py-2">
                    <img src={UserCircle} alt="profile" className="" />
                  </div>
                  <Typography component="h6" variant="h6" align="center">
                    {customer?.first_name} {customer?.last_name}
                  </Typography>
                  <Typography component="p" variant="body2" align="center">
                    Points: {customer?.points}
                  </Typography>
                </Box>
                <Divider />
                <List className={classes.list}>
                  <ListItem
                    className={steps === 0 ? "active" : ""}
                    onClick={() => {
                      setsteps(0);
                    }}
                  >
                    Customer Information
                  </ListItem>
                  <ListItem
                    className={steps === 1 ? "active" : ""}
                    onClick={() => {
                      setsteps(1);
                    }}
                  >
                    Customer Orders
                  </ListItem>
                  <ListItem
                    className={steps === 2 ? "active" : ""}
                    onClick={() => {
                      setsteps(2);
                    }}
                  >
                    Activity Log
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={9}>
            {getStepComponent(steps, customer)}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

function CustomerOrders() {
  const classes = useStyles();
  const columns = [
    {
      name: "Order-ID",
      selector: "id",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <Link to={`/orders/details/${row.id}`} style={{ fontWeight: "bold" }}>
            {row.id}
          </Link>
        </div>
      ),
    },
    {
      name: "Delivery Address",
      selector: "address",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    {
      name: "Dates",
      selector: "created_at",
      sortable: true,
    },
    {
      name: "Total",
      selector: "total",
      sortable: true,
    },
    {
      name: "Sub Total",
      selector: "sub_total",
      sortable: true,
    },
  ];
  const [orders, setorders] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [data, setdata] = useState(null);
  const [pagenumber, setpagenumber] = useState(1);
  const { id } = useParams();
  useEffect(() => {
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}/customer/orders/${id}?page=${pagenumber}`)
        .then((response) => {
          if (response.data) {
            setorders(response.data.data);
            setdata(response.data);
            setisLoading(false);
          }
        })
        .catch((error) => {
          setisLoading(false);
        });
    };
    init();
  }, [id, pagenumber]);
  function handleChange(e) {}
  return (
    <Card className={classes.card}>
      <Box flexGrow={1} className="justify-content-between p-2" display="flex">
        <Typography component="h4" variant="h6">
          Customer Orders
        </Typography>
        <Box>
          <PrintIcon className="text-main" fontSize="large" />
          <DeleteForeverIcon className="text-main" fontSize="large" />
        </Box>
      </Box>
      <Divider />
      <DataTable
        columns={columns}
        data={orders}
        defaultSortField="title"
        SortIcon={<SortIcon />}
        pagination
        progressPending={isLoading}
        selectableRows
        onSelectedRowsChange={handleChange}
        paginationPerPage={10}
        paginationServer={true}
        paginationTotalRows={data?.total}
        onChangePage={(e) => setpagenumber(e)}
      />
    </Card>
  );
}
function ActivityLog() {
  const classes = useStyles();
  const columns = [
    {
      name: "Descriptions",
      selector: "activity",
      sortable: true,
    },
    {
      name: "Date",
      selector: "created_at",
      sortable: true,
    },
  ];
  const [activity, setactivity] = useState([]);
  const { id } = useParams();
  const [isLoading, setisLoading] = useState(false);
  const [data, setdata] = useState(null);
  const [pagenumber, setpagenumber] = useState(1);
  useEffect(() => {
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}/customer/activity/${id}?page=${pagenumber}`)
        .then((response) => {
          if (response.data) setactivity(response.data.data);
          setdata(response.data);
          setisLoading(false);
        })
        .catch((error) => {
          setisLoading(false);
        });
    };
    init();
  }, [id, pagenumber]);
  return (
    <Card className={classes.card}>
      <Box flexGrow={1} className="justify-content-between p-2" display="flex">
        <Typography component="h4" variant="h6">
          Activity Log
        </Typography>
      </Box>
      <Divider />
      <DataTable
        columns={columns}
        data={activity}
        defaultSortField="title"
        SortIcon={<SortIcon />}
        progressPending={isLoading}
        pagination
        paginationPerPage={10}
        paginationServer={true}
        paginationTotalRows={data?.total}
        onChangePage={(e) => setpagenumber(e)}
      />
    </Card>
  );
}
export default CustomerDetails;
