import {
  Container,
  FormControl,
  Paper,
  TextField,
  Box,
  Divider,
  List,
  ListItem,
  MenuItem,
  Select,
  Button,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Breadcrumbs, Grid } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import dummyImage from "../../../../assets/images/dummyimage.svg";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Config from "../../../../config.json";
import { Alert } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  listitem: {
    boxShadow: "0px 3px 6px #0000001A",
    margin: "11px auto",
    border: " 1px solid #D6D6D6",
  },
}));
function AddBogoOffer() {
  const classes = useStyles();
  const [Offer, setOffer] = useState({
    BogoOffer: "",
    From: "",
    To: "",
  });
  const [image, setimage] = useState(null);
  const handleChange = (e) => {
    setOffer({ ...Offer, [e.target.name]: e.target.value });
  };
  const [ImagesURL, setImagesURL] = useState(dummyImage);
  function handleImageChanges(e) {
    let imagesTemp = e.target.files[0];
    let imagesTempURL = URL.createObjectURL(e.target.files[0]);
    setimage(imagesTemp);
    setImagesURL(imagesTempURL);
  }
  const [products, setproducts] = useState([]);
  const [producttobuy, setproducttobuy] = useState([]);
  const [rewardproduct, setrewardproduct] = useState([]);
  const [dummystate, setdummystate] = useState(false);
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/product/all`)
        .then((response) => {
          setproducts(response.data);
        })
        .catch((error) => {});
    };
    init();
  }, []);
  const handleToBuy = (e) => {
    if (e.target.value === 0 || e.target.value === "0") {
      return;
    }
    let value = e.target.value;
    let isDuplicate = producttobuy.filter((s, index) => {
      return s.id === value.id;
    });
    if (isDuplicate.length < 1) {
      producttobuy.push(value);
      setproducttobuy(producttobuy);
      setdummystate(!dummystate);
    }
  };
  const removeToBuy = (e) => {
    let isDuplicate = producttobuy.filter((s, index) => {
      return s.id !== e.id;
    });
    setproducttobuy(isDuplicate);
  };
  const handletoreward = (e) => {
    if (e.target.value === 0 || e.target.value === "0") {
      return;
    }
    let value = e.target.value;
    let isDuplicate = rewardproduct.filter((s, index) => {
      return s.id === value.id;
    });
    if (isDuplicate.length < 1) {
      rewardproduct.push(value);
      setrewardproduct(rewardproduct);
      setdummystate(!dummystate);
    }
  };
  const removetoreward = (e) => {
    let isDuplicate = rewardproduct.filter((s, index) => {
      return s.id !== e.id;
    });
    setrewardproduct(isDuplicate);
  };
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState({});
  const location = useHistory();
  const AddOffer = async () => {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("offer", Offer.BogoOffer);
    formData.append("discount", Offer.Discount);
    formData.append("from", Offer.From);
    formData.append("to", Offer.To);
    formData.append("productstobuy", JSON.stringify(producttobuy));
    formData.append("rewardproducts", JSON.stringify(rewardproduct));
    await axios
      .post(`${Config.SERVER_URL}/bogo/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setsuccess(true);
        setTimeout(() => {
          location.push("/marketing");
        }, 3000);
      })
      .catch((error) => {
        error.response &&
          error.response.status === 400 &&
          seterror(error.response.data);
      });
  };
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        <Link to="/customers">
          <ArrowBackIcon className="text-dark" />
        </Link>
        BOGO Offer
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/marketing">
          Marketing
        </Link>
        <Link color="inherit" to="/" disabled>
          Add Bogo Offer
        </Link>
      </Breadcrumbs>
      <Paper className="p-4">
        {success && (
          <Alert severity="success">
            Offer Created You will be redirected in 3 sec
          </Alert>
        )}
        {error &&
          Object.keys(error).map((key) => {
            return (
              <Alert key={key} severity="error">
                {error[key]}
              </Alert>
            );
          })}
        <Grid container spacing={4} justify="space-between">
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs>
                <FormControl fullWidth>
                  <label>Bogo Offer</label>
                  <TextField
                    name="BogoOffer"
                    id="BogoOffer"
                    color="primary"
                    variant="outlined"
                    onChange={handleChange}
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <label>From</label>
                  <TextField
                    name="From"
                    id="From"
                    color="primary"
                    variant="outlined"
                    type="date"
                    size="small"
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <label>To</label>
                  <TextField
                    name="To"
                    id="To"
                    color="primary"
                    variant="outlined"
                    type="date"
                    size="small"
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Box className="d-flex justify-content-between">
              <p>Image</p>
              <label className="imageUpload">
                Upload Image
                <input
                  type="file"
                  name="banner"
                  id="banner"
                  onChange={handleImageChanges}
                />
              </label>
            </Box>
            {ImagesURL && (
              <div className="d-flex justify-content-center">
                <img src={ImagesURL} alt="banner" className="auto" />
              </div>
            )}
          </Grid>
        </Grid>
        <Divider className="my-3" />
        <Box>
          <FormControl>
            <Typography component="h6" variant="h6">
              Products to Buy
            </Typography>
            <Typography component="p" variant="body2">
              Select items for offer
            </Typography>
            <Typography component="p" variant="caption">
              specifically select categories or products for the offer
            </Typography>
            <Select
              color="primary"
              variant="outlined"
              onChange={(e) => handleToBuy(e)}
              defaultValue={0}
            >
              <MenuItem value={0}>Select Product</MenuItem>
              {products &&
                products.map((prod) => {
                  return <MenuItem value={prod}>{prod?.product_name}</MenuItem>;
                })}
            </Select>
          </FormControl>
        </Box>

        <Box component={Paper} p={4}>
          <List>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  Type
                </Grid>
                <Grid item xs={10}>
                  Name
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
            {producttobuy &&
              producttobuy?.map((p) => {
                return (
                  <ListItem className={classes.listitem}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        Product
                      </Grid>
                      <Grid item xs={8}>
                        {p.product_name}
                      </Grid>
                      <Grid item xs={2} className="d-flex justify-content-end">
                        <IconButton
                          style={{ color: "red" }}
                          aria-label="Remove"
                          onClick={() => removeToBuy(p)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
          </List>
        </Box>
        <Divider />
        <Box>
          <FormControl>
            <Typography component="h6" variant="h6">
              Reward Products
            </Typography>
            <Typography component="p" variant="body2">
              Select items for offer
            </Typography>
            <Typography component="p" variant="caption">
              specifically select categories or products for the offer
            </Typography>
            <Select
              color="primary"
              variant="outlined"
              onChange={(e) => handletoreward(e)}
              defaultValue={0}
            >
              <MenuItem value={0}>Select Product</MenuItem>
              {products &&
                products.map((prod) => {
                  return <MenuItem value={prod}>{prod?.product_name}</MenuItem>;
                })}
            </Select>
          </FormControl>
        </Box>
        <Box component={Paper} p={4}>
          <List>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  Type
                </Grid>
                <Grid item xs={10}>
                  Name
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
            {rewardproduct &&
              rewardproduct?.map((p) => {
                return (
                  <ListItem className={classes.listitem}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        Product
                      </Grid>
                      <Grid item xs={8}>
                        {p.product_name}
                      </Grid>
                      <Grid item xs={2} className="d-flex justify-content-end">
                        <IconButton
                          style={{ color: "red" }}
                          aria-label="Remove"
                          onClick={() => removetoreward(p)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
          </List>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            onClick={() => AddOffer()}
          >
            Save
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default AddBogoOffer;
