import React, { useState, useEffect } from "react";
import { Grid, Box, Button, Menu, MenuItem } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/Sort";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import axios from 'axios'
import Config from '../../../config.json';
import { useUserContext } from './../../../Context/UserContext';
import { useConfirm } from 'material-ui-confirm';
import Alert from '@material-ui/lab/Alert'
function Voucher() {
  const columns = [
    {
      name: "Voucher Name",
      selector: "voucher_name",
      sortable: true,
    },
    {
      name: "Partner Name",
      selector: "brand",
      sortable: true,
    },
    {
      name: "Amount",
      selector: "amount",
      sortable: true,
    },
    {
      name: "Date Start",
      selector: "start_date",
      sortable: true,
    },
    {
      name: "Date End",
      selector: "expiry_date",
      sortable: true,
    },
    {
      name: "Action",
      sortable: true,
      width: "150px",
      cell: (row) => (
          <ActionMenu Voucher={row} setvoucher={setvoucher} setsuccess={setsuccess} seterror={seterror} />
      ),
    },
  ];
  const [success, setsuccess] = useState(false)
  const [error, seterror] = useState({})
  const [voucher, setvoucher] = useState([]);
  const [search, setsearch] = useState('')
  const [isLoading, setisLoading] = useState(false)
  useEffect(() => {
    const init = async () => {
      setisLoading(true)
      await axios.get(`${Config.SERVER_URL}/voucher?search=${search}`).then((response => {
        setvoucher(response.data)
        setisLoading(false)
      })).catch(error => {
       
        setisLoading(false)
      })
    };
    init();
  }, [search]);
  function handleChange(e) {
   
  }

  return (
    <>
      <Grid container justify="flex-end">
        <Grid item xs>
          <Box className="d-flex align-items-center justify-content-end my-3">
            <DeleteForeverIcon className="text-main" fontSize="large" />
            <TextField
              name="Search"
              id="Search"
              color="primary"
              variant="outlined"
              size="small"
              onChange={e => setsearch(e.target.value)}
            />
            <Link to="/marketing/voucher/add">
              <Button color="primary" variant="contained" className="mx-3">
                Add Voucher
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
      {
        success && <Alert severity="success">Voucher Delete Successfully</Alert>
      }
      {
        error && Object.keys(error).map(key => {
          return <Alert key={key} severity="error">{error[key]}</Alert>
        })
      }
      <DataTable
        columns={columns}
        data={voucher}
        defaultSortField="title"
        SortIcon={<SortIcon />}
        pagination
        selectableRows
        progressPending={isLoading}
        onSelectedRowsChange={handleChange}
      />
    </>
  );
}

function ActionMenu({ Voucher, setvoucher, setsuccess, seterror }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { getuser } = useUserContext()
  const [user] = useState(getuser());
  const confirm = useConfirm();
  const handleDelete = async () => {
    confirm({ description: `Are you sure you want to delete ${Voucher?.voucher_name}!` })
      .then(async () => {
        await axios.post(`${Config.SERVER_URL}/voucher/delete/${Voucher?.id}`, {
          admin_id: user?.id
        }).then(res => {
          setsuccess(true);
          setvoucher(res.data)
          handleClose();
          setTimeout(() => {
            setsuccess(false)
          }, 3000);
        }).catch(error => {
          if (error?.response && error?.response.status === 400)
            seterror(error.response.data)
          setTimeout(() => {
            seterror({})
          }, 3000);
        })

      })
      .catch(() => { /* ... */ });
  };
  return (
    <div>
      <IconButton
        aria-label="settings"
        className="py-0"
        style={{ fontSize: "16pt" }}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </div>
  );
}
export default Voucher;
