import React, { useState, useEffect } from "react";
import { Container, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
  Breadcrumbs,
  Grid,
  TextField,
  Box,
  FormControl,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/Sort";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import axios from "axios";
import Config from "../../config.json";
import DeleteIcon from "@material-ui/icons/Delete";
import Alert from "@material-ui/lab/Alert";
export default function Staffs() {
  const [Filters, setFilters] = useState({
    NoOfOrder: "",
    From: "",
    To: "",
  });
  const [EnableFilters, setEnableFilters] = useState(false);
  const handleFilterChange = (e) => {
    let TempFilters = Filters;
    TempFilters[e.target.name] = e.target.value;
    setFilters(TempFilters);
  };
  const columns = [
    {
      name: "Profile",
      selector: "Image",
      sortable: true,
      cell: (row) => (
        <img
          src={row.image}
          alt="user"
          width="52"
          height="52"
          className="m-1"
        />
      ),
      width: "80px",
    },
    {
      name: "Staff Name",
      selector: "Staff",
      sortable: true,
      cell: (row) => (
        <Link to={`/staff/details/${row?.id}`}>
          {row?.first_name} {row?.last_name}
        </Link>
      ),
    },
    {
      name: "Email Address",
      selector: "email",
      sortable: true,
    },
    {
      name: "Phone",
      selector: "phone",
      sortable: true,
    },
    {
      name: "Role",
      selector: "role",
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "80px",
      cell: (row) => (
        <IconButton onClick={() => deleteStaff(row?.id)}>
          <DeleteIcon style={{ color: "red" }} />
        </IconButton>
      ),
    },
  ];
  const deleteStaff = async (id) => {
    await axios
      .post(`${Config.SERVER_URL}/staff/delete/${id}`)
      .then((res) => {
        setstaff(res.data);
      })
      .catch((error) => {
        error.response &&
          error.response.status === 400 &&
          seterror(error.response.data);
      });
  };
  const [error, seterror] = useState({});
  const [search, setsearch] = useState("");
  const [staff, setstaff] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [pagenumber, setpagenumber] = useState(1);
  const [data, setdata] = useState(null);
  // eslint-disable-next-line
  const [selectedRows, setselectedRows] = useState([]);
  const handleChange = (e) => {
    setselectedRows(e.selectedRows);
  };
  const SubmitFilter = async () => {
    setisLoading(true);
    await axios
      .get(
        `${Config.SERVER_URL}/staff?from=${Filters.From}&to=${Filters.To}&orders=${Filters.NoOfOrder}`
      )
      .then((response) => {
        if (response.data) setstaff(response.data.data);
        setdata(response.data);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(false);
      });
  };
  useEffect(() => {
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}/staff?search=${search}&page=${pagenumber}`)
        .then((response) => {
          if (response.data) setstaff(response.data.data);
          setdata(response.data);
          setisLoading(false);
        })
        .catch((error) => {
          setisLoading(false);
        });
    };
    init();
  }, [search, pagenumber]);
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        Staff
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/staff">
          Staff
        </Link>
      </Breadcrumbs>
      <Paper className="p-4">
        <Box>
          <Grid container justify="space-between" spacing={3}>
            <Grid item xs={6} sm={3}>
              <IconButton
                aria-label="delete"
                color="default"
                className="filters"
                onClick={() => setEnableFilters(!EnableFilters)}
              >
                Filters
                <ArrowDropDownIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={6}
              className="d-flex justify-content-end align-items-center"
            >
              <TextField
                variant="outlined"
                size="small"
                onChange={(e) => setsearch(e.target.value)}
              ></TextField>
              <Link to="/staff/new" className="mx-2 mt-2">
                <Button variant="contained" color="primary" className="h-100">
                  Add Staff
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Box p={4} className={EnableFilters ? "Filters-Show" : "Filters-Hide"}>
          <Grid container spacing={4}>
            <Grid item xs={6} md={4}>
              <label htmlFor="" className="m-0">
                From
              </label>
              <TextField
                variant="outlined"
                name="From"
                fullWidth
                margin="none"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                type="date"
              ></TextField>
            </Grid>
            <Grid item xs={6} md={4}>
              <label htmlFor="" className="m-0">
                To
              </label>
              <TextField
                variant="outlined"
                name="To"
                fullWidth
                margin="none"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                type="date"
              ></TextField>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <label className="pb-2">No. of Orders</label>
                <TextField
                  name="NoOfOrder"
                  id="NoOfOrder"
                  color="primary"
                  variant="outlined"
                  size="small"
                  placeholder="Type Here"
                  onChange={(e) => {
                    handleFilterChange(e);
                  }}
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item container xs={12} className="d-flex justify-content-end">
              <Button color="primary" variant="outlined" onClick={SubmitFilter}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
        {error &&
          Object.keys(error).map((key) => {
            return <Alert severity="error">{error[key]}</Alert>;
          })}
        <DataTable
          columns={columns}
          data={staff}
          defaultSortField="title"
          SortIcon={<SortIcon />}
          pagination
          progressPending={isLoading}
          selectableRows
          onSelectedRowsChange={handleChange}
          paginationPerPage={10}
          paginationServer={true}
          paginationTotalRows={data?.total}
          onChangePage={(e) => setpagenumber(e)}
        />
      </Paper>
    </Container>
  );
}
