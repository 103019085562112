import React, { useState, useEffect } from "react";
import { Box, FormControl, Grid, TextField } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Container } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/Sort";
import Config from "../../config.json";
import axios from "axios";
function ProductReport() {
  const [Filters, setFilters] = useState({
    NoOfOrder: 0,
    Category: "",
    Model: "",
    InStock: "",
  });
  const [EnableFilters, setEnableFilters] = useState(false);
  const handleFilterChange = (e) => {
    let TempFilters = Filters;
    TempFilters[e.target.name] = e.target.value;
    setFilters(TempFilters);
  };
  const [products, setproducts] = useState([]);
  const HandleFilters = async () => {
    await axios
      .get(
        `${Config.SERVER_URL}/report/product?order=${Filters.NoOfOrder}&category=${Filters.Category}&model=${Filters.Model}&instock=${Filters.InStock}`
      )
      .then((response) => {
        if (Object.keys(response.data).length > 0) setproducts(response.data.data); setdata(response.data)
      })
      .catch((error) => {
       
      });
  };
  const [pagenumber, setpagenumber] = useState(1)
  const [data, setdata] = useState(null)
  useEffect(() => {
    const init = async () => {
      await axios
        .get(
          `${Config.SERVER_URL}/report/product?page=${pagenumber}`
        )
        .then((response) => {
          if (Object.keys(response.data).length > 0) setproducts(response.data.data); setdata(response.data)
        })
        .catch((error) => {
         
        });
    }
    init();
  }, [pagenumber])
  const columns = [
    {
      name: "Product Name",
      selector: "product_name",
      sortable: true,
    },
    {
      name: "Category",
      selector: "category",
      sortable: true,
      cell: row => (
        row?.category?.category_name
      )
    },
    {
      name: "Model",
      selector: "model",
      sortable: true,
    },
    {
      name: "NoOfOrders",
      selector: "cart_count",
      sortable: true,
    },
    {
      name: "Price",
      selector: "price",
      sortable: true,
    },
    {
      name: "In Stock",
      selector: "quantity",
      sortable: true,
    },
  ];

  return (
    <Container maxWidth="xl">
      <Box>
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={6} sm={3}>
            <IconButton
              aria-label="delete"
              color="default"
              className="filters"
              onClick={() => setEnableFilters(!EnableFilters)}
            >
              Filters
              <ArrowDropDownIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box p={4} className={EnableFilters ? "Filters-Show" : "Filters-Hide"}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">No of Orders</label>
              <TextField
                variant="outlined"
                name="NoOfOrder"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                type="number"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Category</label>
              <TextField
                variant="outlined"
                name="Category"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Model</label>
              <TextField
                variant="outlined"
                name="Model"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">In Stock</label>
              <TextField
                variant="outlined"
                name="InStock"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                type="number"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} container justify="flex-end" alignItems="center">
            <Button variant="outlined" color="primary" onClick={HandleFilters}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataTable
          columns={columns}
          data={products}
          defaultSortField="title"
          SortIcon={<SortIcon />}
          pagination
          paginationPerPage={10}
          paginationServer={true}
          paginationTotalRows={data?.total}
          onChangePage={(e) => setpagenumber(e)}
        />
      </Box>
    </Container>
  );
}

export default ProductReport;
