import {
  Paper,
  Typography,
  Box,
  TextField,
  Grid,
  Button,
  FormControl,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Config from "../../config.json";
import { Alert } from "@material-ui/lab";
function Preferences() {
  // states
  const [Conversion, setConversion] = useState(null);
  const [UpdateConversion, setUpdateConversion] = useState(null);
  const [ResponseSuccess, setResponseSuccess] = useState(false);
  const [ResponseError, setResponseError] = useState(false);
  const [TaxSuccess, setTaxSuccess] = useState(false);
  const [TaxError, setTaxError] = useState(false);
  const [ShippingSuccess, setShippingSuccess] = useState(false);
  const [ShippingError, setShippingError] = useState(false);
  const [CODSuccess, setCODSuccess] = useState(false);

  const [COD, setCOD] = useState({});
  const [Tax, setTax] = useState(null);
  const [UpdateTax, setUpdateTax] = useState(null);
  const [Shipping, setShipping] = useState({});

  const handleChange = (e) => {
    UpdateConversion[e.target.name] = e.target.value;
    setUpdateConversion(Conversion);
  };
  const handleTaxChange = (e) => {
    UpdateTax[e.target.name] = e.target.value;
    setUpdateTax(Conversion);
  };
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}/points`)
        .then((response) => {
          setConversion(response.data);
          setUpdateConversion(response.data);
        })
        .catch((error) => {});
      await axios
        .get(`${Config.SERVER_URL}/tax`)
        .then((response) => {
          setTax(response.data);
          setUpdateTax(response.data);
        })
        .catch((error) => {});
      await axios
        .get(`${Config.SERVER_URL}/shippingrate`)
        .then((response) => {
          setShipping(response.data);
        })
        .catch((error) => {});
      await axios
        .get(`${Config.SERVER_URL}/cod`)
        .then((response) => {
          setCOD(response.data);
          setisLoading(false);
        })
        .catch((error) => {});
    };
    init();
  }, []);
  const updatePoints = async () => {
    await axios
      .post(`${Config.SERVER_URL}/points/update`, UpdateConversion)
      .then((response) => {
        setResponseSuccess(true);
      })
      .catch((error) => {
        setResponseError(true);
      });
  };
  const updateTax = async () => {
    await axios
      .post(`${Config.SERVER_URL}/tax/update`, UpdateTax)
      .then((response) => {
        setTaxSuccess(true);
      })
      .catch((error) => {
        setTaxError(true);
      });
  };
  const updateShipping = async () => {
    await axios
      .post(`${Config.SERVER_URL}/shippingrate/update`, Shipping)
      .then((response) => {
        setShippingSuccess(true);
      })
      .catch((error) => {
        setShippingError(true);
      });
  };
  const updateCOD = async () => {
    await axios
      .post(`${Config.SERVER_URL}/cod`, COD)
      .then((response) => {
        setCODSuccess(true);
      })
      .catch((error) => {});
  };

  return (
    <>
      {isLoading ? (
        <Typography>Loading Please wait.</Typography>
      ) : (
        <>
          <Paper className="p-4 my-3" elevation={2}>
            <Typography component="h6" variant="h6">
              Points conversion rate
            </Typography>
            <Typography component="h6" variant="body2">
              set a conversion rate for points to real money
            </Typography>
            {ResponseError && (
              <Alert severity="error">Something Went Wrong</Alert>
            )}
            {ResponseSuccess && (
              <Alert severity="success">Conversion Points updated</Alert>
            )}
            <Box style={{ marginTop: "57px" }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <TextField
                    name="points"
                    id="Points"
                    variant="outlined"
                    defaultValue={Conversion?.points}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    size="small"
                    type="number"
                  />
                </Grid>
                <Grid
                  xs={1}
                  item
                  className="d-flex align-items-center justify-content-center"
                >
                  <Typography component="h6" variant="h6" align="center">
                    =
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    name="amount"
                    id="Amount"
                    variant="outlined"
                    defaultValue={Conversion?.amount}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    size="small"
                    type="number"
                  />
                </Grid>
                <Grid xs={2} item container alignItems="center">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={updatePoints}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>

          {/* VAT */}
          <Paper className="p-4 my-3" elevation={2}>
            <Typography component="h6" variant="h6">
              VAT adjustments
            </Typography>
            <Typography component="h6" variant="body2">
              Manage VAT value and settings
            </Typography>
            {TaxError && <Alert severity="error">Something Went Wrong</Alert>}
            {TaxSuccess && <Alert severity="success">VAT updated</Alert>}
            <Box style={{ marginTop: "57px" }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <FormControl>
                    <label htmlFor="">VAT</label>
                    <TextField
                      name="tax_value"
                      color="primary"
                      variant="outlined"
                      defaultValue={Tax?.tax_value}
                      onChange={(e) => handleTaxChange(e)}
                      size="small"
                    ></TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={2} container alignItems="center">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={updateTax}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
          {/* Shipping */}
          {Object.keys(Shipping).length > 0 && (
            <Paper className="p-4 my-3" elevation={2}>
              <Typography component="h6" variant="h6">
                Shipping
              </Typography>
              <Typography component="h6" variant="body2">
                Manage Shipping Rates
              </Typography>
              {ShippingError && (
                <Alert severity="error">Something Went Wrong</Alert>
              )}
              {ShippingSuccess && (
                <Alert severity="success">Shipping updated</Alert>
              )}
              <Box style={{ marginTop: "57px" }}>
                <Grid container spacing={2}>
                  <Grid item xs lg={2}>
                    <FormControl>
                      <label htmlFor="">Shipping Rate</label>
                      <TextField
                        name="shipping_rate"
                        color="primary"
                        variant="outlined"
                        defaultValue={Shipping?.shipping_rate}
                        onChange={(e) =>
                          setShipping({
                            ...Shipping,
                            [e.target.name]: e.target.value,
                          })
                        }
                        size="small"
                      ></TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs lg={2}>
                    <FormControl>
                      <label htmlFor="">International Shipping Rate</label>
                      <TextField
                        name="international_charges"
                        color="primary"
                        variant="outlined"
                        defaultValue={Shipping?.international_charges}
                        onChange={(e) =>
                          setShipping({
                            ...Shipping,
                            [e.target.name]: e.target.value,
                          })
                        }
                        size="small"
                      ></TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs lg={2}>
                    <FormControl>
                      <label htmlFor="">Free Shipping Over</label>
                      <TextField
                        name="free_shipping"
                        color="primary"
                        variant="outlined"
                        defaultValue={Shipping?.free_shipping}
                        onChange={(e) =>
                          setShipping({
                            ...Shipping,
                            [e.target.name]: e.target.value,
                          })
                        }
                        size="small"
                      ></TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs lg={4}>
                    <FormControl>
                      <label htmlFor="">
                        Free Shipping Over for International Shipping
                      </label>
                      <TextField
                        name="free_international_shipping"
                        color="primary"
                        variant="outlined"
                        defaultValue={Shipping?.free_international_shipping}
                        onChange={(e) =>
                          setShipping({
                            ...Shipping,
                            [e.target.name]: e.target.value,
                          })
                        }
                        size="small"
                      ></TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} container alignItems="center">
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={updateShipping}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          )}

          {/* COD Charges */}
          {Object.keys(COD).length > 0 && (
            <Paper className="p-4 my-3" elevation={2}>
              <Typography component="h6" variant="h6">
                COD Charges
              </Typography>
              <Typography component="h6" variant="body2">
                Manage COD Charges
              </Typography>

              {CODSuccess && <Alert severity="success">COD updated</Alert>}
              <Box style={{ marginTop: "57px" }}>
                <Grid container spacing={2}>
                  <Grid item xs lg={2}>
                    <FormControl>
                      <label htmlFor="">Charges</label>
                      <TextField
                        name="charges"
                        color="primary"
                        variant="outlined"
                        defaultValue={COD?.charges}
                        onChange={(e) =>
                          setCOD({
                            ...COD,
                            [e.target.name]: e.target.value,
                          })
                        }
                        size="small"
                      ></TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} container alignItems="center">
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={updateCOD}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          )}
        </>
      )}
    </>
  );
}

export default Preferences;
