import {
  Container,
  FormControl,
  Paper,
  TextField,
  Box,
  Button,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Breadcrumbs, Grid } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import axios from "axios";
import Config from "../../../../config.json";
import Alert from "@material-ui/lab/Alert";

function EditDiscountOffer() {
  const [Offer, setOffer] = useState({
    name: "",
    from_date: "",
    to_date: "",
    discount: 0,
  });
  const handleChange = (e) => {
    setOffer({ ...Offer, [e.target.name]: e.target.value });
  };
  const [ImagesURL, setImagesURL] = useState("");
  const handleImageChanges = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    await axios
      .post(`${Config.SERVER_URL}/offer/discount/update/image/${id}`, formData)
      .then((res) => {
        setsuccess(true);
        setTimeout(() => {
          setsuccess(false);
          setImagesURL(res.data);
        }, 3000);
      })
      .catch((error) => {
        error && error.response.status === 400 && seterror(error.response.data);
      });
  };

  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState({});
  const location = useHistory();
  const { id } = useParams();

  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/offer/discount/get/${id}`)
        .then((res) => {
          setOffer(res.data);
          setImagesURL(res.data?.image);
        })
        .catch((error) => {});
    };
    init();
  }, [id]);
  const updateOffer = async () => {
    await axios
      .post(`${Config.SERVER_URL}/offer/update/${id}`, Offer)
      .then((res) => {
        setsuccess(true);
        setTimeout(() => {
          location.push("/marketing");
        }, 3000);
      })
      .catch((error) => {
        error && error.response.status === 400 && seterror(error.response.data);
      });
  };
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        <Link to="/customers">
          <ArrowBackIcon className="text-dark" />
        </Link>{" "}
        DISCOUNT OFFER
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/marketing">
          Marketing
        </Link>
        <Link color="inherit" to="/">
          {" "}
          Edit Discount Offer
        </Link>
      </Breadcrumbs>
      <Paper className="p-4">
        {success && (
          <Alert severity="success">Offer Updated successfully</Alert>
        )}
        {error &&
          Object.keys(error).map((key) => {
            return <Alert severity="error">{error[key]}</Alert>;
          })}
        <Grid container spacing={4} justify="space-between">
          <Grid item xs={5}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <label>Offer Name</label>
                  <TextField
                    name="name"
                    id="name"
                    color="primary"
                    variant="outlined"
                    onChange={handleChange}
                    size="small"
                    value={Offer?.name}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <label>From</label>
                  <TextField
                    name="from_date"
                    id="from_date"
                    color="primary"
                    variant="outlined"
                    type="date"
                    size="small"
                    onChange={handleChange}
                    value={Offer?.from_date}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <label>To</label>
                  <TextField
                    name="to_date"
                    id="to_date"
                    color="primary"
                    variant="outlined"
                    type="date"
                    size="small"
                    onChange={handleChange}
                    value={Offer?.to_date}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <label>Discount (%)</label>
                  <TextField
                    name="discount"
                    id="discount"
                    color="primary"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    value={Offer?.discount}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Box className="d-flex justify-content-between">
              <p>Image</p>
              <label className="imageUpload">
                Upload Image
                <input
                  type="file"
                  name="banner"
                  id="banner"
                  onChange={handleImageChanges}
                />
              </label>
            </Box>
            {ImagesURL && (
              <div className="d-flex justify-content-center">
                <img src={ImagesURL} alt="banner" width="470" height="300" />
              </div>
            )}
          </Grid>
        </Grid>
        <Box justifyContent="flex-end" display="flex" m={3}>
          <Button color="primary" variant="contained" onClick={updateOffer}>
            Update
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default EditDiscountOffer;
