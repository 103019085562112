import React, { useState } from "react";
import {
  Container,
  Typography,
  Breadcrumbs,
  Grid,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { makeStyles } from "@material-ui/core/styles";
import Preferences from "./Preferences";
import Legal from './Legal/index';
import FAQ from './FAQ';
const useStyles = makeStyles((theme) => ({
  active: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 6px #0000003D",
    border: "1px solid #BF6159",
  },
}));
const getComponent = (step) => {
  switch (step) {
    case 0:
      return <Preferences />;
    case 1:
      return <Legal />;
    case 2:
      return <FAQ />;

    default:
      break;
  }
};
function Settings() {
  const classes = useStyles();
  const [step, setstep] = useState(0);
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        Reports
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/settings" disabled>
          Settings
        </Link>
      </Breadcrumbs>
      <Grid container justify="space-around">
        <Grid
          item
          xs
          md={3}
          className={step === 0 ? classes.active : ""}
          onClick={() => setstep(0)}
        >
          <Box p={3}>
            <Typography component="p" className="text-main">Preference</Typography>
            <p>
              Adjust services and settings to make the system preferable to you
            </p>
          </Box>
        </Grid>
        <Grid
          item
          xs
          md={3}
          className={step === 1 ? classes.active : ""}
          onClick={() => setstep(1)}
        >
          <Box p={3}>
            <Typography component="p" className="text-main">Legal</Typography>
            <p>
              Create your legal documentations for the customers to be clear
            </p>
          </Box>
        </Grid>
        <Grid
          item
          xs
          md={3}
          className={step === 2 ? classes.active : ""}
          onClick={() => setstep(2)}
        >
          <Box p={3}>
            <Typography component="p" className="text-main">FAQ's</Typography>
            <p>Curate the frequently asked questions for customer's guidance</p>
          </Box>
        </Grid>
      </Grid>
      <Box p={4}>{getComponent(step)}</Box>
    </Container>
  );
}

export default Settings;
