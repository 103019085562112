import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/Sort";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Button } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import Config from "../../config.json";
import Export from "./../Orders/component/Export";
const getQuery = (filters) => {
  var q = "";
  Object.keys(filters).forEach((k) => {
    if (filters[k] !== "" && filters[k] !== 0 && filters[k] !== null) {
      q += `${k}=${filters[k]}&`;
    }
  });
  return q;
};

export default function Returns() {
  const [Filters, setFilters] = useState({
    customer: "",
    product: "",
    status: "",
    from: "",
    to: "",
    country: "",
    Zone: "",
    City: "",
    promo: "",
    total: 0,
    shipping: 0,
  });
  const [EnableFilters, setEnableFilters] = useState(false);

  const handleFilterChange = (e) => {
    let TempFilters = Filters;
    TempFilters[e.target.name] = e.target.value;
    setFilters(TempFilters);
  };
  const columns = [
    {
      name: "Order-ID",
      selector: "id",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <Link
            to={`/orders/return/details/${row?.id}`}
            style={{ fontWeight: "bold" }}
          >
            {row?.id}
            {row?.is_seen === 0 || row?.is_seen === "0" ? (
              <span className="not-seen"></span>
            ) : (
              ""
            )}
          </Link>
        </div>
      ),
    },
    {
      name: "Customer",
      selector: "customer_id",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          {row?.customer_id !== 0
            ? row?.customer?.first_name + " " + row?.customer?.last_name
            : row?.first_name + " " + row?.last_name}
        </div>
      ),
    },
    {
      name: "Delivery Address",
      selector: "address",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    {
      name: "Dates",
      selector: "created_at",
      sortable: true,
    },
    {
      name: "Payment Type",
      sortable: true,
      selector: "payment_method",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <span>
            {row?.payment_method === "CreditCardPayments"
              ? "Credit/Debit Card "
              : "COD"}
          </span>
        </div>
      ),
    },
    {
      name: "Discount",
      selector: "discount",
      sortable: true,
    },
    {
      name: "Promo Discount",
      selector: "promo_discount",
      sortable: true,
    },
    {
      name: "Points Redeemed",
      selector: "points_discount",
      sortable: true,
    },
    {
      name: "VAT",
      selector: "vat",
      sortable: true,
    },
    {
      name: "Shipping",
      selector: "shipping",
      sortable: true,
    },
    {
      name: "Discount Code",
      selector: "discount_code",
      sortable: true,
    },
    {
      name: "Promo Code",
      selector: "promo_code",
      sortable: true,
    },
    {
      name: "Sub Total",
      selector: "total",
      sortable: true,
    },
    {
      name: "Total",
      selector: "sub_total",
      sortable: true,
    },
    {
      name: "Order From",
      sortable: false,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <span>
            {row?.staff_id !== null ? row.staff?.first_name : "Website"}
          </span>
        </div>
      ),
    },
  ];
  const [isLoading, setisLoading] = useState(false);
  const [returns, setreturns] = useState([]);
  const [search, setsearch] = useState("");
  const [pagenumber, setpagenumber] = useState(1);
  const [data, setdata] = useState(null);
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/order/9?search=${search}&page=${pagenumber}`)
        .then((response) => {
          setreturns(response.data.data);
          setdata(response.data);
        })
        .catch((error) => {});
    };
    init();
  }, [search, pagenumber]);
  function handleChange(e) {}
  const submitFilter = async () => {
    setisLoading(true);
    await axios
      .get(`${Config.SERVER_URL}/order/9?${getQuery(Filters)}`)
      .then((response) => {
        setreturns(response.data.data);
        setdata(response.data);
        setisLoading(false);
      })
      .catch((error) => {});
  };
  return (
    <>
      <Box>
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={6} sm={3}>
            <IconButton
              aria-label="delete"
              color="default"
              className="filters"
              onClick={() => setEnableFilters(!EnableFilters)}
            >
              Filters
              <ArrowDropDownIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs
            className="d-flex justify-content-end align-items-center"
          >
            <Export />
            <TextField
              variant="outlined"
              size="small"
              onChange={(e) => setsearch(e.target.value)}
            ></TextField>
            <Link to="/orders/new" className="mx-2">
              <Button variant="contained" color="primary" className="h-100">
                Add Order
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Box p={4} className={EnableFilters ? "Filters-Show" : "Filters-Hide"}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Customer</label>
              <TextField
                variant="outlined"
                name="customer"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Product</label>
              <TextField
                variant="outlined"
                name="product"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Order Status</label>
              <TextField
                variant="outlined"
                name="status"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <label htmlFor="">From</label>
              <TextField
                variant="outlined"
                name="from"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
                type="date"
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <label htmlFor="">To</label>
              <TextField
                variant="outlined"
                name="to"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
                type="date"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Discount</label>
              <TextField
                variant="outlined"
                name="discount"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Promo</label>
              <TextField
                variant="outlined"
                name="promo"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Shipping</label>
              <TextField
                variant="outlined"
                name="shipping"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Total</label>
              <TextField
                variant="outlined"
                name="total"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>
          <Grid
            item
            container
            justify="flex-end"
            alignItems="center"
            xs
            className="mt-4 "
          >
            <Button variant="outlined" color="primary" onClick={submitFilter}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>

      <DataTable
        columns={columns}
        data={returns}
        defaultSortField="title"
        SortIcon={<SortIcon />}
        pagination
        selectableRows
        onSelectedRowsChange={handleChange}
        progressPending={isLoading}
        paginationPerPage={10}
        paginationServer={true}
        paginationTotalRows={data?.total}
        onChangePage={(e) => setpagenumber(e)}
      />
    </>
  );
}
