import React, { useState } from "react";
import Logo from "../../assets/logo/ADVABlack.svg";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { FormControl,Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import Config from "../../config.json";
import { useUserContext } from './../../Context/UserContext';
import {Link} from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    input: {
        width: "100%",
        backgroundColor: "white",
        color: "black",
        margin: "3px auto",
        "& input:invalid": {
            borderColor: "#BF6159 !important",
        },
        "& input:valid:focus + fieldset": {
            borderColor: "#BF6159 !important",
            padding: "4px !important",
        },
        "& .MuiFormLabel-root.Mui-focused": {
            borderColor: "#BF6159 !important",
            color: "#BF6159",

        },
    },
}));

export default function StaffLogin() {
    const classes = useStyles();
    const { register, handleSubmit, errors } = useForm();
    const { login } = useUserContext();

    const location = useHistory();
    // eslint-disable-next-line
    const [UserForm, setUserForm] = useState({ email: "", password: "" });
    const [error, seterror] = useState(false);
    const onSubmit = async (data) => {
        await axios
            .post(`${Config.SERVER_URL}/staff/login`, { ...UserForm })
            .then((res) => {
                seterror(false);
                login(res.data);
                location.push("/staff/order");
            })
            .catch((error) => {
                seterror(true);
            });
    };
    // eslint-disable-next-line
    const handleFormChange = (e) => {
        UserForm[e.target.name] = e.target.value;
        setUserForm(UserForm);
    };
    // if(isLogined()){
    //     logout();
    // }
    return (
        <Container component="main" maxWidth="xs" className="mb-5">
            <CssBaseline />
            <div className={classes.paper}>
                <img src={Logo} alt="" width="200" height="200" />
                <Typography component="h1" variant="h3">
                    Welcome
        </Typography>
                <Typography component="h1" variant="h6">
                    To ADVA Staff Panel Please Login
        </Typography>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    {error && <Alert severity="error">Invalid Credtionals</Alert>}
                    <FormControl fullWidth className="mt-2">
                        <label>Email or Phone</label>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="email"
                            className={classes.input}
                            onChange={(e) => handleFormChange(e)}
                            autoFocus
                            inputRef={register({
                                required: "required",
                            })}
                            size="small"
                        />
                        <span className="error">{errors.email?.message}</span>
                    </FormControl>
                    <FormControl fullWidth className="my-4">
                        <label htmlFor="">password</label>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            type="password"
                            id="password"
                            onChange={(e) => handleFormChange(e)}
                            autoComplete="current-password"
                            className={classes.input}
                            inputRef={register({
                                required: "required",
                            })}
                            size="small"
                        />
                        <span className="error">{errors.password?.message}</span>
                    </FormControl>

                    {/* <Grid container alignItems="center" justify="space-between">
            <Grid item xs md={5}>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
            </Grid>
            <Grid item xs md={4}>
              <Link
                href="/account-recovery"
                className="forgot-password"
                variant="body2"
              >
                Forgot password?
              </Link>
            </Grid>
          </Grid> */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
          </Button>
                </form>
            </div>
            
      <Grid item container xs={12} justify="flex-end">
        <Link to="/login">Sign in as Admin</Link>
      </Grid>
        </Container>
    );
}
