import React, { useState, useEffect } from "react";
import { Container, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
  Breadcrumbs,
  Grid,
  TextField,
  Box,
  FormControl,
  Button, IconButton
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/Sort";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import axios from "axios";
import Config from "../../config.json";
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert'
import { useConfirm } from 'material-ui-confirm';
export default function Customers() {
  const [Filters, setFilters] = useState({
    NoOfOrder: "",
    From: "",
    To: "",
  });
  const [EnableFilters, setEnableFilters] = useState(false);
  const handleFilterChange = (e) => {
    let TempFilters = Filters;
    TempFilters[e.target.name] = e.target.value;
    setFilters(TempFilters);
  };
  const columns = [
    {
      name: "Customer Name",
      sortable: true,
      cell: (row) => (
        <Link to={`/customers/details/${row.id}`}>{row.first_name} {row.last_name}</Link>
      ),
    },
    {
      name: "Email Address",
      selector: "email",
      sortable: true,
    },
    {
      name: "Phone",
      selector: "phone",
      sortable: true,
    },
    {
      name: "Created Date",
      sortable: true,
      cell: (row) => (
        row.created_at
      ),
    },
    {
      name: "No of Orders",
      selector: "orders_count",
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      cell: row => <IconButton onClick={() => deleteCustomer(row?.id)}><DeleteIcon /></IconButton>
    },
  ];
  const confirm = useConfirm();
  const deleteCustomer = async (id) => {
    
    confirm({ description: 'Are you sure you want to delete this customer!' })
    .then(async() => { 
      await axios.post(`${Config.SERVER_URL}/customer/delete/${id}`).then(res => {
        setcustomers(res.data);
      }).catch(error => {
        error.response && error.response.status === 400 && seterror(error.response.data)
      })
     })
    .catch(() => { /* ... */ });
  }
  const [error, seterror] = useState({})
  const [customers, setcustomers] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [data, setdata] = useState(null)
  const [pagenumber, setpagenumber] = useState(1)
  const [search, setsearch] = useState("");
  useEffect(() => {
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}/customers?search=${search}&page=${pagenumber}`)
        .then((response) => {
          if (response.data) {
            setcustomers(response.data.data);
            setdata(response.data)
            setisLoading(false);
          }
        })
        .catch((error) => {
          setisLoading(false);
         
        });
    };
    init();
  }, [search, pagenumber]);
  // eslint-disable-next-line
  const [selectedRows, setselectedRows] = useState([]);
  const handleChange = (e) => {
    setselectedRows(e.selectedRows);
  };
  const HandleFilters = async () => {
    setisLoading(true);
    await axios
      .get(`${Config.SERVER_URL}/customers?from=${Filters.From}&to=${Filters.To}&orders=${Filters.NoOfOrder}`)
      .then((response) => {
        if (response.data) {
          setcustomers(response.data.data);
          setisLoading(false);
        }
      })
      .catch((error) => {
        setisLoading(false);
       
      });
  };
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        Customers
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/customers" disabled>
          Customer
        </Link>
      </Breadcrumbs>
      <Paper className="p-4">
        <Box>
          <Grid container justify="space-between" spacing={3}>
            <Grid item xs={6} sm={3}>
              <IconButton
                aria-label="delete"
                color="default"
                className="filters"
                onClick={() => setEnableFilters(!EnableFilters)}
              >
                Filters
                <ArrowDropDownIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              xs
              className="d-flex justify-content-end align-items-center"
            >
              <TextField
                variant="outlined"
                size="small"
                placeholder="search"
                onChange={(e) => setsearch(e.target.value)}
              ></TextField>
            </Grid>
          </Grid>
        </Box>
        <Box p={4} className={EnableFilters ? "Filters-Show" : "Filters-Hide"}>
          <Grid container spacing={4}>
            <Grid item xs={6} lg={3}>
              <p className="m-0">Dates</p>
              <Grid container spacing={2}>
                <Grid item xs md={6}>
                  <label htmlFor="" className="m-0">
                    From
                  </label>
                  <TextField
                    variant="outlined"
                    name="From"
                    margin="none"
                    onChange={(e) => handleFilterChange(e)}
                    size="small"
                    type="date"
                    fullWidth
                  ></TextField>
                </Grid>
                <Grid item xs md={6}>
                  <label htmlFor="" className="m-0">To</label>
                  <TextField
                    variant="outlined" fullWidth
                    name="To"
                    onChange={(e) => handleFilterChange(e)}
                    size="small"
                    type="date"
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={3}>
              <FormControl fullWidth>
                <label className="pb-2">No. of Orders</label>
                <TextField
                  name="NoOfOrder" fullWidth
                  id="NoOfOrder"
                  color="primary"
                  variant="outlined"
                  size="small"
                  placeholder="Type Here"
                  onChange={(e) => {
                    handleFilterChange(e);
                  }}
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} className="d-flex justify-content-end">
              <Button color="primary" variant="outlined" onClick={HandleFilters}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
        {
          error && Object.keys(error).map(key => {
            return <Alert key={key} severity="error">{error[key]}</Alert>
          })
        }
        <DataTable
          columns={columns}
          data={customers}
          defaultSortField="title"
          SortIcon={<SortIcon />}
          pagination
          progressPending={isLoading}
          selectableRows
          onSelectedRowsChange={handleChange}
          paginationPerPage={10}
          paginationServer={true}
          paginationTotalRows={data?.total}
          onChangePage={(e) => setpagenumber(e)}
        />
      </Paper>
    </Container>
  );
}
