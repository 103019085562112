import React from "react";
import Logo from "../../assets/logo/ADVABlack.svg";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { FormControl, Paper } from "@material-ui/core";
import { useForm } from "react-hook-form";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    maxWidth: "95%",
    margin: "auto",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    margin: "3px auto",
    "& input:invalid": {
      borderColor: "#BF6159 !important",
    },
    "& input:valid:focus + fieldset": {
      borderColor: "#BF6159 !important",
      padding: "4px !important",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      borderColor: "#BF6159 !important",
      color: "#BF6159",
    },
  },
}));

export default function RecoverPassword() {
  const classes = useStyles();

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (data) => {
  };
  return (
    <Paper className={classes.root}>
      <Link to="/account-recovery" className="back mx-4">
        <ArrowBackIcon />
      </Link>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={Logo} alt="" />
          <Typography component="h1" variant="h6">
            Create Your New Password
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth className="mt-2">
              <label>New Password</label>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="password"
                id="NewPassword"
                name="NewPassword"
                size="small"
                className={classes.input}
                autoFocus
                inputRef={register({
                  required: "required",
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                    message: "error",
                  },
                })}
              />
              <span className="error">{errors.NewPassword?.message}</span>
            </FormControl>
            <FormControl fullWidth className="my-4">
              <label htmlFor="">Confirm Password</label>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="ConfirmPassword"
                type="password"
                id="ConfirmPassword"
                size="small"
                autoComplete="current-password"
                className={classes.input}
                inputRef={register({
                  required: "required",
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                    message: "error",
                  },
                })}
              />
              <span className="error">{errors.ConfirmPassword?.message}</span>
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Save & Continue
            </Button>
          </form>
        </div>
      </Container>
    </Paper>
  );
}
