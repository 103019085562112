import React, { useState, useEffect } from "react";
import {
  CardContent,
  Container,
  Divider,
  ListItem,
  List,
  TextField,
  FormControl,
  Button,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Grid, Card, Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/Sort";
import axios from "axios";
import Config from "../../config.json";
import { useForm } from "react-hook-form";
import Alert from "@material-ui/lab/Alert";
import { useUserContext } from "./../../Context/UserContext";
const useStyles = makeStyles(() => ({
  card: {
    minHeight: 650,
  },
  profile: {
    marginTop: "27px",
    padding: "16px",
  },
  list: {
    "& .MuiListItem-root": {
      paddingTop: "19px",
      paddingBottom: "19px",
      cursor: "pointer",
    },
    "& .MuiListItem-root.active": {
      backgroundColor: "#F1BD8E",
    },
  },
  select: {
    padding: "0px 14px !important",
  },
}));
const getStepComponent = (step, setgstaff) => {
  switch (step) {
    case 0:
      return <Profile setgstaff={setgstaff} />;
    case 1:
      return <ChangePassword />;
    case 2:
      return <ActivityLog />;
    default:
      break;
  }
};
function StaffProfle() {
  const classes = useStyles();
  const [steps, setsteps] = useState(0);
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState({});
  const { id } = useParams();
  const handleImageChanges = async (e) => {
    const formdata = new FormData();
    formdata.append("image", e.target.files[0]);
    await axios
      .post(`${Config.SERVER_URL}/staff/update/image/${id}`, formdata)
      .then((res) => {
        setsuccess(true);
        setgstaff(res.data);
      })
      .catch((error) => {
        error.response &&
          error.response.status === 400 &&
          seterror(error.response.data);
      });
  };
  const [staffg, setgstaff] = useState();
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Box className="p-4">
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Card className={classes.card}>
              <CardContent className="p-0">
                {error &&
                  Object.keys(error).map((key) => {
                    return <Alert severity="error">{error[key]}</Alert>;
                  })}
                {success && <Alert severity="success">Profile Updated</Alert>}
                <Box
                  flexGrow={1}
                  alignContent="center"
                  className={classes.profile}
                >
                  <div className="d-flex justify-content-center py-2">
                    {staffg && (
                      <img
                        src={staffg?.image}
                        alt=""
                        width="200"
                        height="200"
                      />
                    )}
                  </div>
                  <label className="imageUpload" style={{ marginLeft: "34%" }}>
                    Upload Image
                    <input
                      type="file"
                      name="banner"
                      id="banner"
                      onChange={handleImageChanges}
                    />
                  </label>
                  <Typography component="h6" variant="h6" align="center">
                    {staffg?.first_name} {staffg?.last_name}
                  </Typography>
                </Box>
                <Divider />
                <List className={classes.list}>
                  <ListItem
                    className={steps === 0 ? "active" : ""}
                    onClick={() => {
                      setsteps(0);
                    }}
                  >
                    Staff Information
                  </ListItem>
                  <ListItem
                    className={steps === 1 ? "active" : ""}
                    onClick={() => {
                      setsteps(1);
                    }}
                  >
                    Change Password
                  </ListItem>
                  <ListItem
                    className={steps === 2 ? "active" : ""}
                    onClick={() => {
                      setsteps(2);
                    }}
                  >
                    Activity Log
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={9}>
            {getStepComponent(steps, setgstaff)}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
function Profile({ setgstaff }) {
  const classes = useStyles();
  const { getid } = useUserContext();
  const { register, handleSubmit } = useForm();
  const [staff, setstaff] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [userForm, setuserForm] = useState();
  useEffect(() => {
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}/staff/show/${getid()}`)
        .then((response) => {
          if (response.data) setstaff(response.data);
          setisLoading(false);
          setuserForm({
            first_name: response.data?.first_name,
            last_name: response.data?.last_name,
            phone: response.data?.phone,
            dob: response.data?.dob,
            email: response.data?.email,
            role: response.data?.role,
          });
          setgstaff(response.data);
        })
        .catch((error) => {
          setisLoading(false);
        });
    };
    init();
  }, [getid, setgstaff]);
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState({});
  const updateStaff = async () => {
    await axios
      .post(`${Config.SERVER_URL}/staff/update`, {
        id: getid(),
        ...userForm,
      })
      .then(() => {
        setsuccess(true);
        seterror({});
        setTimeout(() => {
          setsuccess(false);
        }, 3000);
      })
      .catch((error) => {
        error.response &&
          error.response.status === 400 &&
          seterror(error.response.data);
      });
  };
  const handleFormChange = (e) => {
    userForm[e.target.name] = e.target.value;
    setuserForm(userForm);
  };
  return (
    <Card className={classes.card}>
      <Typography component="h6" variant="h6" className="px-4 py-2">
        Personal Information
      </Typography>
      <Divider />
      {isLoading ? (
        <Typography>Please Wait while we fetch data</Typography>
      ) : (
        <>
          {success && (
            <Alert severity="success">Profile Updated successfully</Alert>
          )}
          {error &&
            Object.keys(error).map((key) => {
              return <Alert severity="error"> {error[key]}</Alert>;
            })}
          <form className={classes.form} onSubmit={handleSubmit(updateStaff)}>
            <Grid container spacing={4} className="px-4">
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <label htmlFor="">First Name</label>
                  <TextField
                    name="first_name"
                    color="primary"
                    variant="outlined"
                    size="small"
                    onChange={(e) => handleFormChange(e)}
                    defaultValue={staff?.first_name}
                  ></TextField>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <label htmlFor="">Last Name</label>
                  <TextField
                    name="last_name"
                    color="primary"
                    variant="outlined"
                    size="small"
                    onChange={(e) => handleFormChange(e)}
                    defaultValue={staff?.last_name}
                  ></TextField>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <label htmlFor="">Phone</label>
                  <TextField
                    name="phone"
                    color="primary"
                    variant="outlined"
                    onChange={(e) => handleFormChange(e)}
                    defaultValue={staff?.phone}
                    size="small"
                  ></TextField>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <label htmlFor="">Email Address</label>
                  <TextField
                    name="email"
                    color="primary"
                    variant="outlined"
                    type="email"
                    onChange={(e) => handleFormChange(e)}
                    defaultValue={staff?.email}
                    size="small"
                    inputRef={register({
                      required: "required",
                      pattern: {
                        value: /^\S+@\S+$/,
                        message: "Email does not match email pattern",
                      },
                    })}
                  ></TextField>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <label htmlFor="">Date of Birth</label>
                  <TextField
                    name="dob"
                    color="primary"
                    variant="outlined"
                    type="date"
                    onChange={(e) => handleFormChange(e)}
                    defaultValue={staff?.dob}
                    size="small"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container justify="flex-end">
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className="mx-4"
              >
                Save
              </Button>
            </Grid>
          </form>
        </>
      )}
    </Card>
  );
}
function ChangePassword() {
  const classes = useStyles();
  const [UserForm, setUserForm] = useState({
    password: "",
    confirm_password: "",
  });
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const { getid } = useUserContext();
  const updatePassword = async () => {
    await axios
      .post(`${Config.SERVER_URL}/staff/updatepassword/${getid()}`, {
        ...UserForm,
      })
      .then(() => {
        setsuccess(true);
        setTimeout(() => {
          setsuccess(false);
        }, 3000);
      })
      .catch((error) => {
        seterror(true);
        setTimeout(() => {
          seterror(false);
        }, 3000);
      });
  };
  const handlePasswordChange = async (e) => {
    UserForm[e.target.name] = e.target.value;
    setUserForm(UserForm);
  };
  return (
    <Card className={classes.card}>
      <Box flexGrow={1} className="justify-content-between p-2" display="flex">
        <Typography component="h4" variant="h6">
          Customer Orders
        </Typography>
      </Box>
      <Divider />
      <form className={classes.form} onSubmit={handleSubmit(updatePassword)}>
        {error && <Alert severity="error">Something Went Wrong</Alert>}
        {success && (
          <Alert severity="success">Password Updated Successfully</Alert>
        )}
        <Grid container spacing={4} className="px-4">
          <Grid item xs md={4}>
            <FormControl fullWidth>
              <label htmlFor="">New Password</label>
              <TextField
                name="password"
                color="primary"
                variant="outlined"
                size="small"
                type="password"
                onChange={(e) => handlePasswordChange(e)}
                inputRef={register({
                  required: "required",
                })}
              />
              <span className="error">{errors.password?.message}</span>
            </FormControl>
          </Grid>
          <Grid item xs md={4}>
            <FormControl fullWidth>
              <label htmlFor="">Confirm Password</label>
              <TextField
                name="confirm_password"
                color="primary"
                variant="outlined"
                type="password"
                size="small"
                onChange={(e) => handlePasswordChange(e)}
                inputRef={register({
                  required: "required",
                })}
              />
              <span className="error">{errors.confirm_password?.message}</span>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justify="flex-end">
          <Button
            name="Update"
            color="primary"
            variant="contained"
            className="mx-4"
            type="submit"
          >
            Update
          </Button>
        </Grid>
      </form>
    </Card>
  );
}

function ActivityLog() {
  const classes = useStyles();
  const columns = [
    {
      name: "Descriptions",
      selector: "Descriptions",
      sortable: true,
    },
    {
      name: "Date",
      selector: "Date",
      sortable: true,
      right: true,
    },
  ];
  const { getid } = useUserContext();
  const [activity, setactivity] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}/staff/activity`)
        .then((response) => {
          if (response.data) setactivity(response.data);
          setisLoading(false);
        })
        .catch((error) => {
          setisLoading(false);
        });
    };
    init();
  }, [getid]);
  return (
    <Card className={classes.card}>
      <Box flexGrow={1} className="justify-content-between p-2" display="flex">
        <Typography component="h4" variant="h6">
          Activity Log
        </Typography>
      </Box>
      <Divider />
      <DataTable
        columns={columns}
        data={activity}
        defaultSortField="title"
        SortIcon={<SortIcon />}
        pagination
        progressPending={isLoading}
      />
    </Card>
  );
}
export default StaffProfle;
