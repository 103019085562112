import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Chart from "./Chart";
import OrderIcon from "../../assets/icons/orders-colored.svg";
import ProductIcon from "../../assets/icons/products-colored.svg";
import VisitorIcon from "../../assets/icons/visitor-colored.svg";
import CustomerIcon from "../../assets/icons/customer-colored.svg";
import {
  Card,
  CardActions,
  CardContent,
  ListItem,
  LinearProgress,
} from "@material-ui/core";
import axios from "axios";
import Config from "../../config.json";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/Sort";
import { Link, Redirect } from "react-router-dom";
import { useUserContext } from "./../../Context/UserContext";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
    backgroundColor: "white",
    color: "black",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: 3,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 363,
  },
}));

function Dashboard() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [dashboard, setdashboard] = useState(null);
  const [first, setfirst] = useState();
  const { isLogined, isAdmin } = useUserContext();
  const [filter, setfilter] = useState("all");
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/dashboard?filter=${filter}`)
        .then((res) => {
          setdashboard(res.data);
          setfirst(res.data?.top ? res.data?.top[0]?.sale_count : 1);
        })
        .catch((error) => {});
    };
    init();
  }, [setfirst, filter]);
  if (!isLogined() || !isAdmin()) {
    <Redirect to="/login" />;
  }
  return (
    <>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="xl" className={classes.container}>
        <Grid container>
          <Grid item xs={2}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Filter
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={filter}
                onChange={(e) => setfilter(e.target.value)}
                label="Filter"
                fullWidth
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"daily"}>Daily</MenuItem>
                <MenuItem value={"weekly"}>Weekly</MenuItem>
                <MenuItem value={"monthly"}>Monthly</MenuItem>
                <MenuItem value={"year"}>Yearly</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography
                  className={classes.title}
                  gutterBottom
                  component="h6"
                  variant="h6"
                >
                  Orders
                </Typography>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={4}>
                    <img src={OrderIcon} alt="O" width="46" height="46" />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="h6"
                      component="h6"
                      className="text-center"
                    >
                      <strong>{dashboard?.totalorders}</strong>
                      <br />
                      {"Total"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      component="h6"
                      className="text-center"
                    >
                      {dashboard?.NewOrders}
                      <br />
                      {"New"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      component="h6"
                      className="text-center"
                    >
                      {dashboard?.returnedOrders}
                      <br />
                      {"Returned"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      component="h6"
                      className="text-center"
                    >
                      {dashboard?.canceledOrders}
                      <br />
                      {"Canceled"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography
                  className={classes.title}
                  gutterBottom
                  component="h6"
                  variant="h6"
                >
                  Products
                </Typography>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={4}>
                    <img src={ProductIcon} alt="p" width="46" height="46" />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="h6"
                      component="h6"
                      className="text-center"
                    >
                      <strong>{dashboard?.products}</strong>
                      <br />
                      {"Total"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>

              <Divider />
              <CardActions>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      component="h6"
                      className="text-center"
                    >
                      {dashboard?.sold}
                      <br />
                      {"Sold"}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="body2"
                      component="h6"
                      className="text-center"
                    >
                      {dashboard?.outofstock}
                      <br />
                      {"Out of Stock"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography
                  className={classes.title}
                  gutterBottom
                  component="h6"
                  variant="h6"
                >
                  Customers
                </Typography>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={4}>
                    <img src={CustomerIcon} alt="p" width="46" height="46" />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="h6"
                      component="h6"
                      className="text-center"
                    >
                      <strong>{dashboard?.customers}</strong>
                      <br />
                      {"Total"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      component="h6"
                      className="text-center"
                    >
                      {dashboard?.newCustomers}
                      <br />
                      {"New"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      component="h6"
                      className="text-center"
                    >
                      {dashboard?.returnedCustomers}
                      <br />
                      {"Returning"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography
                  className={classes.title}
                  gutterBottom
                  component="h6"
                  variant="h6"
                >
                  Visitors
                </Typography>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={4}>
                    <img src={VisitorIcon} alt="V" width="46" height="46" />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="h6"
                      component="h6"
                      className="text-center"
                    >
                      <strong>{dashboard?.vistors}</strong>
                      <br />
                      {"Total"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      component="h6"
                      className="text-center"
                    >
                      {dashboard?.pc}
                      <br />
                      {"Desktop"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      component="h6"
                      className="text-center"
                    >
                      {dashboard?.mobile}
                      <br />
                      {"Mobile"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      component="h6"
                      className="text-center"
                    >
                      {dashboard?.tablet}
                      <br />
                      {"Tablet"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={9}>
            <Paper className={fixedHeightPaper}>
              <Typography component="h6" variant="h5" className="mb-3">
                Revenue
              </Typography>
              <Chart data={dashboard?.chart} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Card variant="outlined" className="h-100">
              <CardContent>
                <Typography
                  className={classes.title}
                  gutterBottom
                  component="h5"
                  variant="h5"
                >
                  Top Categories
                </Typography>

                <Typography
                  className={classes.title}
                  gutterBottom
                  component="p"
                  variant="subtitle2"
                >
                  By Orders
                </Typography>

                <List>
                  {dashboard?.top &&
                    Object.keys(dashboard.top).length > 0 &&
                    dashboard.top?.map((t, index) => {
                      if (index === 0) {
                        return (
                          <ListItem className="w-100 d-block" key={index}>
                            <p className="bg-main w-100 m-0">
                              {t?.category_name}
                            </p>
                            <LinearProgress variant="determinate" value={100} />
                          </ListItem>
                        );
                      } else {
                        return (
                          <ListItem className="w-100 d-block" key={index}>
                            <p className="bg-main w-100 m-0">
                              {t?.category_name}
                            </p>
                            <LinearProgress
                              variant="determinate"
                              value={(t.sale_count / first) * 100}
                            />
                          </ListItem>
                        );
                      }
                    })}
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="xl" className={classes.container}>
        <NewOrders />
      </Container>
    </>
  );
}
function NewOrders() {
  const columns = [
    {
      name: "Order-ID",
      selector: "id",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <Link
            to={`/orders/details/${row?.id}`}
            style={{ fontWeight: "bold" }}
          >
            {row?.id}
          </Link>
        </div>
      ),
    },
    {
      name: "Customer",
      selector: "customer_id",
      sortable: true,
      cell: (row) => row?.customer?.first_name,
    },
    {
      name: "Delivery Address",
      selector: "address",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    {
      name: "Dates",
      selector: "created_at",
      sortable: true,
    },
    {
      name: "Total",
      selector: "total",
      sortable: true,
    },
    {
      name: "Sub Total",
      selector: "sub_total",
      sortable: true,
    },
  ];
  const [pagenumber, setpagenumber] = useState(1);
  const [data, setdata] = useState(null);
  useEffect(() => {
    const init = async () => {
      await axios
        .all([axios.get(`${Config.SERVER_URL}/order/1?page=${pagenumber}`)])
        .then(
          axios.spread((response) => {
            setrows(response.data.data);
            setdata(response.data);
          })
        )
        .catch((error) => {});
    };
    init();
  }, [pagenumber]);
  const [rows, setrows] = useState([]);

  return (
    <Paper className="p-4">
      <Typography component="h5" variant="h6" className="pb-2">
        New Orders
      </Typography>
      <Divider />
      <DataTable
        columns={columns}
        data={rows}
        defaultSortField="title"
        SortIcon={<SortIcon />}
        pagination
        paginationPerPage={10}
        paginationServer={true}
        paginationTotalRows={data?.total}
        onChangePage={(e) => setpagenumber(e)}
      />
    </Paper>
  );
}
export default Dashboard;
