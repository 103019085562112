import React, { useState, useEffect } from "react";
import { Box, Container, Paper, Switch } from "@material-ui/core";
import {
  Grid,
  FormControl,
  TextField,
  MenuItem,
  TextareaAutosize,
  Button,
  Select,
  Chip,
  ButtonGroup,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Config from "../../../config.json";
import { Alert } from "@material-ui/lab";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useUserContext } from "./../../../Context/UserContext";

const options = [
  { value: "small", label: "Small" },
  { value: "medium", label: "Medium" },
  { value: "large", label: "Large" },
  { value: "extralarge", label: "Extra Large" },
];

function Edit() {
  const [sizes, setsizes] = useState([]);
  const [color, setcolors] = useState(null);
  const [categories, setcategories] = useState(null);
  const [brands, setbrands] = useState(null);

  const [, setproduct] = useState(null);
  const [productForm, setproductForm] = useState({});
  const { id } = useParams();
  const { getuser } = useUserContext();
  const [admin] = useState(getuser());
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/products/details/${id}`)
        .then((res) => {
          setproduct(res.data);
          setproductForm({
            product_name: res.data?.product_name,
            product_arabic_name: res.data?.product_arabic_name,
            category_id: res.data?.category_id,
            brand_id: res.data?.brand_id,
            product_description: res.data?.product_description,
            product_arabic_description: res.data?.product_arabic_description,
            reward_points: res.data?.reward_points,
            discounted_amount: res.data?.discounted_amount,
            cost_price: res.data?.cost_price,
            model: res.data?.model,
            quantity: res.data?.quantity,
            sku: res.data?.sku,
            vat: res.data?.vat === 1 || res.data?.vat === "1" ? true : false,
            weight: res.data?.weight,
            shipping: res.data?.shipping,
            length: res.data?.length,
            width: res.data?.width,
            height: res.data?.height,
            tax: res.data?.tax,
            price: res.data?.price,
            dimension_unit: res.data?.dimension_unit,
            bar_code: res.data?.barcode,
            featured:
              res.data?.featured === 1 || res.data?.featured === "1"
                ? true
                : false,
            is_bundle:
              res.data?.is_bundle === 1 || res.data?.is_bundle === "1"
                ? true
                : false,
          });
          setImages(res.data.productimages);
          setsizes(res.data.sizes);
          setcolors(res.data.colors);
        })
        .catch((error) => {});

      // await axios.get(`${Config.SERVER_URL}/product/color/${id}`).then(res => {
      // }).catch(error => {
      //
      // })
      await axios
        .get(`${Config.SERVER_URL}/category/all`)
        .then((res) => {
          setcategories(res.data);
        })
        .catch((error) => {});
      await axios
        .get(`${Config.SERVER_URL}/brands/all`)
        .then((res) => {
          setbrands(res.data);
        })
        .catch((error) => {});
    };
    init();
  }, [id, setsizes, setcolors, setproduct]);

  // eslint-disable-next-line
  const [dummystate, setdummystate] = useState(false);
  const [Images, setImages] = useState([]);
  const DeleteImage = async (imageid) => {
    await axios
      .get(`${Config.SERVER_URL}/product/delete/image/${id}/${imageid}`)
      .then((res) => {
        setImages(res.data);
      })
      .catch((error) => {
        error.response &&
          error.response.status === 400 &&
          seterrors(error.response.data);
      });
  };
  const handleImageChanges = async (e) => {
    const formData = new FormData();
    formData.append(`product_image`, e.target.files[0]);
    await axios
      .post(`${Config.SERVER_URL}/product/upload/image/${id}`, formData)
      .then((res) => {
        setImages(res.data);
      })
      .catch((error) => {
        error.response &&
          error.response.status === 400 &&
          seterrors(error.response.data);
      });
  };
  const handleChange = (e) => {
    setproductForm({ ...productForm, [e.target.name]: e.target.value });
  };

  const handleSize = async (e) => {
    await axios
      .post(`${Config.SERVER_URL}/product/upload/size/${id}`, {
        size: e.target.value,
      })
      .then((res) => {
        setsizes(res.data);
      })
      .catch((error) => {
        error.response &&
          error.response.status === 400 &&
          seterrors(error.response.data);
      });
  };
  const handleSizedelete = async (sizeid) => {
    await axios
      .post(`${Config.SERVER_URL}/product/delete/size/${id}/${sizeid}`)
      .then((res) => {
        setsizes(res.data);
      })
      .catch((error) => {
        error.response &&
          error.response.status === 400 &&
          seterrors(error.response.data);
      });
  };
  const handleColordelete = async (colorid) => {
    await axios
      .post(`${Config.SERVER_URL}/product/delete/color/${id}/${colorid}`)
      .then((res) => {
        setcolors(res.data);
      })
      .catch((error) => {
        error.response &&
          error.response.status === 400 &&
          seterrors(error.response.data);
      });
  };
  const AddColor = async () => {
    if (
      currentcolor?.color_english.length > 0 &&
      currentcolor?.color_arabic.length > 0
    ) {
      await axios
        .post(`${Config.SERVER_URL}/product/upload/color/${id}`, {
          color_english: currentcolor?.color_english,
          color_arabic: currentcolor?.color_arabic,
        })
        .then((res) => {
          setcolors(res.data);
        })
        .catch((error) => {
          error.response &&
            error.response.status === 400 &&
            seterrors(error.response.data);
        });
    } else {
      setcolorerror(true);
      setTimeout(() => {
        setcolorerror(false);
      }, 3000);
    }
  };
  const [currentcolor, setcurrentcolor] = useState({
    color_english: "",
    color_arabic: "",
  });
  const [success, setsuccess] = useState(false);
  const [errors, seterrors] = useState(null);
  const location = useHistory();
  const AddProduct = async () => {
    const formData = new FormData();
    formData.append("product_name", productForm.product_name);
    formData.append("product_arabic_name", productForm.product_arabic_name);
    formData.append("id", id);
    formData.append("category_id", productForm.category_id);
    formData.append("brand_id", productForm.brand_id);
    formData.append("product_description", productForm.product_description);
    formData.append(
      "product_arabic_description",
      productForm.product_arabic_description
    );
    formData.append("reward_points", productForm.reward_points);
    formData.append("discounted_amount", productForm.discounted_amount);
    formData.append("related_products", productForm.related_products);
    formData.append("cost_price", productForm.cost_price);
    formData.append("model", productForm.model);
    formData.append("quantity", productForm.quantity);
    formData.append("sku", productForm.sku);
    formData.append("vat", productForm.vat);
    formData.append("weight", productForm.weight);
    formData.append("shipping", productForm.shipping);
    formData.append("length", productForm.length);
    formData.append("width", productForm.width);
    formData.append("height", productForm.height);
    formData.append("tax", productForm.tax);
    formData.append("price", productForm.price);
    formData.append("size", JSON.stringify(sizes));
    formData.append("color", JSON.stringify(color));
    formData.append("featured", productForm.featured);
    formData.append("barcode", productForm.bar_code);
    formData.append("is_bundle", productForm.is_bundle);
    formData.append("admin_id", admin?.id);
    await axios
      .post(`${Config.SERVER_URL}/product/update`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setsuccess(true);
        setTimeout(() => {
          location.push("/products");
        }, 1000);
      })
      .catch((error) => {
        if (error.response.status === 400) seterrors(error.response.data);
      });
  };
  const handleColor = (e) => {
    setcurrentcolor({ ...currentcolor, [e.target.name]: e.target.value });
  };
  const [colorerror, setcolorerror] = useState(false);
  const [isEnglish, setisEnglish] = useState(true);
  return (
    <>
      <Paper className="px-4">
        {errors &&
          Object.keys(errors).map((key) => (
            <Alert severity="error" className="m-1">
              {errors[key]}
            </Alert>
          ))}
        {success && (
          <Alert severity="success">
            Product added You will be redirected in 3 secs
          </Alert>
        )}
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Box display="flex" justifyContent="space-between">
                    <label htmlFor="">Product Name</label>
                    <ButtonGroup
                      size="small"
                      aria-label="small text primary button group"
                      disableElevation
                    >
                      <Button
                        onClick={() => {
                          setisEnglish(true);
                          setdummystate(dummystate);
                        }}
                        variant={isEnglish ? "outlined" : "contained"}
                        color={isEnglish ? "primary" : ""}
                      >
                        English
                      </Button>
                      <Button
                        onClick={() => {
                          setisEnglish(false);
                          setdummystate(dummystate);
                        }}
                        variant={isEnglish ? "contained" : "outlined"}
                        color={isEnglish ? "" : "primary"}
                      >
                        Arabic
                      </Button>
                    </ButtonGroup>
                  </Box>
                  <TextField
                    name="product_name"
                    id="ProductName"
                    variant="outlined"
                    color="primary"
                    size="small"
                    placeholder="English Name"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    margin="none"
                    className={`m-0 ${isEnglish ? "" : "d-none"}`}
                    value={productForm?.product_name}
                  ></TextField>
                  <TextField
                    name="product_arabic_name"
                    id="arabic_name"
                    variant="outlined"
                    color="primary"
                    size="small"
                    placeholder="Arabic Name"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    margin="none"
                    className={`m-0 ${isEnglish ? "d-none" : ""}`}
                    value={productForm?.product_arabic_name}
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {productForm?.category_id && (
                  <FormControl fullWidth>
                    <label htmlFor="">Category</label>
                    <Select
                      color="primary"
                      variant="outlined"
                      name="category_id"
                      value={productForm?.category_id}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      {categories &&
                        categories?.map((cat, index) => {
                          return (
                            <MenuItem value={cat?.id} key={index}>
                              {cat?.category_name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={6}>
                {productForm?.brand_id && (
                  <FormControl fullWidth>
                    <label htmlFor="">Brand</label>
                    <Select
                      color="primary"
                      variant="outlined"
                      name="brand_id"
                      value={productForm?.brand_id}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      {brands &&
                        brands?.map((cat, index) => {
                          return (
                            <MenuItem value={cat?.id} key={index}>
                              {cat?.brand_name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="">Description</label>
                  <TextareaAutosize
                    name="product_description"
                    defaultValue={productForm?.product_description}
                    rowsMin="10"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    className={`${isEnglish ? "" : "d-none"} w-100`}
                  ></TextareaAutosize>
                  <TextareaAutosize
                    name="product_arabic_description"
                    rowsMin="10"
                    placeholder="Arabic Description"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={productForm?.product_arabic_description}
                    className={`${isEnglish ? "d-none" : ""} w-100`}
                  ></TextareaAutosize>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <label htmlFor="">Price</label>
                  <TextField
                    name="price"
                    id="Price"
                    variant="outlined"
                    color="primary"
                    size="small"
                    type="number"
                    value={productForm?.price}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <label htmlFor="">Reward Points</label>
                  <TextField
                    name="reward_points"
                    id="RewardPoints"
                    variant="outlined"
                    color="primary"
                    size="small"
                    type="number"
                    value={productForm?.reward_points}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <label htmlFor="">Quantity</label>
                  <TextField
                    name="quantity"
                    id="RewardPoints"
                    variant="outlined"
                    color="primary"
                    size="small"
                    value={productForm?.quantity}
                    type="number"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <label>Feature Product</label>
                <Switch
                  checked={productForm.featured ? true : false}
                  color="primary"
                  name="featured"
                  onChange={(e) => {
                    productForm["featured"] = e.target.checked;
                    setproductForm(productForm);
                    setdummystate(!dummystate);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <label>Present as Bundle Offer</label>
                <Switch
                  checked={productForm.is_bundle ? true : false}
                  color="primary"
                  name="is_bundle"
                  onChange={(e) => {
                    productForm["is_bundle"] = e.target.checked;
                    setproductForm(productForm);
                    setdummystate(!dummystate);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </FormControl>
            </Grid>
            <Typography component="h6" variant="h6">
              Product Images
            </Typography>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography component="p">Upload multiple images</Typography>
              <label className="imageUpload">
                Upload Image
                <input
                  type="file"
                  name="images"
                  id="images"
                  multiple
                  className="d-none"
                  onChange={handleImageChanges}
                />
              </label>
            </Box>
            <Grid container spacing={4} className="mt-3 px-3">
              {Images &&
                Object.keys(Images).length > 0 &&
                Images.map((image, index) => {
                  return (
                    <Grid xs={6} md={3} item className="px-3" key={index}>
                      <DeleteForeverIcon
                        onClick={() => DeleteImage(image?.id)}
                        className="ml-auto text-danger"
                      />
                      <img
                        src={image?.picture_reference}
                        className=" auto"
                        alt=""
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={3}>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <label>Charge VAT on product</label>
                <Switch
                  checked={productForm?.vat ? true : false}
                  color="primary"
                  name="vat"
                  onChange={(e) => {
                    productForm["vat"] = e.target.checked;
                    setproductForm(productForm);
                    setdummystate(!dummystate);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* Shipping */}
      <Paper className="p-4 my-5">
        <Typography component="p" variant="body2">
          Shipping
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label>Requires Shipping</label>
              <Switch
                checked={productForm.shipping}
                color="primary"
                name="shipping"
                onChange={(e) => {
                  productForm["shipping"] = e.target.checked;
                  setproductForm(productForm);
                  setdummystate(!dummystate);
                }}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={4}
            className="mx-2"
            style={{ display: productForm.shipping ? "" : "none" }}
          >
            <label>Weight </label>
            <FormControl fullWidth>
              <Grid container>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="weight"
                    type="number"
                    placeholder="Weight"
                    onChange={(e) => handleChange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={3} container alignItems="center">
                  <Select
                    color="primary"
                    variant="outlined"
                    name="weight_unit"
                    fullWidth
                    onChange={(e) => handleChange(e)}
                  >
                    <MenuItem value="g" selected>
                      Gram
                    </MenuItem>
                    <MenuItem value="kg">KG</MenuItem>
                    <MenuItem value="lb">LB</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>

          <Grid
            item
            xs
            className="mx-2"
            style={{ display: productForm.shipping ? "" : "none" }}
          >
            <label>Dimensions (L x W x H)</label>
            <FormControl fullWidth>
              <Grid container>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="length"
                    type="number"
                    placeholder="L"
                    onChange={(e) => handleChange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="width"
                    type="number"
                    placeholder="W"
                    onChange={(e) => handleChange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="height"
                    type="number"
                    placeholder="H"
                    onChange={(e) => handleChange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={3} container alignItems="center">
                  <Select
                    color="primary"
                    variant="outlined"
                    name="dimension_unit"
                    onChange={(e) => handleChange(e)}
                    fullWidth
                  >
                    <MenuItem value="mm" selected>
                      MiliMeter
                    </MenuItem>
                    <MenuItem value="cm">Centimeter</MenuItem>
                    <MenuItem value="m">Meter</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      <Paper className="p-4 my-5">
        <Grid container>
          <Grid item xs={12}>
            <Typography component="h6" variant="h6">
              Additional Options
            </Typography>
            <p>select multiple options for product or add a custom option</p>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <label> Size</label>
                <Select
                  name="sizes"
                  color="primary"
                  variant="outlined"
                  onChange={handleSize}
                  fullWidth
                >
                  <MenuItem selected>Select Size</MenuItem>
                  {options &&
                    options?.map((op, index) => {
                      return (
                        <MenuItem value={op.value} key={index}>
                          {op.label}
                        </MenuItem>
                      );
                    })}
                </Select>
                {sizes &&
                  sizes.map((s, index) => {
                    return (
                      <Chip
                        label={s?.size}
                        className="m-1"
                        onDelete={() => handleSizedelete(s?.id)}
                        key={index}
                      />
                    );
                  })}
              </Grid>
              <Grid item xs={6} md={4}>
                <label> Color</label>
                {colorerror && (
                  <Alert severity="error">Both fields for color required</Alert>
                )}
                <Box display="flex" alignItems="center" className="mb-2">
                  <FormControl fullWidth>
                    <label htmlFor="">English</label>
                    <TextField
                      name="color_english"
                      id="RewardPoints"
                      variant="outlined"
                      color="primary"
                      size="small"
                      placeholder="English Name"
                      onChange={(e) => {
                        handleColor(e);
                      }}
                      value={currentcolor?.color_english}
                    ></TextField>
                  </FormControl>
                  <FormControl fullWidth>
                    <label htmlFor="">Arabic</label>
                    <TextField
                      name="color_arabic"
                      variant="outlined"
                      color="primary"
                      size="small"
                      placeholder="Arabic Name"
                      onChange={(e) => {
                        handleColor(e);
                      }}
                      value={currentcolor?.color_arabic}
                    ></TextField>
                  </FormControl>
                  <Button
                    className="text-main w-100"
                    onClick={AddColor}
                    variant="text"
                    disableRipple
                    disableElevation
                  >
                    Add Color+
                  </Button>
                </Box>
                <Grid item xs={12} lg={4}>
                  {color &&
                    color?.map((s, index) => {
                      return (
                        <Chip
                          label={`${s?.color_english} - ${s?.color_arabic}`}
                          className="m-1"
                          onDelete={() => handleColordelete(s?.id)}
                          key={index}
                        />
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Container maxWidth="xl" className="mt-3">
        <Paper className="p-4">
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <label htmlFor="">Cost Price</label>
                <TextField
                  name="cost_price"
                  id="CostPrice"
                  variant="outlined"
                  color="primary"
                  size="small"
                  onChange={handleChange}
                  value={productForm?.cost_price}
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <label htmlFor="">Tax</label>
                <TextField
                  name="tax"
                  id="Tax"
                  variant="outlined"
                  color="primary"
                  size="small"
                  onChange={handleChange}
                  value={productForm?.tax}
                ></TextField>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <label htmlFor="">SKU</label>
                <TextField
                  name="sku"
                  id="SKU"
                  variant="outlined"
                  color="primary"
                  size="small"
                  onChange={handleChange}
                  value={productForm?.sku}
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <label htmlFor="">Model</label>
                <TextField
                  name="model"
                  id="Model"
                  variant="outlined"
                  color="primary"
                  size="small"
                  onChange={handleChange}
                  value={productForm?.model}
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <label htmlFor="">Bar Code</label>
                <TextField
                  name="bar_code"
                  id="BarCode"
                  variant="outlined"
                  color="primary"
                  size="small"
                  onChange={handleChange}
                  value={productForm?.bar_code}
                ></TextField>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} container justify="flex-end">
            <Button onClick={AddProduct} color="primary" variant="contained">
              Save
            </Button>
          </Grid>
        </Paper>
      </Container>
    </>
  );
}

export default Edit;
