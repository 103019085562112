import { useUserContext } from './../../../Context/UserContext';
import React, { useEffect, useState } from 'react'
import { Card } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import SortIcon from '@material-ui/icons/Sort';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios'
import Config from '../../../config.json'
const useStyles = makeStyles(() => ({
  card: {
    minHeight: 650,
  },
  profile: {
    marginTop: "27px",
    padding: "16px",
  },
  list: {
    "& .MuiListItem-root": {
      paddingTop: "19px",
      paddingBottom: "19px",
      cursor: "pointer",
    },
    "& .MuiListItem-root.active": {
      backgroundColor: "#F1BD8E",
    },
  },
  select: {
    padding: "0px 14px !important",
  },


}));
function ActivityLog() {
  const classes = useStyles();
  const columns = [
    {
      name: "Descriptions",
      selector: "activity",
      sortable: true,
    },
    {
      name: "Date",
      selector: "created_at",
      sortable: true,
      right: true,
    },
  ];
  const [activity, setactivity] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const {getuser} = useUserContext()
  const [user] = useState(getuser());
  useEffect(() => {
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}/activity/${user?.id}`)
        .then((response) => {
          setactivity(response.data);
          setisLoading(false);
        })
        .catch((error) => {
          setisLoading(false);
        });
    };
    init()
  }, [user]);
  return (
    <Card className={classes.card}>
      <Box flexGrow={1} className="justify-content-between p-2" display="flex">
        <Typography component="h4" variant="h6">
          Activity Log
          </Typography>
      </Box>
      <Divider />
      <DataTable
        columns={columns}
        data={activity}
        defaultSortField="title"
        SortIcon={<SortIcon />}
        progressPending={isLoading}
        pagination
      />
    </Card>
  );
}

export default ActivityLog
