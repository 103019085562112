import React, { useState, useEffect } from "react";
import {
  Container,
  Breadcrumbs,
  Paper,
  Typography,
  Divider,
  Box,
  TextareaAutosize,
  Button,
  Avatar,
  Grid,
  Chip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { makeStyles } from "@material-ui/core/styles";
import UserCircle from "../../assets/icons/user-circle.svg";
import axios from "axios";
import Config from "../../config.json";
import Alert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
function QuestionAndAnswer() {
  const [queAndAns, setqueAndAns] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [data, setdata] = useState(null);
  const [pagenumber, setpagenumber] = useState(1);
  useEffect(() => {
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}/qa/index?page=${pagenumber}`)
        .then((response) => {
          if (Object.keys(response.data).length > 0)
            setqueAndAns(response.data.data);
          setdata(response.data);
          setisLoading(false);
        })
        .catch((error) => {
          setisLoading(false);
        });
    };
    init();
  }, [pagenumber]);

  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h6" variant="h5">
        Questions & Answer
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/" disabled>
          Questions And Answer
        </Link>
      </Breadcrumbs>
      {isLoading ? (
        <>
          <Typography variant="h6" component="h6" align="center">
            Loading
          </Typography>
        </>
      ) : (
        <>
          {!!queAndAns &&
            queAndAns?.map((qa, index) => {
              return (
                <QA
                  data={qa}
                  key={index}
                  updateparent={(data) => setqueAndAns(data)}
                />
              );
            })}
          {!!queAndAns && (
            <Grid container item xs={12} justify="flex-end">
              <Pagination
                count={parseInt(data?.total / 12 + 1)}
                page={pagenumber}
                showFirstButton
                showLastButton
                onChange={(e, val) => setpagenumber(val)}
              />
            </Grid>
          )}
        </>
      )}
    </Container>
  );
}
const QAStyles = makeStyles((theme) => ({
  root: {
    minHeight: 300,
    "& .header": {
      minHeight: "60px",
    },
  },
}));
function QA({ data, updateparent }) {
  const [reply, setreply] = useState("");
  const [enableEditing, setenableEditing] = useState(false);
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState(false);
  const classes = QAStyles();
  const handleReply = async () => {
    await axios
      .post(`${Config.SERVER_URL}/qa/update/${data.id}`, {
        answer: reply,
      })
      .then((response) => {
        setsuccess(true);
        if (Object.keys(response.data).length > 0)
          updateparent(response.data.data);
        setenableEditing(false);
        setTimeout(() => {
          setsuccess(false);
        }, 3000);
      })
      .catch((error) => {
        seterror(true);
        setTimeout(() => {
          seterror(false);
        }, 3000);
      });
  };
  const deleteReply = async (id) => {
    await axios
      .post(`${Config.SERVER_URL}/qa/delete/${id}`)
      .then((response) => {
        if (response.data) updateparent(response.data.data);
      })
      .catch((error) => {
        seterror(true);
        setTimeout(() => {
          seterror(false);
        }, 3000);
      });
  };
  return (
    <Paper className={`p-4 mb-5  ${classes.root}`}>
      <div className="d-flex justify-content-between header">
        <Typography component="h6" variant="h6">
          {data?.product?.product_name}
        </Typography>
        <div className="d-flex">
          <p
            className="px-2 text-main cursor"
            onClick={() => deleteReply(data?.id)}
          >
            Delete
          </p>
          <p
            onClick={() => {
              setenableEditing(true);
            }}
            className="text-main cursor"
          >
            Reply
          </p>
        </div>
      </div>
      <Divider />
      {error && (
        <Alert severity="error">Something went wrong while postin reply</Alert>
      )}
      {success && <Alert severity="success">Reply uploaded successfully</Alert>}
      {data?.answer ? (
        ""
      ) : (
        <Chip
          variant="outlined"
          color="primary"
          size="small"
          label="Reply Required"
          className="my-2"
        />
      )}
      <Box display="flex" alignItems="center" className="mb-2" p={2}>
        <Avatar alt="Remy Sharp" src={UserCircle} />
        <p className="font-weight-bold px-3 pt-2">
          {data?.customer?.first_name} {data?.customer?.last_name}
        </p>
      </Box>
      <Grid container item xs>
        <Box className="my-3">
          <p>{data?.question}</p>
        </Box>
      </Grid>
      <Grid container item xs justify="flex-end">
        <Box className="my-3">
          <p className="text-end">{data?.answer}</p>
        </Box>
      </Grid>
      {enableEditing ? (
        <>
          <TextareaAutosize
            name="Reply"
            id="Reply"
            rowsMin="5"
            className="w-100"
            color="primary"
            varaint="contained"
            onChange={(e) => setreply(e.target.value)}
          />
          <div className="d-flex justify-content-end align-items-center m-2">
            <Button
              color="primary"
              variant="contained"
              className="m-1"
              onClick={() => setenableEditing(false)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="m-1"
              onClick={() => handleReply()}
            >
              Send
            </Button>
          </div>
        </>
      ) : (
        <></>
      )}
    </Paper>
  );
}
export default QuestionAndAnswer;
