import React, { useState, useEffect } from "react";
import { Box, FormControl, Grid, TextField } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Link } from "react-router-dom";
import { Container } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/Sort";
import axios from "axios";
import Config from "../../config.json";
function CustomersReport() {
  const [Filters, setFilters] = useState({
    NoOfOrder: "",
    Date: "",
    From: "",
    To: "",
  });
  const [EnableFilters, setEnableFilters] = useState(false);
  const handleFilterChange = (e) => {
    let TempFilters = Filters;
    TempFilters[e.target.name] = e.target.value;
    setFilters(TempFilters);
  };
  const columns = [
    {
      name: "Customer Name",
      sortable: true,
      cell: (row) => (
        <Link to={`/customers/details/${row.id}`}>
          {row.first_name} {row.last_name}
        </Link>
      ),
    },
    {
      name: "Email Address",
      selector: "email",
      sortable: true,
    },
    {
      name: "Phone",
      selector: "phone",
      sortable: true,
    },
    {
      name: "Created Date",
      sortable: true,
      cell: (row) => row.created_at,
    },
    {
      name: "No of Orders",
      selector: "orders_count",
      sortable: true,
    },
  ];
  const [customers, setcustomers] = useState([]);
  const [pagenumber, setpagenumber] = useState(1);
  const [data, setdata] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}/customers?page=${pagenumber}`)
        .then((response) => {
          setisLoading(false);
          if (response.data) setcustomers(response.data.data);
          setdata(response.data);
        })
        .catch((error) => {
          setisLoading(false);
        });
    };
    init();
  }, [pagenumber]);
  const HandleFilters = async () => {
    setisLoading(true);
    await axios
      .get(
        `${Config.SERVER_URL}/customers?from=${Filters.From}&to=${Filters.To}&orders=${Filters.NoOfOrder}`
      )
      .then((response) => {
        if (response.data) {
          setcustomers(response.data.data);
          setdata(response.data);
          setisLoading(false);
        }
      })
      .catch((error) => {
        setisLoading(false);
      });
  };
  return (
    <Container maxWidth="xl">
      <Box>
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={6} sm={3}>
            <IconButton
              aria-label="delete"
              color="default"
              className="filters"
              onClick={() => setEnableFilters(!EnableFilters)}
            >
              Filters
              <ArrowDropDownIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box p={4} className={EnableFilters ? "Filters-Show" : "Filters-Hide"}>
        <Grid container spacing={4}>
          <Grid item md={3}>
            <p className="m-0">Dates</p>
            <Grid container spacing={2}>
              <Grid item xs md={6}>
                <label htmlFor="" className="m-0">
                  From
                </label>
                <TextField
                  variant="outlined"
                  name="From"
                  margin="none"
                  onChange={(e) => handleFilterChange(e)}
                  size="small"
                  type="date"
                ></TextField>
              </Grid>
              <Grid item xs md={6}>
                <label htmlFor="" className="m-0">
                  To
                </label>
                <TextField
                  variant="outlined"
                  name="To"
                  onChange={(e) => handleFilterChange(e)}
                  size="small"
                  type="date"
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3}>
            <FormControl fullWidth>
              <label className="pb-2">No. of Orders</label>
              <TextField
                name="NoOfOrder"
                id="NoOfOrder"
                color="primary"
                variant="outlined"
                size="small"
                placeholder="Type Here"
                onChange={(e) => {
                  handleFilterChange(e);
                }}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} className="d-flex justify-content-end">
            <Button color="primary" variant="outlined" onClick={HandleFilters}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataTable
          columns={columns}
          data={customers}
          defaultSortField="title"
          SortIcon={<SortIcon />}
          progressPending={isLoading}
          pagination
          paginationPerPage={10}
          paginationServer={true}
          paginationTotalRows={data?.total}
          onChangePage={(e) => setpagenumber(e)}
        />
      </Box>
    </Container>
  );
}

export default CustomersReport;
