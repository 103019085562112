import {
  Paper,
  Grid,
  Box,
  FormControl,
  TextField,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Config from "../../config.json";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Typography from "@material-ui/core/Typography";
function FAQ() {
  const [FAQs, setFAQs] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}/faqs`)
        .then((response) => {
          setisLoading(false);
          if (response.data.length > 0) setFAQs(response.data);
        })
        .catch((error) => {
          setisLoading(false);
        });
    };
    init();
  }, []);
  const [QuestionAnser, setQuestionAnser] = useState(null);
  const handleSubmit = async () => {
    await axios
      .post(`${Config.SERVER_URL}/faqs/create`, QuestionAnser)
      .then((response) => {
        setFAQs(response.data);
      })
      .catch((error) => {});
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Paper className="p-3">
          {isLoading && (
            <Typography>Please Wait while we fetch FAQs</Typography>
          )}
          {FAQs &&
            FAQs?.map((f) => {
              return <QA data={f} updateparent={(data) => setFAQs(data)} />;
            })}
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className="p-2">
          <Box p={2}>
            <FormControl fullWidth>
              <label htmlFor="">Question</label>
              <TextField
                name="question"
                color="primary"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setQuestionAnser({
                    ...QuestionAnser,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <label htmlFor="">Answer</label>
              <TextField
                name="answer"
                color="primary"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setQuestionAnser({
                    ...QuestionAnser,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
function QA({ data, updateparent }) {
  const [enableEditing, setenableEditing] = useState(false);
  const [updatedQuestionAnswer, setupdatedQuestionAnswer] = useState({
    question: data?.question,
    answer: data?.answer,
  });
  const handleChange = (e) => {
    updatedQuestionAnswer[e.target.name] = e.target.value;
    setupdatedQuestionAnswer(updatedQuestionAnswer);
  };
  const handleUpdate = async () => {
    setenableEditing(false);
    await axios
      .post(`${Config.SERVER_URL}/faqs/update/${data?.id}`, {
        ...updatedQuestionAnswer,
      })
      .then((response) => {
        if (response.data) updateparent(response.data);
      })
      .catch((error) => {});
  };
  const handleDelete = async () => {
    await axios
      .post(`${Config.SERVER_URL}/faqs/delete/${data?.id}`)
      .then((response) => {
        if (response.data) updateparent(response.data);
      })
      .catch((error) => {});
  };
  return (
    <Paper elevation={1} className="px-4 my-3">
      <div className="d-flex justify-content-end p-2">
        {enableEditing ? (
          <>
            <p onClick={handleUpdate} className="px-2 text-main cursor">
              Save
            </p>
            <p
              onClick={() => {
                setenableEditing(!enableEditing);
              }}
              className="text-main cursor"
            >
              Cancel
            </p>
          </>
        ) : (
          <>
            <EditIcon
              onClick={() => {
                setenableEditing(!enableEditing);
              }}
              className="cursor"
            />
            <DeleteForeverIcon
              style={{ color: "red" }}
              className="cursor"
              onClick={handleDelete}
            />
          </>
        )}
      </div>
      {enableEditing ? (
        <div>
          <Box p={3}>
            <Typography component="h6" variant="h6">
              Question
            </Typography>
            <TextField
              color="primary"
              variant="outlined"
              name="question"
              defaultValue={data.question}
              onChange={(e) => {
                handleChange(e);
              }}
              size="small"
              fullWidth
            ></TextField>
          </Box>
          <Box p={3}>
            <Typography component="h6" variant="h6">
              Answer
            </Typography>
            <TextField
              color="primary"
              variant="outlined"
              name="answer"
              defaultValue={data.answer}
              onChange={(e) => {
                handleChange(e);
              }}
              size="small"
              fullWidth
            ></TextField>
          </Box>
        </div>
      ) : (
        <div>
          <Box p={3}>
            <Typography component="h6" variant="h6">
              Question
            </Typography>
            <Typography component="h6" variant="body2">
              {data?.question}
            </Typography>
          </Box>
          <Box p={3}>
            <Typography component="h6" variant="h6">
              Answer
            </Typography>
            <Typography component="h6" variant="body2">
              {data?.answer}
            </Typography>
          </Box>
        </div>
      )}
    </Paper>
  );
}
export default FAQ;
