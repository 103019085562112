import React, { useState } from "react";
import {
  FormControl,
  Paper,
  Typography,
  Grid,
  TextField,
  Container,
  Button,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import axios from "axios";
import Config from "../../../config.json";
import { Alert } from "@material-ui/lab";
export default function AddVocuher() {
  const [data, setdata] = useState({
    voucher_name: "",
    amount: 0,
    points_target: 0,
    start_date: "",
    expiry_date: "",
    brand: "",
  });
  const handleChange = (e) => {
    data[e.target.name] = e.target.value;
    setdata(data);
  };
  const location = useHistory();
  const [success, setsuccess] = useState(false);
  const [errors, seterrors] = useState({});
  const handleSubmit = async () => {
    await axios
      .post(`${Config.SERVER_URL}/voucher/create`, data)
      .then((response) => {
        setsuccess(true);
        setTimeout(() => {
          location.push("/marketing");
        }, 3000);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          seterrors(error.response.data);
        }
      });
  };
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        <Link to="/customers">
          <ArrowBackIcon className="text-dark" />
        </Link>
        Vocuher
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/marketing">
          Marketing
        </Link>
        <Link color="inherit" to="/">
          Add Voucher
        </Link>
      </Breadcrumbs>
      <Paper className="p-4">
        {success && (
          <Alert severity="success">
            Voucher added You will be redirected in 3 sec.
          </Alert>
        )}
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <label>Vocuher Name</label>
                  <TextField
                    name="voucher_name"
                    id="VoucherName"
                    onChange={(e) => handleChange(e)}
                    color="primary"
                    variant="outlined"
                    size="small"
                  ></TextField>
                  <span className="text-danger">{errors.voucher_name}</span>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <label>Partner Name</label>
                  <TextField
                    name="brand"
                    id="brand"
                    onChange={(e) => handleChange(e)}
                    color="primary"
                    variant="outlined"
                    size="small"
                  ></TextField>
                  <span className="text-danger">{errors.brand}</span>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label>Amount</label>
                  <TextField
                    name="amount"
                    id="Amount"
                    onChange={(e) => handleChange(e)}
                    color="primary"
                    variant="outlined"
                    size="small"
                    defaultValue={data.Amount}
                  ></TextField>
                  <span className="text-danger">{errors.amount}</span>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label>Points Target</label>
                  <TextField
                    name="points_target"
                    id="PointsTarget"
                    onChange={(e) => handleChange(e)}
                    color="primary"
                    variant="outlined"
                    size="small"
                    defaultValue={data.points_target}
                  ></TextField>
                  <span className="text-danger">{errors.points_target}</span>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label>Start Date</label>
                  <TextField
                    name="start_date"
                    id="StartDate"
                    type="date"
                    onChange={(e) => handleChange(e)}
                    color="primary"
                    variant="outlined"
                    size="small"
                  ></TextField>
                  <span className="text-danger">{errors.start_date}</span>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <label>End Date</label>
                  <TextField
                    name="expiry_date"
                    id="EndDate"
                    onChange={(e) => handleChange(e)}
                    color="primary"
                    variant="outlined"
                    size="small"
                    type="date"
                  ></TextField>
                  <span className="text-danger">{errors.expiry_date}</span>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} item className="d-flex justify-content-end">
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
