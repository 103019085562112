import { Avatar, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import Config from "../../../config.json";
import { useParams } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
function Reviews() {
  const { id } = useParams();
  const [reviews, setreviews] = useState(null);
  const [error, seterror] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}/reviews/products/${id}`)
        .then((res) => {
          setisLoading(false);
          setreviews(res.data);
        })
        .catch((error) => {
          setisLoading(false);
          seterror(true);
        });
    };
    init();
  }, [id]);
  return (
    <>
      {isLoading ? (
        <>
          <Typography>Please Wait While we Fetch Reviews</Typography>
        </>
      ) : (
          <>
            {error && <Alert severity="error">Something Went Wrong</Alert>}
            {reviews &&
              reviews?.map((review) => {
                return <ReviewChild data={review} setreviews={setreviews}/>;
              })}
          </>
        )}
    </>
  );
}
const useStyles = makeStyles(() => ({
  media: {
    width: "100%",
    height: "auto",
  },
}));
function ReviewChild({ data ,setreviews}) {
  const [error, seterror] = useState(false)
  const classes = useStyles();
  const deleteReview = async (e)=>{
    await axios.post(`${Config.SERVER_URL}/review/delete/${e}/${data?.product_id}`).then(response=>{
     seterror(false);
      setreviews(response.data)
    }).catch(error=>{
      seterror(true)
    })
  }
  return (
    <>
      <Box p={4}>
        {
          error && <Alert severity="error">Something Went Wrong</Alert>
        }
        <Grid container>
          <Grid item xs className="d-flex" container alignItems="center">
            <Avatar
              alt="Remy Sharp"
              src={data?.customer?.profile_image}
              className="mx-2"
            />
            <Typography component="h6" variant="body2">
              {data?.customer?.first_name} {data?.customer?.last_name}
            </Typography>
            <Box className="mx-2">
             
            <Stars stars={data?.stars} />
            </Box>
          </Grid>
          <Grid item xs={2} container justify="flex-end">
            <IconButton style={{ color: "red" }} aria-label="Remove" onClick={e=>deleteReview(data?.id)}>
              <DeleteForeverIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Box p={3}>
              <p>{data?.review_message}</p>
            </Box>
            <Grid container spacing={3}>
              {
                data.pictures && data.pictures?.map(image => {
                  return <Grid item xs={2}>
                    <img
                      src={image?.picture}
                      alt=""
                      className={classes.media}
                    />
                  </Grid>
                })
              }
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider />
    </>
  );
}
function Stars({ stars }) {
  let element = [];
  for (let i = 0; i < stars; i++) {
    element.push(<StarIcon style={{color:'#ffd700'}}/>);
  }
  return element;
}
export default Reviews;
