import React, { useEffect, useState } from "react";
import ReactExport from "react-export-excel";
import axios from "axios";
import Config from "../../../config.json";
import PrintIcon from "@material-ui/icons/Print";
import { Button } from "@material-ui/core";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function Export({ status }) {
  const [data, setdata] = useState([]);
  useEffect(() => {
    const init = () => {
      axios
        .post(`${Config.SERVER_URL}/export/orders`)
        .then((res) => {
          setdata(res.data);
        })
        .catch((error) => {});
    };
    init();
  }, []);
  return (
    <ExcelFile
      element={
        <Button variant="text" color="primary" className="mx-1">
          <PrintIcon className="text-main" />
        </Button>
      }
    >
      <ExcelSheet data={data} name="Orders">
        <ExcelColumn label="Order-ID" value="id" />
        <ExcelColumn
          label="Customer"
          value={(col) =>
            col?.customer_id !== 0
              ? col?.customer?.first_name + " " + col?.customer?.last_name
              : col?.first_name + " " + col?.last_name
          }
        />
        <ExcelColumn label="Delivery Address" value="address" />
        <ExcelColumn label="Status" value="status" />
        <ExcelColumn label="Payment Type" value="payment_method" />
        <ExcelColumn label="points Redeemed" value="points_discount" />
        <ExcelColumn label="Discount" value="discount" />
        <ExcelColumn label="Promo Discount" value="promo_discount" />
        <ExcelColumn label="VAT" value="vat" />
        <ExcelColumn label="Shipping" value="shipping" />
        <ExcelColumn label="Discount Code" value="discount_code" />
        <ExcelColumn label="Promo Code" value="promo_code" />
        <ExcelColumn label="Dates" value="created_at" />
        <ExcelColumn label="Sub Total" value="total" />
        <ExcelColumn label="Total" value="sub_total" />
        <ExcelColumn
          label="Order From"
          value={(col) =>
            col?.staff_id !== null
              ? col?.staff?.first_name + " " + col?.staff?.last_name
              : "Website"
          }
        />
      </ExcelSheet>
    </ExcelFile>
  );
}

export default Export;
