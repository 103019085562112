import React, {useEffect, useState} from "react";
import {
    Box,
    Breadcrumbs,
    Button,
    ButtonGroup,
    Chip,
    Container,
    FormControl,
    Grid,
    MenuItem,
    Paper,
    Select,
    Switch,
    TextareaAutosize,
    TextField
} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import axios from "axios";
import Config from "../../config.json";
import {Alert} from "@material-ui/lab";

function AddProduct() {
    const [categories, setcategories] = useState(null);
    const [brands, setbrands] = useState(null);

    useEffect(() => {
        const init = async () => {
            await axios
                .get(`${Config.SERVER_URL}/category/all`)
                .then((res) => {
                    setcategories(res.data);
                })
                .catch(() => {
                });
            await axios
                .get(`${Config.SERVER_URL}/brands/all`)
                .then((res) => {
                    setbrands(res.data);
                })
                .catch(() => {
                });
        };
        init();
    }, []);

    // eslint-disable-next-line
    const [dummystate, setdummystate] = useState(false);
    const [Images, setImages] = useState([]);
    const [ImagesURL, setImagesURL] = useState([]);

    function handleImageChanges(e) {
        let imagesTemp = [];
        let imagesTempURL = [];
        Array.from(e.target.files).forEach((image) => {
            imagesTempURL.push(URL.createObjectURL(image));
            imagesTemp.push(image);
        });
        setImages(imagesTemp);
        setImagesURL(imagesTempURL);
    }

    const [productForm, setproductForm] = useState({
        category_id: "",
        brand_id: "",
        product_description: "",
        reward_points: "",
        cost_price: "",
        model: "",
        quantity: "",
        sku: "",
        vat: false,
        weight: "",
        shipping: "",
        length: "",
        width: "",
        height: "",
        tax: 0,
        price: 0,
        dimension_unit: "",
        featured: true,
        is_bundle: false,
        product_arabic_description: "",
        bar_code: "",
    });
    const handleChange = (e) => {
        setproductForm({...productForm, [e.target.name]: e.target.value});
    };
    const options = [
        {value: "small", label: "Small"},
        {value: "medium", label: "Medium"},
        {value: "large", label: "Large"},
        {value: "extralarge", label: "Extra Large"},
    ];
    const [sizes, setsizes] = useState([]);
    const [color, setcolor] = useState([]);
    const handleSize = (e) => {
        if (!e.target.value) {
            return;
        }
        let rchip = sizes.filter((s) => {
            return s === e.target.value;
        });
        if (rchip.length > 0) {
            return;
        }

        sizes.push(e.target.value);
        setsizes(sizes);
        setdummystate(!dummystate);
    };
    const handleSizedelete = (ind) => {
        let rchip = sizes.filter((s) => {
            return s !== ind;
        });
        setsizes(rchip);
        setdummystate(!dummystate);
    };
    const handleColordelete = (ind) => {
        let rchip = color.filter((s) => {
            return s !== ind;
        });
        setcolor(rchip);
        setdummystate(!dummystate);
    };
    const [colorerror, setcolorerror] = useState(false);
    const [currentcolor, setcurrentcolor] = useState({
        color_english: "",
        color_arabic: "",
    });
    const AddColor = () => {
        if (
            currentcolor?.color_english.length > 0 &&
            currentcolor?.color_arabic.length > 0
        ) {
            color[color.length] = currentcolor;
            setcolor(color);
            setdummystate(!dummystate);
        } else {
            setcolorerror(true);
            setTimeout(() => {
                setcolorerror(false);
            }, 3000);
        }
    };

    const [success, setsuccess] = useState(false);
    const [product_name, setproduct_name] = useState("");
    const [arabic_name, setarabic_name] = useState("");
    const [errors, seterrors] = useState(null);
    const location = useHistory();
    const AddProduct = async () => {
        const formData = new FormData();
        formData.append("category_id", productForm.category_id);
        formData.append("product_name", product_name);
        formData.append("product_arabic_name", arabic_name);
        formData.append("brand_id", productForm.brand_id);
        formData.append("product_description", productForm.product_description);
        formData.append(
            "product_arabic_description",
            productForm.product_arabic_description
        );
        formData.append("reward_points", productForm.reward_points);
        formData.append("related_products", productForm.related_products);
        formData.append("cost_price", productForm.cost_price);
        formData.append("model", productForm.model);
        formData.append("quantity", productForm.quantity);
        formData.append("sku", productForm.sku);
        formData.append("vat", productForm.vat);
        formData.append("weight", productForm.weight);
        formData.append("shipping", productForm.shipping);
        formData.append("length", productForm.length);
        formData.append("width", productForm.width);
        formData.append("height", productForm.height);
        formData.append("tax", productForm.tax);
        formData.append("price", productForm.price);
        formData.append("size", JSON.stringify(sizes));
        formData.append("color", JSON.stringify(color));
        formData.append("featured", productForm.featured);
        formData.append("bar_code", productForm.bar_code);
        formData.append("is_bundle", productForm.is_bundle);
        for (let i = 0; i < Images.length; i++) {
            formData.append(`product_image[${i}]`, Images[i]);
        }
        await axios
            .post(`${Config.SERVER_URL}/product/create`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(() => {
                setsuccess(true);
                setTimeout(() => {
                    location.push("/products");
                }, 1000);
            })
            .catch((error) => {
                if (error.response.status === 400) seterrors(error.response.data);
            });
    };
    const [isEnglish, setisEnglish] = useState(true);
    const handleColor = (e) => {
        setcurrentcolor({...currentcolor, [e.target.name]: e.target.value});
    };
    return (
        <>
            <Container maxWidth="xl" className="mainContainer">
                <Typography component="h5" variant="h5">
                    Products
                </Typography>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small"/>}
                    aria-label="breadcrumb"
                    className="mb-3"
                >
                    <Link color="inherit" to="/" className="active">
                        Home
                    </Link>
                    <Link color="inherit" to="/products">
                        Returns
                    </Link>
                    <Link color="inherit" to="/products/new" disabled>
                        Insert
                    </Link>
                </Breadcrumbs>
                <Paper className="px-4">
                    {errors &&
                    Object.keys(errors).map((key) => (
                        <Alert severity="error" className="m-1">
                            {errors[key]}
                        </Alert>
                    ))}
                    {success && (
                        <Alert severity="success">
                            Product added You will be redirected in 3 secs
                        </Alert>
                    )}
                    <Grid container xs={12} item justify="center" className="pt-3"/>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Box display="flex" justifyContent="space-between">
                                            <label htmlFor="">Product Name</label>
                                            <ButtonGroup
                                                size="small"
                                                aria-label="small text primary button group"
                                                disableElevation
                                            >
                                                <Button
                                                    onClick={() => {
                                                        setisEnglish(true);
                                                        setdummystate(dummystate);
                                                    }}
                                                    variant={isEnglish ? "outlined" : "contained"}
                                                    color={isEnglish ? "primary" : ""}
                                                >
                                                    English
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setisEnglish(false);
                                                        setdummystate(dummystate);
                                                    }}
                                                    variant={isEnglish ? "contained" : "outlined"}
                                                    color={isEnglish ? "" : "primary"}
                                                >
                                                    Arabic
                                                </Button>
                                            </ButtonGroup>
                                        </Box>
                                        <TextField
                                            name="product_name"
                                            id="ProductName"
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            placeholder="English Name"
                                            onChange={(e) => {
                                                setproduct_name(e.target.value);
                                            }}
                                            margin="none"
                                            className={`m-0 ${isEnglish ? "" : "d-none"}`}
                                            value={product_name}
                                        />
                                        <TextField
                                            name="arabic_name"
                                            id="arabic_name"
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            placeholder="Arabic Name"
                                            onChange={(e) => {
                                                setarabic_name(e.target.value);
                                            }}
                                            margin="none"
                                            className={`m-0 ${isEnglish ? "d-none" : ""}`}
                                            value={arabic_name}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <label htmlFor="">Category</label>
                                        <Select
                                            color="primary"
                                            variant="outlined"
                                            name="category_id"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        >
                                            <MenuItem value="" selected>
                                                None
                                            </MenuItem>
                                            {categories &&
                                            categories?.map((cat) => {
                                                return (
                                                    <MenuItem value={cat?.id}>
                                                        {cat?.category_name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <label htmlFor="">Brand</label>
                                        <Select
                                            color="primary"
                                            variant="outlined"
                                            name="brand_id"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        >
                                            <MenuItem value="" selected>
                                                None
                                            </MenuItem>
                                            {brands &&
                                            brands?.map((cat) => {
                                                return (
                                                    <MenuItem value={cat?.id}>
                                                        {cat?.brand_name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <label htmlFor="">Description</label>
                                        <TextareaAutosize
                                            name="product_description"
                                            placeholder="Upto 75 characters only."
                                            rowsMin="10"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            value={productForm?.product_description}
                                            className={`${isEnglish ? "" : "d-none"} w-100`}
                                        />
                                        <TextareaAutosize
                                            name="product_arabic_description"
                                            rowsMin="10"
                                            placeholder="Arabic Description"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            value={productForm?.product_arabic_description}
                                            className={`${isEnglish ? "d-none" : ""} w-100`}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <label htmlFor="">Price</label>
                                        <TextField
                                            name="price"
                                            id="Price"
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            type="number"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <label htmlFor="">Reward Points</label>
                                        <TextField
                                            name="reward_points"
                                            id="RewardPoints"
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            type="number"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <label htmlFor="">Quantity</label>
                                        <TextField
                                            name="quantity"
                                            id="RewardPoints"
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            type="number"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <label>Feature Product</label>
                                    <Switch
                                        checked={productForm.featured}
                                        color="primary"
                                        name="featured"
                                        onChange={(e) => {
                                            productForm["featured"] = e.target.checked;
                                            setproductForm(productForm);
                                            setdummystate(!dummystate);
                                        }}
                                        inputProps={{"aria-label": "primary checkbox"}}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <label>Present as Bundle Offer</label>
                                    <Switch
                                        checked={productForm.is_bundle}
                                        color="primary"
                                        name="is_bundle"
                                        onChange={(e) => {
                                            productForm["is_bundle"] = e.target.checked;
                                            setproductForm(productForm);
                                            setdummystate(!dummystate);
                                        }}
                                        inputProps={{"aria-label": "primary checkbox"}}
                                    />
                                </FormControl>
                            </Grid>
                            <Typography component="h6" variant="h6">
                                Product Images
                            </Typography>
                            <Box className="d-flex justify-content-between align-items-center">
                                <Typography component="p">Upload multiple images</Typography>
                                <label className="imageUpload">
                                    Upload Image
                                    <input
                                        type="file"
                                        name="images"
                                        id="images"
                                        multiple
                                        className="d-none"
                                        onChange={handleImageChanges}
                                    />
                                </label>
                            </Box>
                            <Grid container spacing={4} className="mt-3 px-3">
                                {ImagesURL &&
                                ImagesURL.map((image) => {
                                    return (
                                        <Grid xs={6} md={3} item className="px-3">
                                            <img src={image} className=" auto" alt="ReviewImage"/>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} spacing={3}>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <label>Charge VAT on product</label>
                                    <Switch
                                        checked={productForm.vat}
                                        color="primary"
                                        name="vat"
                                        onChange={(e) => {
                                            productForm["vat"] = e.target.checked;
                                            setproductForm(productForm);
                                            setdummystate(!dummystate);
                                        }}
                                        inputProps={{"aria-label": "primary checkbox"}}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                {/* Shipping */}
                <Paper className="p-4 my-5">
                    <Typography component="p" variant="body2">
                        Shipping
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <label>Requires Shipping</label>
                                <Switch
                                    checked={productForm.shipping}
                                    color="primary"
                                    name="shipping"
                                    onChange={(e) => {
                                        productForm["shipping"] = e.target.checked;
                                        setproductForm(productForm);
                                        setdummystate(!dummystate);
                                    }}
                                    inputProps={{"aria-label": "primary checkbox"}}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            className="mx-2"
                            style={{display: productForm.shipping ? "" : "none"}}
                        >
                            <label>Weight </label>
                            <FormControl fullWidth>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            name="weight"
                                            type="number"
                                            placeholder="Weight"
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={3} container alignItems="center">
                                        <Select
                                            color="primary"
                                            variant="outlined"
                                            name="weight_unit"
                                            fullWidth
                                            onChange={(e) => handleChange(e)}
                                        >
                                            <MenuItem value="g" selected>
                                                Gram
                                            </MenuItem>
                                            <MenuItem value="kg">KG</MenuItem>
                                            <MenuItem value="lb">LB</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Grid>

                        <Grid
                            item
                            xs
                            className="mx-2"
                            style={{display: productForm.shipping ? "" : "none"}}
                        >
                            <label>Dimensions (L x W x H)</label>
                            <FormControl fullWidth>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            name="length"
                                            type="number"
                                            placeholder="L"
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            name="width"
                                            type="number"
                                            placeholder="W"
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            name="height"
                                            type="number"
                                            placeholder="H"
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={3} container alignItems="center">
                                        <Select
                                            color="primary"
                                            variant="outlined"
                                            name="dimension_unit"
                                            onChange={(e) => handleChange(e)}
                                            fullWidth
                                        >
                                            <MenuItem value="mm" selected>
                                                MiliMeter
                                            </MenuItem>
                                            <MenuItem value="cm">Centimeter</MenuItem>
                                            <MenuItem value="m">Meter</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper className="p-4 my-5">
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography component="h6" variant="h6">
                                Additional Options
                            </Typography>
                            <p>select multiple options for product or add a custom option</p>
                            <Grid container spacing={4}>
                                <Grid item xs={4} md={2}>
                                    <p style={{opacity: 0}}> space</p>
                                    <label> Size</label>
                                    <Select
                                        name="sizes"
                                        color="primary"
                                        variant="outlined"
                                        onChange={handleSize}
                                        fullWidth
                                    >
                                        <MenuItem selected>Select Size</MenuItem>
                                        {options &&
                                        options?.map((op) => {
                                            return <MenuItem value={op.value}>{op.label}</MenuItem>;
                                        })}
                                    </Select>
                                    {sizes &&
                                    sizes.map((s) => {
                                        return (
                                            <Chip
                                                label={s}
                                                className="m-1"
                                                onDelete={() => handleSizedelete(s)}
                                            />
                                        );
                                    })}
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <label> Color</label>
                                    {colorerror && (
                                        <Alert severity="error">
                                            Both fields for color required
                                        </Alert>
                                    )}
                                    <Box display="flex" alignItems="center" className="mb-2">
                                        <FormControl fullWidth>
                                            <label htmlFor="">English</label>
                                            <TextField
                                                name="color_english"
                                                id="RewardPoints"
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                placeholder="English Name"
                                                onChange={(e) => {
                                                    handleColor(e);
                                                }}
                                                value={currentcolor?.color_english}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <label htmlFor="">Arabic</label>
                                            <TextField
                                                name="color_arabic"
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                placeholder="Arabic Name"
                                                onChange={(e) => {
                                                    handleColor(e);
                                                }}
                                                value={currentcolor?.color_arabic}
                                            />
                                        </FormControl>
                                        <Button
                                            className="text-main w-100"
                                            onClick={AddColor}
                                            variant="text"
                                            disableRipple
                                            disableElevation
                                        >
                                            Add Color+
                                        </Button>
                                    </Box>
                                    <Grid item container xs={12}>
                                        {color &&
                                        color.map((s) => {
                                            return (
                                                <Chip
                                                    label={`${s?.color_english} - ${s?.color_arabic}`}
                                                    className="m-1"
                                                    onDelete={() => handleColordelete(s)}
                                                />
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>

            <Container maxWidth="xl" className="mt-3">
                <Paper className="p-4">
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <label htmlFor="">Cost Price</label>
                                <TextField
                                    name="cost_price"
                                    id="CostPrice"
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <label htmlFor="">Tax</label>
                                <TextField
                                    name="tax"
                                    id="Tax"
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>
                        {productForm?.quantity > 0 && (
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <label htmlFor="">SKU</label>
                                    <TextField
                                        name="sku"
                                        id="SKU"
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <label htmlFor="">Model</label>
                                <TextField
                                    name="model"
                                    id="Model"
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <label htmlFor="">Bar Code</label>
                                <TextField
                                    name="bar_code"
                                    id="BarCode"
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container justify="flex-end">
                        <Button onClick={AddProduct} color="primary" variant="contained">
                            Save
                        </Button>
                    </Grid>
                </Paper>
            </Container>
        </>
    );
}

export default AddProduct;
