import React from "react";
import { Container, Breadcrumbs, Paper, Typography,Tabs, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import PropTypes from "prop-types";
import Gallery from './Gallery';
import AdsManager from './AdsManager';
import NavigateNextIcon  from '@material-ui/icons/NavigateNext';

function MediaManager() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        Products
      </Typography>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}   aria-label="breadcrumb" className="mb-3">
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/orders" disabled>
          Media manager
        </Link>
      </Breadcrumbs>
      <Paper evaluation={1} className="px-2">
      <AppBar position="static" color="transparent" elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="nav tabs example"
          >
            <Tab label="Gallery" {...a11yProps(0)} />
            <Tab label="Ads Manager" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Gallery />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AdsManager />
        </TabPanel>
      </Paper>
    </Container>
  );
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      "aria-controls": `nav-tabpanel-${index}`,
    };
  }
export default MediaManager;
