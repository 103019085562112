import ReactExport from "react-export-excel";
import PrintIcon from "@material-ui/icons/Print";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/Sort";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Button } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import Config from "../../config.json";
const getQuery = (filters) => {
  var q = "";
  Object.keys(filters).forEach((k) => {
    if (filters[k] !== "" && filters[k] !== 0 && filters[k] !== null) {
      q += `${k}=${filters[k]}&`;
    }
  });
  return q;
};
function OrdersReport() {
  const [Filters, setFilters] = useState({
    customer: "",
    product: "",
    status: "",
    from: "",
    to: "",
    country: "",
    Zone: "",
    City: "",
    promo: "",
    total: 0,
    shipping: 0,
  });
  const [EnableFilters, setEnableFilters] = useState(false);

  const handleFilterChange = (e) => {
    let TempFilters = Filters;
    TempFilters[e.target.name] = e.target.value;
    setFilters(TempFilters);
  };
  const [isLoading, setisLoading] = useState(false);
  const columns = [
    {
      name: "Order-ID",
      selector: "id",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <Link
            to={`/orders/details/${row?.id}`}
            style={{ fontWeight: "bold" }}
          >
            {row?.id}
          </Link>
        </div>
      ),
    },
    {
      name: "Customer",
      selector: "customer_id",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          {row?.customer_id !== 0 ? row?.customer?.first_name : row?.first_name}
        </div>
      ),
    },
    {
      name: "Delivery Address",
      selector: "address",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    {
      name: "Dates",
      selector: "created_at",
      sortable: true,
    },
    {
      name: "Discount",
      selector: "discount",
      sortable: true,
    },
    {
      name: "Promo Discount",
      selector: "promo_discount",
      sortable: true,
    },
    {
      name: "VAT",
      selector: "vat",
      sortable: true,
    },
    {
      name: "Shipping",
      selector: "shipping",
      sortable: true,
    },
    {
      name: "Discount Code",
      selector: "discount_code",
      sortable: true,
    },
    {
      name: "Promo Code",
      selector: "promo_code",
      sortable: true,
    },
    {
      name: "Total",
      selector: "total",
      sortable: true,
    },
    {
      name: "Sub Total",
      selector: "sub_total",
      sortable: true,
    },
  ];
  const [rows, setrows] = useState([]);
  const submitFilter = async () => {
    setisLoading(true);
    await axios
      .post(`${Config.SERVER_URL}/export/orders?${getQuery(Filters)}`)
      .then((response) => {
        setrows(response.data);
        setisLoading(false);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    axios
      .post(`${Config.SERVER_URL}/export/orders`)
      .then((response) => {
        setrows(response.data);
        setisLoading(false);
      })
      .catch((error) => {});
  }, []);
  return (
    <Box className="w-100">
      <Box>
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={6} sm={3}>
            <IconButton
              aria-label="delete"
              color="default"
              className="filters"
              onClick={() => setEnableFilters(!EnableFilters)}
            >
              Filters
              <ArrowDropDownIcon />
            </IconButton>
          </Grid>
          <Grid xs={1} item>
            <Export data={rows} />
          </Grid>
        </Grid>
      </Box>
      <Box p={4} className={EnableFilters ? "Filters-Show" : "Filters-Hide"}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Customer</label>
              <TextField
                variant="outlined"
                name="customer"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Product</label>
              <TextField
                variant="outlined"
                name="product"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Order Status</label>
              <TextField
                variant="outlined"
                name="status"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <label htmlFor="">From</label>
              <TextField
                variant="outlined"
                name="from"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
                type="date"
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <label htmlFor="">To</label>
              <TextField
                variant="outlined"
                name="to"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
                type="date"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Discount</label>
              <TextField
                variant="outlined"
                name="discount"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Promo</label>
              <TextField
                variant="outlined"
                name="promo"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Shipping</label>
              <TextField
                variant="outlined"
                name="shipping"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <label htmlFor="">Total</label>
              <TextField
                variant="outlined"
                name="total"
                onChange={(e) => handleFilterChange(e)}
                size="small"
                placeholder="Type Here"
              ></TextField>
            </FormControl>
          </Grid>
          <Grid
            item
            container
            justify="flex-end"
            alignItems="center"
            xs
            className="mt-4 "
          >
            <Button variant="outlined" color="primary" onClick={submitFilter}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <DataTable
        columns={columns}
        data={rows}
        defaultSortField="title"
        SortIcon={<SortIcon />}
        pagination
        responsive={true}
        progressPending={isLoading}
        style={{ maxWidth: "900px !important" }}
      />
    </Box>
  );
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function Export({ data }) {
  return (
    <ExcelFile
      element={
        <Button variant="text" color="primary" className="mx-1">
          <PrintIcon className="text-main" />
        </Button>
      }
    >
      <ExcelSheet data={data} name="Orders">
        <ExcelColumn label="Order-ID" value="id" />
        <ExcelColumn
          label="Customer"
          value={(col) =>
            col?.customer_id !== 0
              ? col?.customer?.first_name + " " + col?.customer?.last_name
              : col?.first_name + " " + col?.last_name
          }
        />
        <ExcelColumn label="Delivery Address" value="address" />
        <ExcelColumn label="Status" value="status" />
        <ExcelColumn label="Payment Type" value="payment_method" />
        <ExcelColumn label="points Redeemed" value="points_discount" />
        <ExcelColumn label="Discount" value="discount" />
        <ExcelColumn label="Promo Discount" value="promo_discount" />
        <ExcelColumn label="VAT" value="vat" />
        <ExcelColumn label="Shipping" value="shipping" />
        <ExcelColumn label="Discount Code" value="discount_code" />
        <ExcelColumn label="Promo Code" value="promo_code" />
        <ExcelColumn label="Dates" value="created_at" />
        <ExcelColumn label="Sub Total" value="total" />
        <ExcelColumn label="Total" value="sub_total" />
      </ExcelSheet>
    </ExcelFile>
  );
}

export default OrdersReport;
