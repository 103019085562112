import React from "react";
import { Grid } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import {  Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  card: {
    minHeight: 600,
  },
  address: {
    wordBreak: "break-word",
    padding: "10px 5px",
    border: "1px solid #707070",
    backgroundColor: "#F4F4F4",
  },
}));
function Profile({customer}) {
  const classes = useStyles();
  // const updatePassword = async () => {
  //   await axios
  //     .post(`${Config.SERVER_URL}/updatecustomer/password/${id}`, {
  //       admin_id: 1, //testing
  //       password: Password.NewPassword,
  //       confirm_password: Password.ConfirmPassword,
  //     })
  //     .then((response) => {
  //       if (response.data) setsuccess(true);
  //       setTimeout(() => {
  //         setsuccess(false)
  //       }, 3000);
  //     })
  //     .catch((error) => {
  //       seterror(true);
  //       setTimeout(() => {
  //         seterror(false)
  //       }, 3000);
  //     });
  // };
  return (
    <Card className={classes.card}>
      <Typography component="h6" variant="h6" className="px-4 py-2">
        Personal Information
      </Typography>
      <Divider />
      <Box p={5}>
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography component="h6" variant="body1">
              First Name
            </Typography>
            <Typography component="p" variant="subtitle2">
              {customer?.first_name}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography component="h6" variant="body1">
              Last Name
            </Typography>
            <Typography component="p" variant="subtitle2">
              {customer?.last_name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography component="h6" variant="body1">
              Date of Birth
            </Typography>
            <Typography component="p" variant="subtitle2">
              {customer?.dob?customer?.dob:"N/A"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography component="h6" variant="body1">
              Email Address
            </Typography>
            <Typography component="p" variant="subtitle2">
            {customer?.email?customer?.email:"N/A"}

            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography component="h6" variant="body1">
              Phone
            </Typography>
            <Typography component="p" variant="subtitle2">
              {customer?.phone?customer?.phone:"N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box m={8}>
        <Typography>Address</Typography>
        <Grid container spacing={4}>
          {customer?.addresses?
            customer?.addresses?.map((addr, index) => {
              return (
                <Grid item xs={3}>
                  <Typography
                    key={index}
                    component="p"
                    variant="subtitle2"
                    className={classes.address}
                  >
                    {addr.address}
                  </Typography>
                </Grid>
              );
            }):
            <Grid item xs>
            <Typography
              component="p"
              variant="subtitle2"
            >
              No Addresses Added Yet
            </Typography>
          </Grid>
            }
        </Grid>
      </Box>
    </Card>
  );
}

export default Profile;
