import React from 'react'
import { useUserContext } from './../../../Context/UserContext';
import { Route,Redirect } from 'react-router-dom';

function SalesManRoute({ component, ...props }) {
    const {isLogined,isSalesman} = useUserContext();
    // eslint-disable-next-line
    return (
        <>
            {
                isLogined() && isSalesman() ? (<Route {...props} render={()=>component}/>) : (<Redirect to="/staff/login" />)
            }
        </>
    )
}

export default SalesManRoute
