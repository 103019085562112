import { useUserContext } from './../../../Context/UserContext';
import React, { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import { Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";
import ActivityLog from "./ActivityLog";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import axios from 'axios'
import Config from '../../../config.json'
import  Alert  from '@material-ui/lab/Alert';
import Logo from '../../../assets/logo/ADVABlack.svg'
const useStyles = makeStyles(() => ({
  card: {
    minHeight: 650,
  },
  profile: {
    marginTop: "27px",
    padding: "16px",
  },
  list: {
    "& .MuiListItem-root": {
      paddingTop: "19px",
      paddingBottom: "19px",
      cursor: "pointer",
    },
    "& .MuiListItem-root.active": {
      backgroundColor: "#F1BD8E",
    },
  },
  select: {
    padding: "0px 14px !important",
  }


}));
const getStepComponent = (step, user) => {
  switch (step) {
    case 0:
      return <Profile user={user} />;
    case 1:
      return <ChangePassword />;
    case 2:
      return <ActivityLog />;
    default:
      break;
  }
};
function AdminProfile() {
  const classes = useStyles();
  const [steps, setsteps] = useState(0);
  const {getuser} = useUserContext()
  const [user] = useState(getuser());
  const [admin, setadmin] = useState(null)
  const [error, seterror] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  useEffect(() => {
    const init = async () => {
      setisLoading(true)
      await axios.get(`${Config.SERVER_URL}/${user?.id}`).then(res => {
        setisLoading(false)
        setadmin(res.data)
      }).catch(error => {
        setisLoading(false)
        seterror(true)
      })
    }
    init()
  }, [user])
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="mb-3">
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/profile">
          Admin Profile
        </Link>
      </Breadcrumbs>
      <Box className="p-3">
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Card className={classes.card}>
              <CardContent className="p-0">
                <Box
                  flexGrow={1}
                  alignContent="center"
                  className={classes.profile}
                >
                  <div className="d-flex justify-content-center py-2">
                    <img src={Logo} alt="profile" className="" width="150" />
                  </div>
                  <Typography component="h6" variant="h6" align="center">
                    {admin?.name}
                  </Typography>
                </Box>
                <Divider />
                <List className={classes.list}>
                  <ListItem
                    className={steps === 0 ? "active" : ""}
                    onClick={() => {
                      setsteps(0);
                    }}
                  >
                    Admin Information
                  </ListItem>
                  <ListItem
                    className={steps === 1 ? "active" : ""}
                    onClick={() => {
                      setsteps(1);
                    }}
                  >
                    Change Password
                  </ListItem>
                  <ListItem
                    className={steps === 2 ? "active" : ""}
                    onClick={() => {
                      setsteps(2);
                    }}
                  >
                    Activity Log
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={9}>
            {
              error && <Alert severity="error">Something went wrong</Alert>
            }
            
            {isLoading?<Typography>Please wait while we fetch your Profile</Typography>:getStepComponent(steps,admin)}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default AdminProfile;
