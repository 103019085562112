import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  FormControl,
  Grid,
  TextareaAutosize,
  TextField,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import { Container } from "@material-ui/core";
import { Paper, Switch } from "@material-ui/core";
import { Box } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import axios from "axios";
import Config from "../../config.json";
import { useForm } from "react-hook-form";
import Alert from "@material-ui/lab/Alert";
export default function EditCategory() {
  const { id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const [CategoryForm, setCategoryForm] = useState([]);
  const [error, seterror] = useState(null);
  const [images, setimages] = useState(null);
  const handleImageChanges = async (e) => {
    const formData = new FormData();
    formData.append(`banner`, e.target.files[0]);
    await axios
      .post(`${Config.SERVER_URL}/category/update/image/${id}`, formData)
      .then((res) => {
        setimages(res.data);
      })
      .catch((error) => {
        error.response &&
          error.response.status === 400 &&
          seterror(error.response.data);
      });
  };
  const handleChanges = (e) => {
    setCategoryForm({ ...CategoryForm, [e.target.name]: e.target.value });
  };
  const location = useHistory();
  const formData = new FormData();
  const [dummtstate, setdummtstate] = useState(false);
  const [success, setsuccess] = useState(false);
  const addCategory = async () => {
    formData.append("category_name", CategoryForm?.category_name);
    formData.append("category_arabic_name", CategoryForm?.category_arabic_name);
    formData.append("status", CategoryForm?.status);
    formData.append("description", CategoryForm?.description);
    formData.append("arabic_description", CategoryForm?.arabic_description);
    await axios
      .post(`${Config.SERVER_URL}/category/update/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setsuccess(true);
      })
      .catch((error) => {
        error.response &&
          error.response.status === 400 &&
          seterror(error.response.data);
      });
  };
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}/category/${id}`)
        .then((res) => {
          res.data &&
            setCategoryForm({
              category_name: res.data?.category_name,
              category_arabic_name: res.data?.category_arabic_name,
              status:
                res.data?.status === 1 || res.data?.status === "1"
                  ? true
                  : false,
              description: res.data?.description,
              arabic_description: res.data?.arabic_description,
            });
          setimages(res.data?.banner);
        })
        .catch((error) => {});
    };
    init();
  }, [id]);
  if (success) {
    setTimeout(() => {
      location.push("/products");
    }, 2000);
  }
  const [isEnglish, setisEnglish] = useState(true);
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="mb-3"
      >
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/products">
          Category
        </Link>
        <Link color="inherit" to="/products/new" disabled>
          Insert
        </Link>
      </Breadcrumbs>
      <Paper evaluation={2} className="p-4">
        {success && (
          <Alert severity="success">
            Category Added successfully you will be redirected in 3 sec
          </Alert>
        )}
        {error &&
          Object.keys(error).map((key) => {
            return <Alert severity="error">{error[key]}</Alert>;
          })}
        <form
          onSubmit={handleSubmit(addCategory)}
          encType="multipart/form-data"
        >
          <Grid container spacing={10}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Box display="flex" justifyContent="space-between">
                  <label htmlFor="">Category Name</label>
                  <ButtonGroup
                    size="small"
                    aria-label="small text primary button group"
                    disableElevation
                  >
                    <Button
                      onClick={() => {
                        setisEnglish(true);
                        setdummtstate(!dummtstate);
                      }}
                      variant={isEnglish ? "outlined" : "contained"}
                      color={isEnglish ? "primary" : ""}
                    >
                      English
                    </Button>
                    <Button
                      onClick={() => {
                        setisEnglish(false);
                        setdummtstate(!dummtstate);
                      }}
                      variant={isEnglish ? "contained" : "outlined"}
                      color={isEnglish ? "" : "primary"}
                    >
                      Arabic
                    </Button>
                  </ButtonGroup>
                </Box>
                <TextField
                  variant="outlined"
                  name="category_name"
                  size="small"
                  placeholder="Type English Name Here"
                  onChange={(e) => handleChanges(e)}
                  inputRef={register({
                    required: "required",
                  })}
                  value={CategoryForm?.category_name}
                  className={isEnglish ? "" : "d-none"}
                ></TextField>
                <TextField
                  variant="outlined"
                  name="category_arabic_name"
                  size="small"
                  placeholder="Type Arabic Name Here"
                  onChange={(e) => handleChanges(e)}
                  inputRef={register({
                    required: "required",
                  })}
                  value={CategoryForm?.category_arabic_name}
                  className={isEnglish ? "d-none" : ""}
                ></TextField>
                <span className="error text-danger">
                  {errors?.category_name?.message}
                </span>
                <span className="error text-danger">
                  {errors?.category_arabic_name?.message}
                </span>
              </FormControl>
              <FormControl fullWidth>
                <label htmlFor="">Description</label>
                <TextareaAutosize
                  variant="outlined"
                  name="description"
                  size="small"
                  rowsMin="10"
                  onChange={(e) => handleChanges(e)}
                  inputRef={register({
                    required: "required",
                  })}
                  value={CategoryForm?.description}
                  placeholder="English Description"
                  className={`w-100 ${isEnglish ? "" : "d-none"}`}
                ></TextareaAutosize>
                <TextareaAutosize
                  variant="outlined"
                  name="arabic_description"
                  size="small"
                  rowsMin="10"
                  onChange={(e) => handleChanges(e)}
                  inputRef={register({
                    required: "required",
                  })}
                  value={CategoryForm?.arabic_description}
                  placeholder="Arabic Description"
                  className={`w-100 ${isEnglish ? "d-none" : ""}`}
                ></TextareaAutosize>
                <span className="error text-danger">
                  {errors?.description?.message}
                </span>
                <span className="error text-danger">
                  {errors?.arabic_description?.message}
                </span>
              </FormControl>
              <FormControl fullWidth>
                <label htmlFor="">Status</label>
                <Switch
                  checked={CategoryForm?.status ? true : false}
                  color="primary"
                  name="status"
                  onChange={(e) => {
                    CategoryForm["status"] = e.target.checked;
                    setCategoryForm(CategoryForm);
                    setdummtstate(!dummtstate);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Box className="d-flex justify-content-between">
                <p>Image</p>
                <label className="imageUpload">
                  Upload Image
                  <input
                    type="file"
                    name="banner"
                    id="banner"
                    onChange={handleImageChanges}
                    inputRef={register({
                      required: "required",
                    })}
                  />
                </label>
                <span className="error">{errors.banner?.message}</span>
              </Box>
              <img src={images} alt="" width="400" />
            </Grid>
            <Grid xs container item justify="flex-end">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}
