import React from "react";
import { Typography, Tabs, Box } from "@material-ui/core";
import { Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { Container } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import CustomersReport from './CustomersReport';
import ProductReport from './ProductReport';
import SalesReport from './SalesReport';
import NavigateNextIcon  from '@material-ui/icons/NavigateNext';
import OrdersReport from './OrdersReport';

function Reports() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container maxWidth="xl" className="mainContainer">
      <Typography component="h5" variant="h5">
        Reports
      </Typography>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}   aria-label="breadcrumb" className="mb-3">
        <Link color="inherit" to="/" className="active">
          Home
        </Link>
        <Link color="inherit" to="/reports" disabled>
          Reports
        </Link>
      </Breadcrumbs>
      <Paper evaluation={1} className="px-2">
        <AppBar position="static" color="transparent" elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="nav tabs example"
          >
            <Tab label="Customers" {...a11yProps(0)} />
            <Tab label="Products" {...a11yProps(1)} />
            <Tab label="Sales" {...a11yProps(2)} />
            <Tab label="Orders" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
            <CustomersReport/>
        </TabPanel>
        <TabPanel value={value} index={1}>
            <ProductReport/>
        </TabPanel>
        <TabPanel value={value} index={2}>
            <SalesReport/>
        </TabPanel>
        <TabPanel value={value} index={3}>
            <OrdersReport/>
        </TabPanel>
      </Paper>
    </Container>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}
export default Reports;
